import Typography from "@mui/material/Typography";
import React from "react";

export default function LabelText({ children, ...props }) {
  return (
    <Typography {...props} variant="h6">
      {children}
    </Typography>
  );
}
