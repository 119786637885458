import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  TextField,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  formControl: {
    position: "relative",
    "& .MuiFormControlLabel-root": {
      width: "calc(33% - 16px)",
      "& > span": {
        "&:first-child": {
          height: "fit-content",
        },
      },
    },
  },
}));

export default function MultipleSelectField(props) {
  const classes = useStyles();
  const { label, data, disabledItems = [], ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const { setValue } = helper;
  const isError = touched && error && true;

  function _renderHelperText() {
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function onChange(e) {
    const value = e.target.value || "";
    const obj = Object.assign(field.value || {}, {
      OTHER: { value, label: value } || "",
    });
    const clearValueblank = Object.entries(obj).filter((e) => e[1]?.value);
    setValue(Object.fromEntries(clearValueblank), true);
  }

  const _onChange = (option) => (event) => {
    const obj = Object.assign(field.value || {}, {
      [option.value]: event.target.checked ? option : "",
    });
    const clearValueblank = Object.entries(obj).filter(
      (e) => e[1]?.value || e[1]
    );
    setValue(Object.fromEntries(clearValueblank), true);
  };

  return (
    <FormControl className={classes.formControl} {...rest} error={isError}>
      <FormLabel component="legend" style={{ marginBottom: 8 }}>
        {label}
      </FormLabel>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {data.map((e, i) => {
            if (e.value === "OTHER") {
              return <React.Fragment key={`MultipleSelectField_item_${i}`} />;
            }
            const isDisabled = disabledItems.includes(e.value);
            return (
              <FormControlLabel
                key={`MultipleSelectField_item_${i}`}
                value={e.value}
                disabled={isDisabled}
                checked={!!(field.value && field.value[e.value])}
                control={<Checkbox {...field} onChange={_onChange(e)} />}
                label={e.label}
              />
            );
          })}
        </div>
        <TextField
          {...field}
          {...rest}
          variant="standard"
          type="text"
          error={touched && error && true}
          style={{ marginBottom: 16, maxWidth: 500 }}
          onChange={onChange}
          value={field.value?.OTHER?.value}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">Other:&nbsp;</InputAdornment>
            ),
          }}
        />
      </div>
      <TextField
        {...field}
        {...rest}
        readOnly
        style={{
          opacity: 0,
          position: "absolute",
          pointerEvents: "none",
          left: 136,
        }}
        type="text"
        id="gender"
        value={field.value?.OTHER?.value}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
