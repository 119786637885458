import { getEnv } from "./../helper/utils";

export const ACCOUNT_TYPE = {
  INDIVIDUAL: "INDIVIDUAL",
  JOINT: "JOINT",
  COMPANY: "COMPANY",
  TRUST: "TRUST",
  FUND: "SUPER_FUND",
  INTERNATIONAL_INVESTOR: "INTERNATIONAL_INVESTOR",
};

export const PERCENT_TO_VERIFIED = 25;

export const EKYC_GOVID_STATUS = {
  EKYC_LOCKED_OUT: "EKYC_LOCKED_OUT",
};

export const TYPE_OPTIONS = [
  { value: "business", label: "a business" },
  { value: "individual", label: "an individual" },
];

export const USER_TYPE = { BROKER: 1, RETAIL: 0 };

export const COMPANY_SECTOR = {
  ENERGY: "Energy",
  MATERIALS: "Materials",
  INDUSTRIALS: "Industrials",
  CONSUMER_DISCRETIONARY: "Consumer discretionary",
  CONSUMER_STAPLES: "Consumer staples",
  HEALTH_CARE: "Health care",
  FINANCIALS: "Financials",
  INFORMATION_TECHNOLOGY: "Information technology",
  TELECOMMUNICATIONS_SERVICES: "Telecommunications services",
  UTILITIES: "Utilities",
  REAL_ESTATE: "Real estate",
};

export const INDUSTRY_CODE_OPTIONS = {
  [COMPANY_SECTOR.ENERGY]: [
    { value: "OIL_GAS_DRILLING", label: "Oil gas drilling" },
    { value: "GAS_EQUIPMENT_SERVICES", label: "Gas equipment services" },
    { value: "INTEGRATED_OIL_GAS", label: "Integrated oil gas" },
    {
      value: "OIL_GAS_EXPLORATION_PRODUCTION",
      label: "Oil gas exploration production",
    },
    {
      value: "OIL_GAS_REFINING_MARKETING",
      label: "Oil gas refining marketing",
    },
    {
      value: "OIL_GAS_STORAGE_TRANSPORTATION",
      label: "Oil gas storage transportation",
    },
    { value: "COAL_CONSUMABLE_FUELS", label: "Coal consumable fuels" },
  ],
  [COMPANY_SECTOR.MATERIALS]: [
    { value: "COMMODITY_CHEMICALS", label: "Commodity chemicals" },
    { value: "DIVERSIFIED_CHEMICALS", label: "Diversified chemicals" },
    {
      value: "FERTILIZERS_AGRICULTURAL_CHEMICALS",
      label: "Fertilizers agricultural chemicals",
    },
    { value: "INDUSTRIAL_GASSES", label: "Industrial gasses" },
    { value: "SPECIALITY_CHEMICALS", label: "Speciality chemicals" },
    { value: "CONSTRUCTION_MATERIALS", label: "Construction materials" },
    { value: "METAL_GLASS_CONTAINERS", label: "Metal glass containers" },
    { value: "PAPER_PACKAGING", label: "Paper packaging" },
    { value: "ALUMINUM", label: "Aluminum" },
    { value: "DIVERSIFIED_METALS_MINING", label: "Diversified metals mining" },
    { value: "COPPER", label: "Copper" },
    { value: "GOLD", label: "Gold" },
    { value: "PRECIOUS_METALS_MINERALS", label: "Precious metals minerals" },
    { value: "SILVER", label: "Silver" },
    { value: "STEEL", label: "Steel" },
    { value: "FOREST_PRODUCTS", label: "Forest products" },
    { value: "PAPER_PRODUCTS", label: "Paper products" },
  ],
  [COMPANY_SECTOR.INDUSTRIALS]: [
    { value: "AEROSPACE_DEFENSE", label: "Aerospace defense" },
    { value: "BUILDING_PRODUCTS", label: "Building products" },
    { value: "CONSTRUCTION_ENGINEERING", label: "Construction engineering" },
    {
      value: "ELECTRICAL_COMPONENTS_EQUIPMENT",
      label: "Electrical components equipment",
    },
    {
      value: "HEAVY_ELECTRICAL_EQUIPMENT",
      label: "Heavy electrical equipment",
    },
    { value: "INDUSTRIAL_CONGLOMERATES", label: "Industrial conglomerates" },
    {
      value: "CONSTRUCTION_MACHINERY_HEAVY_TRUCKS",
      label: "Construction machinery heavy trucks",
    },
    {
      value: "AGRICULTURAL_FARM_MACHINERY",
      label: "Agricultural farm machinery",
    },
    { value: "INDUSTRIAL_MACHINERY", label: "Industrial machinery" },
    {
      value: "TRADING_COMPANIES_DISTRIBUTORS",
      label: "Trading companies distributors",
    },
    { value: "COMMERCIAL_PRINTING", label: "Commercial printing" },
    {
      value: "ENVIRONMENTAL_FACILITIES_SERVICES",
      label: "Environmental facilities services",
    },
    { value: "OFFICE_SERVICES_SUPPLIES", label: "Office services supplies" },
    {
      value: "DIVERSIFIES_SUPPER_SERVICES",
      label: "Diversifies supper services",
    },
    { value: "SECURITY_ALARM_SERVICES", label: "Security alarm services" },
    {
      value: "HUMAN_RESOURCES_EMPLOYMENT_SERVICES",
      label: "Human resources employment services",
    },
    {
      value: "RESEARCH_CONSULTING_SERVICES",
      label: "Research consulting services",
    },
    { value: "AIR_FREIGHT_LOGISTICS", label: "Air freight logistics" },
    { value: "AIRLINES", label: "Airlines" },
    { value: "MARINE", label: "Marine" },
    { value: "RAILROADS", label: "Railroads" },
    { value: "TRUCKING", label: "Trucking" },
    { value: "AIRPORT_SERVICES", label: "Airport services" },
    { value: "HIGHWAYS_RAILTRACKS", label: "Highways railtracks" },
    { value: "MARINE_PORTS_SERVICES", label: "Marine ports services" },
  ],
  [COMPANY_SECTOR.CONSUMER_DISCRETIONARY]: [
    { value: "AUTO_PARTS_EQUIPMENT", label: "Auto parts equipment" },
    { value: "TIRES_RUBBER", label: "Tires rubber" },
    { value: "AUTOMOBILE_MANUFACTURERS", label: "Automobile manufacturers" },
    { value: "MOTORCYCLE_MANUFACTURERS", label: "Motorcycle manufacturers" },
    { value: "CONSUMER_ELECTRONICS", label: "Consumer electronics" },
    { value: "HOME_FURNISHINGS", label: "Home furnishings" },
    { value: "HOMEBUILDING", label: "Homebuilding" },
    { value: "HOUSEHOLD_APPLIANCES", label: "Household appliances" },
    { value: "HOUSEWARES_SPECIALTIES", label: "Housewares specialties" },
    { value: "LEISURE_PRODUCTS", label: "Leisure products" },
    {
      value: "APPAREL_ACCESSORIES_LUXURY_GOODS",
      label: "Apparel accessories luxury goods",
    },
    { value: "FOOTWARE", label: "Footware" },
    { value: "TEXTILES", label: "Textiles" },
    { value: "CASINOS_GAMING", label: "Casinos gaming" },
    {
      value: "HOTELS_RESORTS_CRUISE_LINES",
      label: "Hotels resorts cruise lines",
    },
    { value: "LEISURE_FACILITIES", label: "Leisure facilities" },
    { value: "RESTAURANTS", label: "Restaurants" },
    { value: "EDUCATION_SERVICES", label: "Education services" },
    {
      value: "SPECIALIZED_CONSUMER_SERVICES",
      label: "Specialized consumer services",
    },
    { value: "DISTRUBUTORS", label: "Distrubutors" },
    {
      value: "INTERNET_DIRECT_MARKETING_RETAIL",
      label: "Internet direct marketing retail",
    },
    { value: "DEPARTMENT_STORES", label: "Department stores" },
    {
      value: "GENERAL_MERCHANDISE_STORES",
      label: "General merchandise stores",
    },
    { value: "APPAREL_RETAIL", label: "Apparel retail" },
    {
      value: "COMPUTER_ELECTRONICS_RETAIL",
      label: "Computer electronics retail",
    },
    { value: "HOME_IMPROVEMENT_RETAIL", label: "Home improvement retail" },
    { value: "SPECIALTY_STORES", label: "Specialty stores" },
    { value: "AUTOMOTIVE_RETAIL", label: "Automotive retail" },
    { value: "HOME_FURNISHING_RETAIL", label: "Home furnishing retail" },
  ],
  [COMPANY_SECTOR.CONSUMER_STAPLES]: [
    { value: "DRUG_RETAIL", label: "Drug retail" },
    { value: "FOOD_DISTRIBUTORS", label: "Food distributors" },
    { value: "FOOD_RETAIL", label: "Food retail" },
    {
      value: "HYPERMARKETS_SUPER_CENTERS",
      label: "Hypermarkets super centers",
    },
    { value: "BREWERS", label: "Brewers" },
    { value: "DISTILLERS_VINTERS", label: "Distillers vinters" },
    { value: "SOFT_DRINKS", label: "Soft drinks" },
    { value: "AGRICULTURAL_PRODUCTS", label: "Agricultural products" },
    { value: "PACKAGED_FOODS_MEATS", label: "Packaged foods meats" },
    { value: "TOBACCO", label: "Tobacco" },
    { value: "HOUSEHOLD_PRODUCTS", label: "Household products" },
    { value: "PERSONAL_PRODUCTS", label: "Personal products" },
  ],
  [COMPANY_SECTOR.HEALTH_CARE]: [
    { value: "HEALTHCARE_SUPPLIES", label: "Healthcare equipment" },
    { value: "HEALTHCARE_SUPPLIES", label: "Healthcare supplies" },
    { value: "HEALTHCARE_DISTRIBUTORS", label: "Healthcare distributors" },
    { value: "HEALTHCARE_SERVICES", label: "Healthcare services" },
    { value: "HEALTHCARE_FACILITIES", label: "Healthcare facilities" },
    { value: "MANAGED_HEALTHCARE", label: "Managed healthcare" },
    { value: "HEALTHCARE_TECHNOLOGY", label: "Healthcare technology" },
    { value: "BIOTECHNOLOGY", label: "Biotechnology" },
    { value: "PHARMECEURICALS", label: "Pharmeceuricals" },
    {
      value: "LIFE_SCIENCES_TOOLS_SERVICES",
      label: "Life sciences tools services",
    },
  ],
  [COMPANY_SECTOR.FINANCIALS]: [
    { value: "DIVERSIFIED_BANKS", label: "Diversified banks" },
    { value: "REGIONAL_BANKS", label: "Regional banks" },
    { value: "THRIFTS_MORTGAGE_FINANCE", label: "Thrifts mortgage finance" },
    {
      value: "OTHER_DIVERSIFIES_FINANCIAL_SERVICES",
      label: "Other diversifies financial services",
    },
    { value: "MULTI_SECTOR_HOLDINGS", label: "Multi sector holdings" },
    { value: "SPECIALIZED_FINANCE", label: "Specialized finance" },
    { value: "CONSUMER_FINANCE", label: "Consumer finance" },
    {
      value: "ASSET_MANAGEMENT_CUSTODY_BANKS",
      label: "Asset management custody banks",
    },
    {
      value: "INVESTMENT_BANKING_BROKERAGE",
      label: "Investment banking brokerage",
    },
    {
      value: "DIVERSIFIES_CAPITAL_MARKETS",
      label: "Diversifies capital markets",
    },
    { value: "FINANCIAL_EXCHANGES_DATA", label: "Financial exchanges data" },
    { value: "MORTGAGE_REIT", label: "Mortgage reit" },
    { value: "INSURANCE_BROKERS", label: "Insurance brokers" },
    { value: "LIFE_HEALTH_INSURANCE", label: "Life health insurance" },
    { value: "MULTI_LINE_INSURANCE", label: "Multi line insurance" },
    {
      value: "PROPERTY_CASUALTY_INSURANCE",
      label: "Property casualty insurance",
    },
    { value: "REINSURANCE", label: "Reinsurance" },
  ],
  [COMPANY_SECTOR.INFORMATION_TECHNOLOGY]: [
    {
      value: "IT_CONSULTING_OTHER_SERVICES",
      label: "It consulting other services",
    },
    {
      value: "DATA_PROCESSING_OUTSOURCED_SERVICES",
      label: "Data processing outsourced services",
    },
    {
      value: "INTERNET_SERVICES_INFRASTRUCTURE",
      label: "Internet services infrastructure",
    },
    { value: "APPLICATION_SOFTWARE", label: "Application software" },
    { value: "SYSTEMS_SOFTWARE", label: "Systems software" },
    { value: "COMMUNICATIONS_EQUIPMENT", label: "Communications equipment" },
    {
      value: "TECHNOLOGY_HARDWARE_STORAGE_PERIPHERALS",
      label: "Electronic equipment instruments",
    },
    {
      value: "ELECTRONIC_EQUIPMENT_INSTRUMENTS",
      label: "Electronic equipment instruments",
    },
    { value: "ELECTRONIC_COMPONENTS", label: "Electronic components" },
    {
      value: "ELECTRONIC_MANUFACTURING_SERVICES",
      label: "Electronic manufacturing services",
    },
    { value: "TECHNOLOGY_DISTRIBUTORS", label: "Technology distributors" },
    { value: "SEMICONDUCTOR_EQUIPMENT", label: "Semiconductor equipment" },
    { value: "SEMICONDUCTORS", label: "Semiconductors" },
  ],
  [COMPANY_SECTOR.TELECOMMUNICATIONS_SERVICES]: [
    { value: "ALTERNATIVE_CARRIERS", label: "Alternative carriers" },
    {
      value: "INTEGRATED_TELECOMS_SERVICES",
      label: "Integrated telecoms services",
    },
    {
      value: "WIRELESS_TELECOMS_SERVICES",
      label: "Wireless telecoms services",
    },
    { value: "ADVSERTISING", label: "Advsertising" },
    { value: "BROADCASTING", label: "Broadcasting" },
    { value: "CABLE_SATELLITE", label: "Cable satellite" },
    { value: "PUBLISHING", label: "Publishing" },
    { value: "MOVIES_ENTERTAINMENT", label: "Movies entertainment" },
    {
      value: "INTERACTIVE_HOME_ENTERTAINMENT",
      label: "Interactive home entertainment",
    },
    {
      value: "INTERACTIVE_MEDIA_SERVICES",
      label: "Interactive media services",
    },
  ],
  [COMPANY_SECTOR.UTILITIES]: [
    { value: "ELECTRONIC_UTILITIES", label: "Electronic utilities" },
    { value: "GAS_UTILITIES", label: "Gas utilities" },
    { value: "MULTI_UTILITIES", label: "Multi utilities" },
    { value: "WATER_UTILITIES", label: "Water utilities" },
    {
      value: "INDEPENDENT_POWER_PRODUCERS_ENERGY_TRADERS",
      label: "Independent power producers energy traders",
    },
    { value: "RENEWABLE_ELECTRICITY", label: "Renewable electricity" },
  ],
  [COMPANY_SECTOR.REAL_ESTATE]: [
    { value: "DIVERSIFIED_REIT", label: "Diversified reit" },
    { value: "INDUSTRIAL_REIT", label: "Industrial reit" },
    { value: "HOTEL_RESORT_REIT", label: "Hotel resort reit" },
    { value: "OFFICE_REIT", label: "Office reit" },
    { value: "HEALTHCARE_REIT", label: "Healthcare reit" },
    { value: "RESIDENTIAL_REIT", label: "Residential reit" },
    { value: "RETAIL_REIT", label: "Retail reit" },
    { value: "SPECIALIZED_REIT", label: "Specialized reit" },
    {
      value: "DIVERSIFIED_REAL_ESTATE_ACTIVITIES",
      label: "Diversified real estate activities",
    },
    {
      value: "REAL_ESTATE_OPERATING_COMPANIES",
      label: "Real estate operating companies",
    },
    { value: "REAL_ESTATE_DEVELOPMENT", label: "Real estate development" },
    { value: "REAL_ESTATE_SERVICES", label: "Real estate services" },
  ],
};

export const COMPANY_SECTOR_OPTIONS = [
  { value: "Administration", label: "Administration" },
  { value: "CharityRegistered", label: "Charity Registered" },
  { value: "CorporateTrusteeDuties", label: "Corporate Trustee Duties" },
  { value: "EducationTraining", label: "Education Training" },
  { value: "Engineering", label: "Engineering" },
  {
    value: "FinancialServiceAccounting",
    label: "Financial Service Accounting",
  },
  { value: "Investment", label: "Investment" },
  { value: "Manufacturing", label: "Manufacturing" },
  {
    value: "PrimaryProductionAndServices",
    label: "Primary Production and Services",
  },
  { value: "ProfessionalServices", label: "Professional Services" },
  { value: "PropertyRentalRealEstate", label: "Property Rental Real Estate" },
  { value: "ResearchConsulting", label: "Research Consulting" },
  { value: "TechnologyServices", label: "Technology Services" },
  { value: "TradeServices", label: "Trade Services" },
  { value: "TradingSharePurchasing", label: "Trading Share Purchasing" },
];

export const COMPANY_TYPE_OPTIONS = [
  { value: "PRIVATE", label: "Private" },
  { value: "UNLISTED_PUBLIC", label: "Unlisted Public" },
  { value: "LISTED_PUBLIC", label: "Listed Public" },
  { value: "FINANCIAL_INSTITUTION", label: "Financial Institution" },
];

export const RELATIONSHIP_TYPE = {
  OWNER: "OWNER",
  POWER_OF_ATTORNEY: "POWER_OF_ATTORNEY",
  OTHER: "OTHER",
  DIRECTOR: "DIRECTOR",
  BENEFICIAL_OWNER: "BENEFICIAL_OWNER",
  BENEFICARY: "BENEFICARY",
  BENEFICARY_CLASS: "BENEFICARY_CLASS",
  CONTRIBUTOR: "CONTRIBUTOR",
  TRUST_CONTRIBUTOR: "TRUST_CONTRIBUTOR",
  TRUST_EXECUTOR: "TRUST_EXECUTOR",
  TRUST_APPOINTER_PROTECTOR: "TRUST_APPOINTER_PROTECTOR",
  TRUST_SETTLOR: "TRUST_SETTLOR",
  MEMBER: "MEMBER",
};

export const TRUST_TYPE_OPTIONS = [
  { value: "BARE", label: "Bare" },
  { value: "DISCRETIONARY", label: "Discretionary" },
  { value: "FIXED", label: "Fixed" },
  { value: "UNIT", label: "Unit" },
  { value: "OTHER", label: "Other" },
];

export const ROLE_IN_COMPANY_OPTIONS = [
  { value: RELATIONSHIP_TYPE.DIRECTOR, label: "Director" },
  { value: RELATIONSHIP_TYPE.BENEFICIAL_OWNER, label: "Beneficial Owner" },
  { value: RELATIONSHIP_TYPE.OTHER, label: "Other" },
];

export const ROLE_IN_SUPER_OPTIONS = {
  [ACCOUNT_TYPE.INDIVIDUAL]: [
    { value: RELATIONSHIP_TYPE.MEMBER, label: "Member" },
    { value: RELATIONSHIP_TYPE.OTHER, label: "Other" },
  ],
  [ACCOUNT_TYPE.COMPANY]: [
    { value: RELATIONSHIP_TYPE.DIRECTOR, label: "Director" },
    { value: RELATIONSHIP_TYPE.MEMBER, label: "Member" },
    { value: RELATIONSHIP_TYPE.BENEFICIAL_OWNER, label: "Beneficial Owner" },
    { value: RELATIONSHIP_TYPE.OTHER, label: "Other" },
  ],
};

export const ROLE_IN_TRUST_OPTIONS = [
  { label: "Director", value: RELATIONSHIP_TYPE.DIRECTOR },
  { label: "Member", value: RELATIONSHIP_TYPE.MEMBER },
  { label: "Beneficial owner", value: RELATIONSHIP_TYPE.BENEFICIAL_OWNER },
];

export const BENEFICIAL_POSITION_OPTIONS = [
  { value: "treasurer", label: "Treasurer" },
  { value: "votermanager", label: "Voter/Manager" },
  { value: "controller", label: "Controller" },
  { value: "owner", label: "Owner" },
  { value: "other", label: "Other" },
];

export const REASON_TIN_OPTIONS = [
  {
    value: "I have not been issued a TIN",
    label: "I have not been issued a TIN",
  },
  { value: "I do not need a TIN", label: "I do not need a TIN" },
  {
    value: "I have applied for a TIN and have not yet received it",
    label: "I have applied for a TIN and have not yet received it",
  },
  {
    value: "I do not have a TIN for another reason",
    label: "I do not have a TIN for another reason",
  },
];

export const POSITION_OPTIONS = [
  { value: "administrator", label: "Administrator" },
  { value: "secretary", label: "Company Secretary" },
  { value: "director", label: "Director" },
  { value: "other", label: "Other" },
];

export const VERIFY_STATUS = {
  VERIFIED: "Verified",
  VERIFIED_WITH_MINOR_CHANGE: "Verified, with minor changes",
  VERIFIED_EXTERNALLY: "Verified externally",
  VERIFIED_BY_ADMINISTRATOR: "Verified by an administrator",
  NOT_YET_VERIFIED: "Not yet verified",
  LOCK_OUT: "Locked out",
  FAILED: "Failed",
  ABANDONED: "Abandoned",
  VERIFIED_NOT_REQUIRED: "Verification not required",
};

export const PREFERENCE_STATUS = {
  [VERIFY_STATUS.VERIFIED]: {
    icon: "CheckCircleOutline",
    img: "/company/verified-icon-21@2x.png",
    iconColor: "#A8CD37",
    background: "verified",
    text: VERIFY_STATUS.VERIFIED,
  },
  [VERIFY_STATUS.VERIFIED_WITH_MINOR_CHANGE]: {
    icon: "CheckCircleOutline",
    img: "/company/verified-icon-21@2x.png",
    iconColor: "#A8CD37",
    background: "verified",
    text: VERIFY_STATUS.VERIFIED_WITH_MINOR_CHANGE,
  },
  [VERIFY_STATUS.VERIFIED_EXTERNALLY]: {
    icon: "CheckCircleOutline",
    img: "/company/verified-icon-21@2x.png",
    iconColor: "#A8CD37",
    background: "verified",
    text: VERIFY_STATUS.VERIFIED_EXTERNALLY,
  },
  [VERIFY_STATUS.VERIFIED_BY_ADMINISTRATOR]: {
    icon: "CheckCircleOutline",
    img: "/company/verified-icon-21@2x.png",
    iconColor: "#A8CD37",
    background: "verified",
    text: VERIFY_STATUS.VERIFIED_BY_ADMINISTRATOR,
  },
  [VERIFY_STATUS.NOT_YET_VERIFIED]: {
    icon: "PauseCircleOutline",
    img: "/company/inprogress-icon-21@2x.png",
    color: "#767676",
    background: "normal",
    text: VERIFY_STATUS.NOT_YET_VERIFIED,
  },
  [VERIFY_STATUS.LOCK_OUT]: {
    icon: "Lock",
    img: "/company/lockout-icon-21@2x.png",
    color: "#B92022",
    background: "failed",
    text: VERIFY_STATUS.LOCK_OUT,
  },
  [VERIFY_STATUS.FAILED]: {
    icon: "Close",
    img: "/company/failed-icon-52@2x.png",
    color: "#B92022",
    background: "failed",
    text: VERIFY_STATUS.FAILED,
  },
  [VERIFY_STATUS.ABANDONED]: {
    icon: "PauseCircleOutline",
    img: "/company/abandoned-icon-52@2x.png",
    color: "#767676",
    background: "normal",
    text: VERIFY_STATUS.ABANDONED,
  },
  [VERIFY_STATUS.VERIFIED_NOT_REQUIRED]: {
    icon: "PauseCircleOutline",
    img: "/company/not-contributing-icon-21@2x.png",
    color: "#767676",
    background: "normal",
    text: VERIFY_STATUS.VERIFIED_NOT_REQUIRED,
  },
};

export const ACCOUNT_STATUS = {
  IN_KYC: "IN_KYC",
  REJECTED: "REJECTED",
  PENDING_KYC_APPROVAL: "PENDING_KYC_APPROVAL",
  PENDING_BANK_ACCOUNT_INFO: "PENDING_BANK_ACCOUNT_INFO",
  PENDING_BANK_TPA: "PENDING_BANK_TPA",
  PENDING_APPLICATION_SUBMIT: "PENDING_APPLICATION_SUBMIT",
  IN_PENDING_DEVELOPMENT: "IN_PENDING_DEVELOPMENT",
  IN_REFERRED: "IN_REFERRED",
  IN_DEVELOPMENT: "IN_DEVELOPMENT",
  PENDING_MANUAL_STEPS: "PENDING_MANUAL_STEPS",
  IN_REVIEW: "IN_REVIEW",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  PENDING_AUTO_STEPS: "PENDING_AUTO_STEPS",
  COMPLETED: "COMPLETED",
  CANCELLED: "CANCELLED",
};

export const ACCOUNT_STATUS_DISPLAY = {
  [ACCOUNT_STATUS.IN_KYC]: "WE ARE VERIFYING YOUR IDENTITY",
  [ACCOUNT_STATUS.REJECTED]: "WE CANNOT VERIFY YOUR IDENTITY",
  [ACCOUNT_STATUS.PENDING_KYC_APPROVAL]: "WE ARE REVIEWING YOUR APPLICATION",
  [ACCOUNT_STATUS.PENDING_BANK_ACCOUNT_INFO]:
    "WE ARE WAITING FOR YOUR BANK ACCOUNT INFO",
  [ACCOUNT_STATUS.PENDING_BANK_TPA]: "BANK ACCOUNT IN PROGRESS",
  [ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT]:
    "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.IN_PENDING_DEVELOPMENT]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.IN_REFERRED]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.IN_DEVELOPMENT]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.PENDING_MANUAL_STEPS]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.IN_REVIEW]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.PENDING_APPROVAL]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.PENDING_AUTO_STEPS]: "WE ARE PROCESSING YOUR APPLICATION",
  [ACCOUNT_STATUS.COMPLETED]: "DONE! YOUR ACCOUNT IS READY!",
  [ACCOUNT_STATUS.CANCELLED]: "YOUR APPLICATION HAS BEEN CANCELLED",
};

export const GOVERNMENT_ID_TYPE = {
  DRIVER_LICENSE: "DRIVER_LICENSE",
  MEDICARE_CARD: "MEDICARE_CARD",
  PASSPORT: "PASSPORT",
};

export const DEFAULT_APPLICANT = {
  is_trust_beneficial_owner: false,
  is_trust_beneficiary: false,
  alternate_name: "",
  applicant_id: "",
  nationality: { label: "Australia", value: "AUSTRALIA" },
  role_in_trust: "",
  role_in_super: "",
  role_in_super_other: "",
  role_in_super_individual: "",
  role_in_company: "",
  tos_consent: true,
  ekyc_aml_consent: false,
  client_address: false,
  morrison_confirm: false,
  terms_confirm: false,
  quant_edge_privacy_statement_confirm: false,
  macquarie_confirm: false,
  total_confirm: false,
  australian_tax_resident: true,
  tax_exemption: false,
  applicant_email: "",
  same_as_ra: true,
  government_id: {
    type: {
      label: "Drivers License",
      value: GOVERNMENT_ID_TYPE.DRIVER_LICENSE,
    },
    state_of_issue: "",
    number: "",
    medicare_card_expiry_date: "",
    medicare_card_colour: "",
    medicare_name_on_card: "",
    medicare_individual_reference_number: "",
  },
  equity: "",
  title: "",
  gender: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  dob: "",
  relationship_type: "",
  country_of_birth: "",
  occupation_type: "",
  occupation_category: "",
  liquor_gambling_licence: false,
  if_business_is_regulated_charity: false,
  own_cafe_restaurant: "",
  source_of_wealth: "",
  subclass_visa: false,
  tfn: "",
  tax_exemption_details: "",
  residential_address_manual_address: false,
  residential_address_street_number: "",
  residential_address_unit_flat_number: "",
  residential_address_postcode: "",
  residential_address_address_line_1: "",
  residential_address_address_line_2: "",
  residential_address_state: "",
  residential_address_street_name: "",
  residential_address_city_suburb: "",
  residential_address_street_type: "",
  residential_address_country: "",
  residential_address_full_address: "",
  applicant_mobile_phone: "",
};

export const ACCOUNT_TYPE_OPTIONS = [
  { label: "Individual", value: ACCOUNT_TYPE.INDIVIDUAL },
  { label: "Joint", value: ACCOUNT_TYPE.JOINT },
  { label: "Company", value: ACCOUNT_TYPE.COMPANY },
];

export const COMPANY_VERIFY_TYPE = {};

export const COMPANY_VERIFY_TYPE_OPTIONS = [
  { label: "Look up a business's structure using an ACN", value: "1" },
  // { label: "Look up a business's top-level details only using an ABN ", value: '2' },
  // { label: "Create a business's structure manually ", value: '3' },
];

export const GENDER_OPTIONS = [
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const AUSTRALIAN_TAX_OPTIONS = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const AUSTRALIAN_TAX_RESIDENT_OPTIONS = [
  {
    label: "Yes, I am a tax resident of Australia",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const TRUSTEE_TYPE_OPTIONS = [
  {
    label: "Individual trustee(s)",
    value: ACCOUNT_TYPE.INDIVIDUAL,
  },
  {
    label: "Corporate trustee",
    value: ACCOUNT_TYPE.COMPANY,
  },
];

export const SETTLEMENT_METHOD_OPTIONS = [
  {
    label: "Yes - Establish a new HIN",
    value: false,
  },
  {
    label: "No - Transfer an existing HIN from another broker",
    value: true,
  },
];

export const GENDER_BY_TITLE = {
  MR: "MALE",
  MRS: "FEMALE",
  MS: "FEMALE",
};

export const TITLE_OPTIONS = [
  { label: "Mr", value: "MR" },
  { label: "Mrs", value: "MRS" },
  { label: "Ms", value: "MS" },
  { label: "Miss", value: "MISS" },
  { label: "Dr", value: "DR" },
  { label: "Prof", value: "PROF" },
  { label: "Adml", value: "ADML" },
  { label: "Att", value: "ATT" },
  { label: "Brig", value: "BRIG" },
  { label: "Brn", value: "BRN" },
  { label: "Bshp", value: "BSHP" },
  { label: "Capt", value: "CAPT" },
  { label: "Cmdr", value: "CMDR" },
  { label: "Clr", value: "CLR" },
  { label: "Col", value: "COL" },
  { label: "Comm", value: "COMM" },
  { label: "Cpl", value: "CPL" },
  { label: "Dame", value: "DAME" },
  { label: "Est", value: "EST" },
  { label: "Flt", value: "FLT" },
  { label: "Fr", value: "FR" },
  { label: "Gcpt", value: "GCPT" },
  { label: "Hon", value: "HON" },
  { label: "Jdg", value: "JDG" },
  { label: "Lady", value: "LADY" },
  { label: "Lt", value: "LT" },
  { label: "Ltcl", value: "LTCL" },
  { label: "Maj", value: "MAJ" },
  { label: "Mdm", value: "MDM" },
  { label: "Msrs", value: "MSRS" },
  { label: "Mstr", value: "MSTR" },
  { label: "Pstr", value: "PSTR" },
  { label: "Rab", value: "RAB" },
  { label: "Rev", value: "REV" },
  { label: "Sen", value: "SEN" },
  { label: "Sgt", value: "SGT" },
  { label: "Sir", value: "SIR" },
  { label: "Sr", value: "SR" },
  { label: "Wcmd", value: "WCMD" },
  { label: "Other", value: "OTHER" },
];
export const GOVERNMENT_ID_TYPE_OPTIONS = [
  { label: "Drivers License", value: GOVERNMENT_ID_TYPE.DRIVER_LICENSE },
  // { label: 'Medicare Card', value: GOVERNMENT_ID_TYPE.MEDICARE_CARD },
  { label: "Passport", value: GOVERNMENT_ID_TYPE.PASSPORT },
];
export const ALL_LANGUAGE = [
  { label: "English", value: "en" },
  { label: "Vietnamese", value: "vn" },
];

export const STATE_OF_ISSUE = {
  ACT: "ACT",
  NSW: "NSW",
  NT: "NT",
  QLD: "QLD",
  SA: "SA",
  TAS: "TAS",
  VIC: "VIC",
  WA: "WA",
};

export const STATE_OF_ISSUE_OPTIONS = [
  { label: STATE_OF_ISSUE.ACT, value: STATE_OF_ISSUE.ACT },
  { label: STATE_OF_ISSUE.NSW, value: STATE_OF_ISSUE.NSW },
  { label: STATE_OF_ISSUE.NT, value: STATE_OF_ISSUE.NT },
  { label: STATE_OF_ISSUE.QLD, value: STATE_OF_ISSUE.QLD },
  { label: STATE_OF_ISSUE.SA, value: STATE_OF_ISSUE.SA },
  { label: STATE_OF_ISSUE.TAS, value: STATE_OF_ISSUE.TAS },
  { label: STATE_OF_ISSUE.VIC, value: STATE_OF_ISSUE.VIC },
  { label: STATE_OF_ISSUE.WA, value: STATE_OF_ISSUE.WA },
];

export const OWN_CAFE_RESTAURANT = {
  CAFE: "Cafe",
  RESTAURANT: "Restaurant",
};

export const OWN_CAFE_RESTAURANT_OPTIONS = [
  { label: OWN_CAFE_RESTAURANT.CAFE, value: OWN_CAFE_RESTAURANT.CAFE },
  {
    label: OWN_CAFE_RESTAURANT.RESTAURANT,
    value: OWN_CAFE_RESTAURANT.RESTAURANT,
  },
];

export const MEDICARE_CARD_COLOUR = {
  GREEN: "G",
  BLUE: "B",
  YELLOW: "Y",
};

export const MEDICARE_CARD_COLOUR_OPTIONS = [
  {
    label: "Green",
    value: MEDICARE_CARD_COLOUR.GREEN,
  },
  {
    label: "Blue",
    value: MEDICARE_CARD_COLOUR.BLUE,
  },
  {
    label: "Yellow",
    value: MEDICARE_CARD_COLOUR.YELLOW,
  },
];

export const MEDICARE_CARD_INDIDUAL_NUMBERS = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

export const OCCUPATION_TYPE = {
  PROD: {
    BUSINESS_OWNER: "Business Owner",
    CHIEF_EXECUTIVES: "Chief Executives, General Managers and Legislators",
    CLERIAL_ADMINISTRATIVE: "Clerical and administrative workers",
    COMUNITY_PERSONAL: "Community and personal service workers",
    EMPLOYEES: "Employees",
    HOMEMAKER: "Homemaker",
    LABOURERS: "Labourers",
    MACHINERY_OPERATORS: "Machinery operators and drivers",
    MILITARY: "Military",
    PROFESSIONALS: "Professionals",
    RETIRED: "Retired",
    SALES_WORKERS: "Sales workers",
    STUDENT: "Student",
    TECHNICIANS_TRADES: "Technicians and trades workers",
  },
  DEV: {
    BUSINESS_OWNER: "Business Owner",
    CHIEF_EXECUTIVES: "Chief Executives, General Managers and Legislators",
    CLERIAL_ADMINISTRATIVE: "Clerical and administrative workers",
    COMUNITY_PERSONAL: "Community and personal service workers",
    EMPLOYEES: "Employees",
    HOMEMAKER: "Homemaker",
    LABOURERS: "Labourers",
    MACHINERY_OPERATORS: "Machinery operators and drivers",
    MILITARY: "Military",
    PROFESSIONALS: "Professionals",
    RETIRED: "Retired",
    SALES_WORKERS: "Sales workers",
    STUDENT: "Student",
    TECHNICIANS_TRADES: "Technicians and trades workers",
  },
};

export const OCCUPATION_TYPE_OPTIONS = Object.values(
  OCCUPATION_TYPE[getEnv()]
).map((e) => ({ label: e, value: e }));

export const OCCUPATION_CATEGORY = {
  PROD: {
    [OCCUPATION_TYPE.PROD.BUSINESS_OWNER]: [
      "Accommodation and Food Services",
      "Administrative and Support Services",
      "Arms or Weapons Manufacture or Distribution",
      "Arts and Recreation Services",
      "Bar or Licensed Club",
      "Betting, Bookmaking, Gambling and Gaming",
      "Cafe and Restaurant",
      "Charity Community or Social Services",
      "Construction",
      "Digital Currency Traders",
      "Education and Training",
      "Electricity, Gas, Water and Waste Services",
      "Farming and Agriculture",
      "Financial and Insurance Services",
      "Health Care and Social Assistance",
      "Hotel and Motel",
      "Information Media and Telecommunications",
      "Jewel, Gem and Precious Metals",
      "Mining, Gas, Oil and Petroleum",
      "Money Exchange or Foreign FX Services",
      "Pawn Brokers",
      "Professional, Scientific and Technical Services",
      "Public Administration and Safety",
      "Real Estate Agent",
      "Rental, Hiring and Real Estate Services",
      "Retail Trade",
      "Strata Manager",
      "Transport, Postal and Warehousing",
      "Wholesale Trade",
    ],
    [OCCUPATION_TYPE.PROD.CHIEF_EXECUTIVES]: [
      "Accommodation and Food Services",
      "Administrative and Support Services",
      "Arms or Weapons Manufacture or Distribution",
      "Arts and Recreation Services",
      "Bar or Licensed Club",
      "Betting, Bookmaking, Gambling and Gaming",
      "Cafe and Restaurant",
      "Charity Community or Social Services",
      "Construction",
      "Strata Manager",
      "Digital Currency Traders",
      "Education and Training",
      "Electricity, Gas, Water and Waste Services",
      "Farming and Agriculture",
      "Financial and Insurance Services",
      "Health Care and Social Assistance",
      "Hotel and Motel",
      "Information Media and Telecommunications",
      "Jewel, Gem and Precious Metals",
      "Mining, Gas, Oil and Petroleum",
      "Money Exchange or Foreign FX Services",
      "Pawn Brokers",
      "Professional, Scientific and Technical Services",
      "Public Administration and Safety",
      "Real Estate Agent",
      "Rental, Hiring and Real Estate Services",
      "Retail Trade",
      "Transport, Postal and Warehousing",
      "Wholesale Trade",
    ],
    [OCCUPATION_TYPE.PROD.CLERIAL_ADMINISTRATIVE]: [
      "Clerical and Administrative Workers",
      "Clerical and Office Support Workers",
      "General Clerical Workers",
      "Inquiry Clerks and Receptionists",
      "Numerical Clerks",
      "Office Managers and Program Administrators",
      "Personal Assistants and Secretaries",
    ],
    [OCCUPATION_TYPE.PROD.COMUNITY_PERSONAL]: [
      "Carers and Aides",
      "Health and Welfare Support Workers",
      "Hospitality Workers",
      "Protective Service Workers",
      "Sports and Personal Service Workers",
    ],
    [OCCUPATION_TYPE.PROD.EMPLOYEES]: [
      "Advertising, Public Relations and Sales",
      "Construction, Distribution and Production",
      "Education, Health and Welfare Services",
      "Farmers and Farm",
      "Hospitality, Retail and Service",
      "Information & Communication Technology",
    ],
    [OCCUPATION_TYPE.PROD.HOMEMAKER]: ["Homemaker"],
    [OCCUPATION_TYPE.PROD.LABOURERS]: [
      "Cleaners and Laundry Workers",
      "Construction and Mining Labourers",
      "Factory Process Workers",
      "Farm, Forestry and Garden Workers",
      "Food Preparation Assistants",
      "Labourers",
    ],
    [OCCUPATION_TYPE.PROD.MACHINERY_OPERATORS]: [
      "Machinery",
      "Mobile Plant Operators",
      "Road and Rail Drivers",
      "Storepersons",
    ],
    [OCCUPATION_TYPE.PROD.MILITARY]: ["Military enlisted", "Military officer"],
    [OCCUPATION_TYPE.PROD.PROFESSIONALS]: [
      "Accountants, Auditors and Company Secretaries",
      "Arts and Media Professionals",
      "Aviation",
      "Strata Manager",
      "Business, Human Resource and Marketing Professionals",
      "Design, Engineering, Science and Transport Professionals",
      "Doctor, Veterinarian, Health Professionals",
      "Education Professionals",
      "Financial Brokers and Dealers, and Investment Advisers",
      "Information & Communication Technology Professionals",
      "Legal, Social and Welfare Professionals",
      "Real Estate Agent",
    ],
    [OCCUPATION_TYPE.PROD.RETIRED]: ["Retired"],
    [OCCUPATION_TYPE.PROD.SALES_WORKERS]: [
      "Sales Assistants and Salespersons",
      "Sales Representatives and Agents",
      "Sales Support Workers",
    ],
    [OCCUPATION_TYPE.PROD.STUDENT]: ["Student"],
    [OCCUPATION_TYPE.PROD.TECHNICIANS_TRADES]: [
      "Automotive and Engineering Trades Workers",
      "Construction Trades Workers",
      "Electrotechnology and Telecommunications Trades Workers",
      "Engineering and Science Technicians",
      "Food Trades Workers",
      "Information & Communication Technology Technicians",
      "Other Technicians and Trades Workers",
      "Skilled Animal and Horticultural Workers",
    ],
  },
  DEV: {
    [OCCUPATION_TYPE.DEV.BUSINESS_OWNER]: [
      "Accommodation and Food Services",
      "Administrative and Support Services",
      "Arms or Weapons Manufacture or Distribution",
      "Arts and Recreation Services",
      "Bar or Licensed Club",
      "Betting, Bookmaking, Gambling and Gaming",
      "Cafe and Restaurant",
      "Charity Community or Social Services",
      "Construction",
      "Digital Currency Traders",
      "Education and Training",
      "Electricity, Gas, Water and Waste Services",
      "Farming and Agriculture",
      "Financial and Insurance Services",
      "Health Care and Social Assistance",
      "Hotel and Motel",
      "Information Media and Telecommunications",
      "Jewel, Gem and Precious Metals",
      "Mining, Gas, Oil and Petroleum",
      "Money Exchange or Foreign FX Services",
      "Pawn Brokers",
      "Professional, Scientific and Technical Services",
      "Public Administration and Safety",
      "Real Estate Agent",
      "Rental, Hiring and Real Estate Services",
      "Retail Trade",
      "Strata Manager",
      "Transport, Postal and Warehousing",
      "Wholesale Trade",
    ],
    [OCCUPATION_TYPE.PROD.CHIEF_EXECUTIVES]: [
      "Accommodation and Food Services",
      "Administrative and Support Services",
      "Arms or Weapons Manufacture or Distribution",
      "Arts and Recreation Services",
      "Bar or Licensed Club",
      "Betting, Bookmaking, Gambling and Gaming",
      "Cafe and Restaurant",
      "Charity Community or Social Services",
      "Construction",
      "Strata Manager",
      "Digital Currency Traders",
      "Education and Training",
      "Electricity, Gas, Water and Waste Services",
      "Farming and Agriculture",
      "Financial and Insurance Services",
      "Health Care and Social Assistance",
      "Hotel and Motel",
      "Information Media and Telecommunications",
      "Jewel, Gem and Precious Metals",
      "Mining, Gas, Oil and Petroleum",
      "Money Exchange or Foreign FX Services",
      "Pawn Brokers",
      "Professional, Scientific and Technical Services",
      "Public Administration and Safety",
      "Real Estate Agent",
      "Rental, Hiring and Real Estate Services",
      "Retail Trade",
      "Transport, Postal and Warehousing",
      "Wholesale Trade",
    ],
    [OCCUPATION_TYPE.DEV.CLERIAL_ADMINISTRATIVE]: [
      "Clerical and Administrative Workers",
      "Clerical and Office Support Workers",
      "General Clerical Workers",
      "Inquiry Clerks and Receptionists",
      "Numerical Clerks",
      "Office Managers and Program Administrators",
      "Personal Assistants and Secretaries",
    ],
    [OCCUPATION_TYPE.DEV.COMUNITY_PERSONAL]: [
      "Carers and Aides",
      "Health and Welfare Support Workers",
      "Hospitality Workers",
      "Protective Service Workers",
      "Sports and Personal Service Workers",
    ],
    [OCCUPATION_TYPE.DEV.EMPLOYEES]: [
      "Advertising, Public Relations and Sales",
      "Construction, Distribution and Production",
      "Education, Health and Welfare Services",
      "Farmers and Farm",
      "Hospitality, Retail and Service",
      "Information & Communication Technology",
    ],
    [OCCUPATION_TYPE.DEV.HOMEMAKER]: ["Homemaker"],
    [OCCUPATION_TYPE.DEV.LABOURERS]: [
      "Cleaners and Laundry Workers",
      "Construction and Mining Labourers",
      "Factory Process Workers",
      "Farm, Forestry and Garden Workers",
      "Food Preparation Assistants",
      "Labourers",
    ],
    [OCCUPATION_TYPE.DEV.MACHINERY_OPERATORS]: [
      "Machinery",
      "Mobile Plant Operators",
      "Road and Rail Drivers",
      "Storepersons",
    ],
    [OCCUPATION_TYPE.DEV.MILITARY]: ["Military enlisted", "Military officer"],
    [OCCUPATION_TYPE.DEV.PROFESSIONALS]: [
      "Accountants, Auditors and Company Secretaries",
      "Arts and Media Professionals",
      "Aviation",
      "Strata Manager",
      "Business, Human Resource and Marketing Professionals",
      "Design, Engineering, Science and Transport Professionals",
      "Doctor, Veterinarian, Health Professionals",
      "Education Professionals",
      "Financial Brokers and Dealers, and Investment Advisers",
      "Information & Communication Technology Professionals",
      "Legal, Social and Welfare Professionals",
      "Real Estate Agent",
    ],
    [OCCUPATION_TYPE.DEV.RETIRED]: ["Retired"],
    [OCCUPATION_TYPE.DEV.SALES_WORKERS]: [
      "Sales Assistants and Salespersons",
      "Sales Representatives and Agents",
      "Sales Support Workers",
    ],
    [OCCUPATION_TYPE.DEV.STUDENT]: ["Student"],
    [OCCUPATION_TYPE.DEV.TECHNICIANS_TRADES]: [
      "Automotive and Engineering Trades Workers",
      "Construction Trades Workers",
      "Electrotechnology and Telecommunications Trades Workers",
      "Engineering and Science Technicians",
      "Food Trades Workers",
      "Information & Communication Technology Technicians",
      "Other Technicians and Trades Workers",
      "Skilled Animal and Horticultural Workers",
    ],
  },
};

export const OCCUPATION_CATEGORY_OPTIONS = Object.keys(
  OCCUPATION_CATEGORY[getEnv()]
).reduce((acc, cur) => {
  acc[cur] = OCCUPATION_CATEGORY[getEnv()][cur].map((e) => ({
    label: e,
    value: e,
  }));
  return acc;
}, {});

export const NO_TIN_REASON_OPTIONS = [
  {
    label: "I have not been issued a TIN",
    value: "I have not been issued a TIN",
  },
  {
    label: "I do not need a TIN",
    value: "I do not need a TIN",
  },
  {
    label: "I have applied for a TIN and have not yet received it",
    value: "I have applied for a TIN and have not yet received it",
  },
  {
    label: "I do not have a TIN for another reason",
    value: "I do not have a TIN for another reason",
  },
];

export const TAX_EXEMPTION_DETAILS_OPTIONS = [
  {
    label: "Tax return not required",
    value: "TAX_RETURN_NOT_REQUIRED",
  },
];

export const TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS = [
  {
    label: "Pensioner Receiving Social Security / Service Pension",
    value: "Pensioner receiving Social Security / Service Pension",
  },
  {
    label: "Recipient Of Other Social Security Pension Or Benefit",
    value: "Recipient of other Social Security Pension or benefit",
  },
  {
    label: "Not Required To Lodge A Tax Return",
    value: "Not required to lodge a Tax Return",
  },
  {
    label: "Non-resident Of Australia",
    value: "Non-resident of Australia",
  },
];

export const CMA = {
  CREATE_NEW: "NEW_CMA",
  USE_EXISTING: "EXISTING",
  DO_NOT_CREATE: "DO_NOT_CREATE",
};

export const BANK_ACCOUNT_TYPE = {
  EMPTY: "EMPTY",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  LINKED_CMT_CMA: "LINKED_CMT_CMA",
};

export const TRANSACTION_TYPE = {
  BOTH: "BOTH",
  CREDIT: "CREDIT",
  DEBIT: "DEBIT",
};

export const CMT_PROVIDER = {
  ADL: "ADL",
  ANZ: "ANZ",
  DDH: "DDH",
  DDHW: "DDHW",
  MBLA: "MBLA",
};

export const STREET_TYPE = [
  { label: "Alley", value: "ALLEY" },
  { label: "Avenue", value: "AVENUE" },
  { label: "Boulevard", value: "BOULEVARD" },
  { label: "Court", value: "COURT" },
  { label: "Crescent", value: "CRESCENT" },
  { label: "Drive", value: "DRIVE" },
  { label: "Esplanade", value: "ESPLANADE" },
  { label: "Lane", value: "LANE" },
  { label: "Mews", value: "MEWS" },
  { label: "Place", value: "PLACE" },
  { label: "Ridge", value: "RIDGE" },
  { label: "Road", value: "ROAD" },
  { label: "Street", value: "STREET" },
  { label: "Terrace", value: "TERRACE" },
  { label: "Way", value: "WAY" },
  { label: "Access", value: "ACCESS" },
  { label: "Amble", value: "AMBLE" },
  { label: "Anchorage", value: "ANCHORAGE" },
  { label: "Approach", value: "APPROACH" },
  { label: "Arcade", value: "ARCADE" },
  { label: "Artery", value: "ARTERY" },
  { label: "Alleyway", value: "ALLEYWAY" },
  { label: "Basin", value: "BASIN" },
  { label: "Beach", value: "BEACH" },
  { label: "Block", value: "BLOCK" },
  { label: "Bend", value: "BEND" },
  { label: "Bank", value: "BANK" },
  { label: "Bypass", value: "BYPASS" },
  { label: "Brace", value: "BRACE" },
  { label: "Brae", value: "BRAE" },
  { label: "Bridge", value: "BRIDGE" },
  { label: "Break", value: "BREAK" },
  { label: "Brow", value: "BROW" },
  { label: "Bowl", value: "BOWL" },
  { label: "Broadway", value: "BROADWAY" },
  { label: "Byway", value: "BYWAY" },
  { label: "Circuit", value: "CIRCUIT" },
  { label: "Cul-de-sac", value: "CUL-DE-SAC" },
  { label: "Chase", value: "CHASE" },
  { label: "Circle", value: "CIRCLE" },
  { label: "Close", value: "CLOSE" },
  { label: "Colonnade", value: "COLONNADE" },
  { label: "Circlet", value: "CIRCLET" },
  { label: "Common", value: "COMMON" },
  { label: "Concourse", value: "CONCOURSE" },
  { label: "Corner", value: "CORNER" },
  { label: "Copse", value: "COPSE" },
  { label: "Circus", value: "CIRCUS" },
  { label: "Crossroad", value: "CROSSROAD" },
  { label: "Crossway", value: "CROSSWAY" },
  { label: "Crossing", value: "CROSSING" },
  { label: "Corso", value: "CORSO" },
  { label: "Cruiseway", value: "CRUISEWAY" },
  { label: "Centre", value: "CENTRE" },
  { label: "Centreway", value: "CENTREWAY" },
  { label: "Cutting", value: "CUTTING" },
  { label: "Cove", value: "COVE" },
  { label: "Causeway", value: "CAUSEWAY" },
  { label: "Courtyard", value: "COURTYARD" },
  { label: "Dale", value: "DALE" },
  { label: "Dell", value: "DELL" },
  { label: "Dip", value: "DIP" },
  { label: "Driveway", value: "DRIVEWAY" },
  { label: "Distributor", value: "DISTRIBUTOR" },
  { label: "Deviation", value: "DEVIATION" },
  { label: "Edge", value: "EDGE" },
  { label: "Elbow", value: "ELBOW" },
  { label: "End", value: "END" },
  { label: "Entrance", value: "ENTRANCE" },
  { label: "Estate", value: "ESTATE" },
  { label: "Expressway", value: "EXPRESSWAY" },
  { label: "Fairway", value: "FAIRWAY" },
  { label: "Flat", value: "FLAT" },
  { label: "Follow", value: "FOLLOW" },
  { label: "Formation", value: "FORMATION" },
  { label: "Frontage", value: "FRONTAGE" },
  { label: "Front", value: "FRONT" },
  { label: "Foreshore", value: "FORESHORE" },
  { label: "Fire Track", value: "FIRE TRACK" },
  { label: "Firetrail", value: "FIRETRAIL" },
  { label: "Footway", value: "FOOTWAY" },
  { label: "Freeway", value: "FREEWAY" },
  { label: "Gap", value: "GAP" },
  { label: "Garden", value: "GARDEN" },
  { label: "Gardens", value: "GARDENS" },
  { label: "Glade", value: "GLADE" },
  { label: "Glen", value: "GLEN" },
  { label: "Gully", value: "GULLY" },
  { label: "Grange", value: "GRANGE" },
  { label: "Ground", value: "GROUND" },
  { label: "Green", value: "GREEN" },
  { label: "Gate", value: "GATE" },
  { label: "Gates", value: "GATES" },
  { label: "Grove", value: "GROVE" },
  { label: "Hill", value: "HILL" },
  { label: "Highroad", value: "HIGHROAD" },
  { label: "Heights", value: "HEIGHTS" },
  { label: "Highway", value: "HIGHWAY" },
  { label: "Interchange", value: "INTERCHANGE" },
  { label: "Intersection", value: "INTERSECTION" },
  { label: "Island", value: "ISLAND" },
  { label: "Junction", value: "JUNCTION" },
  { label: "Key", value: "KEY" },
  { label: "Landing", value: "LANDING" },
  { label: "Lees", value: "LEES" },
  { label: "Line", value: "LINE" },
  { label: "Link", value: "LINK" },
  { label: "Lookout", value: "LOOKOUT" },
  { label: "Lower", value: "LOWER" },
  { label: "Loop", value: "LOOP" },
  { label: "Little", value: "LITTLE" },
  { label: "Laneway", value: "LANEWAY" },
  { label: "Mall", value: "MALL" },
  { label: "Meander", value: "MEANDER" },
  { label: "Mew", value: "MEW" },
  { label: "Mile", value: "MILE" },
  { label: "Mount", value: "MOUNT" },
  { label: "Motorway", value: "MOTORWAY" },
  { label: "Nook", value: "NOOK" },
  { label: "Outlook", value: "OUTLOOK" },
  { label: "Parade", value: "PARADE" },
  { label: "Parklands", value: "PARKLANDS" },
  { label: "Pocket", value: "POCKET" },
  { label: "Parkway", value: "PARKWAY" },
  { label: "Plaza", value: "PLAZA" },
  { label: "Point", value: "POINT" },
  { label: "Pier", value: "PIER" },
  { label: "Park", value: "PARK" },
  { label: "Promenade", value: "PROMENADE" },
  { label: "Part", value: "PART" },
  { label: "Pass", value: "PASS" },
  { label: "Port", value: "PORT" },
  { label: "Path", value: "PATH" },
  { label: "Plateau", value: "PLATEAU" },
  { label: "Piazza", value: "PIAZZA" },
  { label: "Quadrant", value: "QUADRANT" },
  { label: "Quadrangle", value: "QUADRANGLE" },
  { label: "Quad", value: "QUAD" },
  { label: "Quay", value: "QUAY" },
  { label: "Quays", value: "QUAYS" },
  { label: "Ramble", value: "RAMBLE" },
  { label: "Rosebowl", value: "ROSEBOWL" },
  { label: "Reach", value: "REACH" },
  { label: "Ronde", value: "RONDE" },
  { label: "Roads", value: "ROADS" },
  { label: "Ridgeway", value: "RIDGEWAY" },
  { label: "Roadway", value: "ROADWAY" },
  { label: "Reserve", value: "RESERVE" },
  { label: "Range", value: "RANGE" },
  { label: "Rise", value: "RISE" },
  { label: "Ride", value: "RIDE" },
  { label: "Ramp", value: "RAMP" },
  { label: "Round", value: "ROUND" },
  { label: "Ring", value: "RING" },
  { label: "Row", value: "ROW" },
  { label: "Roadside", value: "ROADSIDE" },
  { label: "Rest", value: "REST" },
  { label: "Retreat", value: "RETREAT" },
  { label: "Route", value: "ROUTE" },
  { label: "Right of Way", value: "RIGHT OF WAY" },
  { label: "Rotary", value: "ROTARY" },
  { label: "Rue", value: "RUE" },
  { label: "Run", value: "RUN" },
  { label: "Riviera", value: "RIVIERA" },
  { label: "River", value: "RIVER" },
  { label: "Riverway", value: "RIVERWAY" },
  { label: "Siding", value: "SIDING" },
  { label: "State Highway", value: "STATE HIGHWAY" },
  { label: "Slope", value: "SLOPE" },
  { label: "Sound", value: "SOUND" },
  { label: "Spur", value: "SPUR" },
  { label: "Square", value: "SQUARE" },
  { label: "Stairs", value: "STAIRS" },
  { label: "Strip", value: "STRIP" },
  { label: "Steps", value: "STEPS" },
  { label: "Streets", value: "STREETS" },
  { label: "Service Way", value: "SERVICE WAY" },
  { label: "Thoroughfare", value: "THOROUGHFARE" },
  { label: "Trunkway", value: "TRUNKWAY" },
  { label: "Top", value: "TOP" },
  { label: "Tor", value: "TOR" },
  { label: "Track", value: "TRACK" },
  { label: "Triangle", value: "TRIANGLE" },
  { label: "Trail", value: "TRAIL" },
  { label: "Tarnice Way", value: "TARNICE WAY" },
  { label: "Trailer", value: "TRAILER" },
  { label: "Turn", value: "TURN" },
  { label: "Towers", value: "TOWERS" },
  { label: "Tollway", value: "TOLLWAY" },
  { label: "Underpass", value: "UNDERPASS" },
  { label: "Upper", value: "UPPER" },
  { label: "Vale", value: "VALE" },
  { label: "Viaduct", value: "VIADUCT" },
  { label: "Villa", value: "VILLA" },
  { label: "Vista", value: "VISTA" },
  { label: "View", value: "VIEW" },
  { label: "Wade", value: "WADE" },
  { label: "Wharf", value: "WHARF" },
  { label: "Walk", value: "WALK" },
  { label: "Walkway", value: "WALKWAY" },
  { label: "Wynd", value: "WYND" },
  { label: "Yard", value: "YARD" },
  { label: "Extension", value: "EXTENSION" },
];

export const CMT_PROVIDER_OPTIONS = [
  { label: "Adelaide Bank Limited (ADL)", value: CMT_PROVIDER.ADL },
  { label: "ANZ (ANZ)", value: CMT_PROVIDER.ANZ },
  { label: "DDH Graham (DDH)", value: CMT_PROVIDER.DDH },
  { label: "DDH Westpac (DDHW)", value: CMT_PROVIDER.DDHW },
  { label: "Macquarie Bank Limited (MBLA)", value: CMT_PROVIDER.MBLA },
];

export const CMA_OPTIONS = [
  {
    label: "New Macquarie CMA (Cash Management) Bank Account",
    value: CMA.CREATE_NEW,
  },
  { label: "Existing Macquarie CMA Bank Account", value: CMA.USE_EXISTING },
  { label: "None", value: CMA.DO_NOT_CREATE },
];

export const TRUST_COUNTRY_ESTABLISHED = [
  { label: "Australia", value: "AUSTRALIA" },
  { label: "Other", value: "OTHER" },
];

export const SOURCE_OF_WEALTH = {
  SAVINGS_FROM_EMPLOYMENT: "SAVINGS_FROM_EMPLOYMENT",
  BUSINESS_OPERATIONS: "BUSINESS_OPERATIONS",
  SUPERANNUATION: "SUPERANNUATION",
  INVESTMENTS: "INVESTMENTS",
  INHERITANCE_OR_GIFT: "INHERITANCE_OR_GIFT",
  FOREIGN_INCOME: "FOREIGN_INCOME",
};

export const SOURCE_OF_WEALTH_OPTIONS = [
  {
    label: "Savings from employment",
    value: SOURCE_OF_WEALTH.SAVINGS_FROM_EMPLOYMENT,
  },
  {
    label: "Business operations",
    value: SOURCE_OF_WEALTH.BUSINESS_OPERATIONS,
  },
  {
    label: "Superannuation",
    value: SOURCE_OF_WEALTH.SUPERANNUATION,
  },
  {
    label: "Investments",
    value: SOURCE_OF_WEALTH.INVESTMENTS,
  },
  {
    label: "Inheritance or gift",
    value: SOURCE_OF_WEALTH.INHERITANCE_OR_GIFT,
  },
  {
    label: "Foreign income",
    value: SOURCE_OF_WEALTH.FOREIGN_INCOME,
  },
];

export const CMA_SOURCE_OF_FUNDS = {
  PROD: {
    EMPLOYMENT_BUSINESS_INCOME: "EMPLOYMENT_BUSINESS_INCOME",
    INVESTMENTS_AND_DIVIDENDS_ASSET_SALE:
      "INVESTMENTS_AND_DIVIDENDS_ASSET_SALE",
    SUPERANNUATION_PENSION: "SUPERANNUATION_PENSION",
    SAVINGS: "SAVINGS",
    INHERITANCE_GIFT: "INHERITANCE_GIFT",
    GOVT_BENEFIT_SOCIAL_SECURITY: "GOVT_BENEFIT_SOCIAL_SECURITY",
    FOREIGN_INCOME: "FOREIGN_INCOME",
  },
  DEV: {
    EMPLOYMENT_BUSINESS_INCOME: "EMPLOYMENT_BUSINESS_INCOME",
    INVESTMENTS_AND_DIVIDENDS_ASSET_SALE:
      "INVESTMENTS_AND_DIVIDENDS_ASSET_SALE",
    SUPERANNUATION_PENSION: "SUPERANNUATION_PENSION",
    SAVINGS: "SAVINGS",
    INHERITANCE_GIFT: "INHERITANCE_GIFT",
    GOVT_BENEFIT_SOCIAL_SECURITY: "GOVT_BENEFIT_SOCIAL_SECURITY",
    FOREIGN_INCOME: "FOREIGN_INCOME",
  },
};

export const CMA_SOURCE_OF_FUNDS_OPTIONS = {
  PROD: [
    {
      label: "Employment Business Income",
      value: CMA_SOURCE_OF_FUNDS.PROD.EMPLOYMENT_BUSINESS_INCOME,
    },
    {
      label: "Investments and Dividends Asset Sale",
      value: CMA_SOURCE_OF_FUNDS.PROD.INVESTMENTS_AND_DIVIDENDS_ASSET_SALE,
    },
    {
      label: "Superannuation Pension",
      value: CMA_SOURCE_OF_FUNDS.PROD.SUPERANNUATION_PENSION,
    },
    {
      label: "Savings",
      value: CMA_SOURCE_OF_FUNDS.PROD.SAVINGS,
    },
    {
      label: "Inheritance Gift",
      value: CMA_SOURCE_OF_FUNDS.PROD.INHERITANCE_GIFT,
    },
    {
      label: "Govt Benefit Social Security",
      value: CMA_SOURCE_OF_FUNDS.PROD.GOVT_BENEFIT_SOCIAL_SECURITY,
    },
    {
      label: "Foreign Income",
      value: CMA_SOURCE_OF_FUNDS.PROD.FOREIGN_INCOME,
    },
  ],
  DEV: [
    {
      label: "Employment Business Income",
      value: CMA_SOURCE_OF_FUNDS.DEV.EMPLOYMENT_BUSINESS_INCOME,
    },
    {
      label: "Investments and Dividends Asset Sale",
      value: CMA_SOURCE_OF_FUNDS.DEV.INVESTMENTS_AND_DIVIDENDS_ASSET_SALE,
    },
    {
      label: "Superannuation Pension",
      value: CMA_SOURCE_OF_FUNDS.DEV.SUPERANNUATION_PENSION,
    },
    {
      label: "Savings",
      value: CMA_SOURCE_OF_FUNDS.DEV.SAVINGS,
    },
    {
      label: "Inheritance Gift",
      value: CMA_SOURCE_OF_FUNDS.DEV.INHERITANCE_GIFT,
    },
    {
      label: "Govt Benefit Social Security",
      value: CMA_SOURCE_OF_FUNDS.DEV.GOVT_BENEFIT_SOCIAL_SECURITY,
    },
    {
      label: "Foreign Income",
      value: CMA_SOURCE_OF_FUNDS.DEV.FOREIGN_INCOME,
    },
  ],
};

export const CMA_ACCOUNT_PURPOSE = {
  SAVINGS: "SAVINGS",
  PERSONAL_DAY_TO_DAY_USAGE: "PERSONAL_DAY_TO_DAY_USAGE",
  INVESTMENT_GROWTH: "INVESTMENT_GROWTH",
  BUSINESS_TRANSACTING: "BUSINESS_TRANSACTING",
  SUPERANNUATION_PENSION: "SUPERANNUATION_PENSION",
};

export const CMA_ACCOUNT_PURPOSE_OPTIONS = {
  [ACCOUNT_TYPE.INDIVIDUAL]: [
    { label: "Savings", value: CMA_ACCOUNT_PURPOSE.SAVINGS },
    {
      label: "Personal Day to Day Usage",
      value: CMA_ACCOUNT_PURPOSE.PERSONAL_DAY_TO_DAY_USAGE,
    },
    {
      label: "Investment Growth",
      value: CMA_ACCOUNT_PURPOSE.INVESTMENT_GROWTH,
    },
    {
      label: "Business Transacting",
      value: CMA_ACCOUNT_PURPOSE.BUSINESS_TRANSACTING,
    },
  ],
  [ACCOUNT_TYPE.COMPANY]: [
    { label: "Savings", value: CMA_ACCOUNT_PURPOSE.SAVINGS },
    {
      label: "Investment Growth",
      value: CMA_ACCOUNT_PURPOSE.INVESTMENT_GROWTH,
    },
    {
      label: "Business Transacting",
      value: CMA_ACCOUNT_PURPOSE.BUSINESS_TRANSACTING,
    },
  ],
  [ACCOUNT_TYPE.FUND]: [
    {
      label: "Superannuation Pension",
      value: CMA_ACCOUNT_PURPOSE.SUPERANNUATION_PENSION,
    },
  ],
};

export const BSB_OPTION = [
  { label: "182-222", value: "182-222" },
  { label: "184-446", value: "184-446" },
  { label: "182-512", value: "182-512" },
  { label: "183-334", value: "183-334" },
  { label: "186-200", value: "186-200" },
];

export const LIST_OF_PIDS = [
  { label: "[01027] Euroz Securities Limited", value: "01027" },
  { label: "[01028] Euroz Securities Limited", value: "01028" },
  { label: "[01033] Terrain Securities Pty Ltd", value: "01033" },
  { label: "[01042] Cybertrade Australia Securities Limited", value: "01042" },
  { label: "[01052] Westpac Securities Limited", value: "01052" },
  { label: "[01062] Bridges Financial Services Pty Ltd", value: "01062" },
  { label: "[01063] Bridges Financial Services Pty Limited", value: "01063" },
  { label: "[01064] Bridges Financial Services Pty Limited", value: "01064" },
  { label: "[01089] Morrison Securities Pty Ltd", value: "01089" },
  { label: "[01103] Credit Suisse First Boston Aust Equ Ltd", value: "01103" },
  { label: "[01104] Credit Suisse Equities (australia) Ltd", value: "01104" },
  { label: "[01113] Berndale Securities Ltd", value: "01113" },
  { label: "[01123] Burdett, Buckeridge & Young Limited", value: "01123" },
  { label: "[01124] Bby Limited", value: "01124" },
  { label: "[01132] Cameron Stockbroking Limited", value: "01132" },
  { label: "[01135] Openmarkets Australia Limited", value: "01135" },
  { label: "[01136] Open Markets Australia Limited", value: "01136" },
  { label: "[01153] Peake Lands Kirwan Pty Ltd", value: "01153" },
  { label: "[01158] Lands Kirwan Tong Stockbrokers Pty Ltd", value: "01158" },
  { label: "[01183] Fleet Stockbroking Ltd", value: "01183" },
  { label: "[01192] Fortis Clearing Sydney Pty Limited", value: "01192" },
  { label: "[01193] Abn Amro Clearing Sydney Pty Ltd", value: "01193" },
  { label: "[01212] Reynolds & Co Pty Ltd", value: "01212" },
  { label: "[01226] Wealthhub Securities Limited", value: "01226" },
  { label: "[01227] Wealthhub Securities Limited", value: "01227" },
  { label: "[01231] Foster Stockbroking Pty Ltd", value: "01231" },
  { label: "[01312] Grange Securities Limited", value: "01312" },
  { label: "[01322] Bnp Paribas Equities Private (aust) Ltd", value: "01322" },
  { label: "[01353] Bnp Paribas Equities (australia) Limited", value: "01353" },
  { label: "[01382] Hudson Securities Pty Ltd", value: "01382" },
  { label: "[01392] Dicksons Ltd", value: "01392" },
  { label: "[01402] Commonwealth Securities Limited", value: "01402" },
  { label: "[01442] Etrade Australia Securities Limited", value: "01442" },
  { label: "[01452] Southern Cross Equities Ltd", value: "01452" },
  { label: "[01459] Southern Cross Equities Limited", value: "01459" },
  { label: "[01493] William Noall Ltd", value: "01493" },
  { label: "[01502] Ubs Securities Australia Limited", value: "01502" },
  { label: "[01505] Ubs Securities Australia Limited", value: "01505" },
  { label: "[01543] Bell Potter Securities Limited", value: "01543" },
  { label: "[01572] Macquarie Securities (australia) Limited", value: "01572" },
  { label: "[01602] Commonwealth Securities Ltd (margin Len)", value: "01602" },
  { label: "[01652] Religare Securities Australia Pty Ltd", value: "01652" },
  { label: "[01782] Findlay & Co Stockbroking Ltd", value: "01782" },
  { label: "[01792] Pershing Securities Australia Pty Ltd", value: "01792" },
  { label: "[01802] Hsbc Stockbroking (australia) Pty Ltd", value: "01802" },
  { label: "[01822] Finclear Execution Limited", value: "01822" },
  { label: "[01833] Tiffit Securities (australia) Ptd Ltd", value: "01833" },
  { label: "[01843] Merrill Lynch Private (australia) Ltd", value: "01843" },
  { label: "[01862] Cazenove Australia Pty Ltd", value: "01862" },
  { label: "[01892] Bnp Paribas Securities Sevices", value: "01892" },
  { label: "[01992] Avcol Stockbroking Pty Ltd", value: "01992" },
  { label: "[02002] Td Waterhouse Investor Services Limited", value: "02002" },
  { label: "[02022] Baker Young Stockbrokers Limited", value: "02022" },
  { label: "[02026] Baker Young Stockbrokers Limited", value: "02026" },
  { label: "[02032] Citigroup Global Markets Australia P/l", value: "02032" },
  { label: "[02033] Citigroup Global Markets Aust Pty Ltd", value: "02033" },
  { label: "[02102] Deutsche Securities Australia Limited", value: "02102" },
  { label: "[02103] Deutsche Securities Australia Limited", value: "02103" },
  { label: "[02104] Deutsche Securities Australia Limited", value: "02104" },
  { label: "[02105] Deutsche Securities Australia Limited", value: "02105" },
  { label: "[02183] Mj Wren And Partners Stockbrokers", value: "02183" },
  { label: "[02262] Joseph Palmer & Sons", value: "02262" },
  { label: "[02282] Aot Australia Pty Ltd", value: "02282" },
  { label: "[02332] Ord Minnett Ltd", value: "02332" },
  { label: "[02337] Ord Minnett Limited", value: "02337" },
  { label: "[02338] Ord Minnett", value: "02338" },
  { label: "[02339] Ord Minnett Limited", value: "02339" },
  { label: "[02353] Anz Securities Limited", value: "02353" },
  { label: "[02442] Macquarie Equities Ltd Retail", value: "02442" },
  { label: "[02492] Statton Securities", value: "02492" },
  { label: "[02493] Ccz Statton Equities Pty Ltd", value: "02493" },
  { label: "[02502] Instinet Australia Limited", value: "02502" },
  { label: "[02542] Rbc Securities Australia Pty Limited", value: "02542" },
  { label: "[02552] Third Party Platform Pty Ltd", value: "02552" },
  { label: "[02622] Kj Polkinghorne & Co Pty Ltd", value: "02622" },
  { label: "[02652] Cmc Markets Stockbroking Limited", value: "02652" },
  { label: "[02662] Cmc Markets Stockbroking Limited", value: "02662" },
  { label: "[02703] Abn Amro Equities Australia Ltd", value: "02703" },
  { label: "[02705] Cimb Securities (australia) Ltd", value: "02705" },
  { label: "[02894] D2mx Pty Ltd", value: "02894" },
  { label: "[02921] Intersuisse Securities Limited", value: "02921" },
  { label: "[02922] Octa Phillip Securities Limited(pershing", value: "02922" },
  { label: "[02923] Intersuisse Ltd", value: "02923" },
  { label: "[02924] Phillip Capital Limited", value: "02924" },
  { label: "[02972] Jp Morgan Securities Australia Limited", value: "02972" },
  { label: "[02981] Shaw Stockbroking Limited", value: "02981" },
  { label: "[02982] Shaw Stockbroking Limited", value: "02982" },
  { label: "[02992] Morgan Stanley Dean Witter Aust Sec Ltd", value: "02992" },
  { label: "[03003] Ubs Private Clients Australia Limited", value: "03003" },
  { label: "[03033] Citigroup Securities Clearing Aust Ltd", value: "03033" },
  { label: "[03063] Daiwa Securities Smbc Stockbroking Ltd", value: "03063" },
  { label: "[03073] Austock Securities Limited", value: "03073" },
  { label: "[03074] Austock Limited", value: "03074" },
  { label: "[03083] Barton Capital Securities Pty Ltd", value: "03083" },
  { label: "[03102] Comsec Trading Limited", value: "03102" },
  { label: "[03103] Cba Equities Limited", value: "03103" },
  { label: "[03113] El&c Baillieu Stockbroking Ltd", value: "03113" },
  { label: "[03118] Baillieu Holst Ltd", value: "03118" },
  { label: "[03173] Johnson Taylor Potter Limited", value: "03173" },
  { label: "[03223] Tolhurst Noall Limited", value: "03223" },
  { label: "[03273] Falkiners Stockbroking Limited", value: "03273" },
  { label: "[03303] Opes Prime Stockbroking Ltd", value: "03303" },
  { label: "[03332] Tir Securities (australia) Limited", value: "03332" },
  { label: "[03383] Morgan Stanley Wealth Management Austral", value: "03383" },
  { label: "[03453] Itg Australia Limited", value: "03453" },
  { label: "[03523] Fw Holst & Co Pty Ltd", value: "03523" },
  { label: "[03552] Lonsec Limited", value: "03552" },
  { label: "[03554] Lonsec Limited (pershing)", value: "03554" },
  { label: "[03555] Finclear Execution Limited (ubs)", value: "03555" },
  { label: "[03556] Finclear Execution Limited (ubs)", value: "03556" },
  { label: "[03610] Goldman Sachs J B Were Pty Ltd", value: "03610" },
  { label: "[03614] Goldman Sachs Jb Were", value: "03614" },
  { label: "[03663] Merrill Lynch Equities (australia) Ltd", value: "03663" },
  { label: "[03666] Merrill Lynch Equities (aust) Ltd", value: "03666" },
  { label: "[03882] Charles Schwab Australia Pty Ltd", value: "03882" },
  { label: "[04064] Morgans Financial Limited", value: "04064" },
  { label: "[04094] Burrell Stockbroking Pty Ltd", value: "04094" },
  { label: "[04114] Challenger First Pacific Limited", value: "04114" },
  { label: "[04124] Wilson Htm Ltd", value: "04124" },
  { label: "[04125] Wilsons Advisory And Stockbroking Ltd", value: "04125" },
  { label: "[04126] Wilsons Advisory And Stockbroking Ltd", value: "04126" },
  { label: "[05125] Taylor Collison Ltd", value: "05125" },
  { label: "[05127] Taylor Collison Limited", value: "05127" },
  { label: "[05128] Taylor Collison Limited", value: "05128" },
  { label: "[05129] Taylor Collison Limited", value: "05129" },
  { label: "[06046] Dj Carmichael Pty Limited", value: "06046" },
  { label: "[06047] Dj Carmichael Pershing", value: "06047" },
  { label: "[06056] Cibc World Markets Australia Limited", value: "06056" },
  { label: "[06066] Jdv Limited", value: "06066" },
  { label: "[06086] Hartleys Limited", value: "06086" },
  { label: "[06126] Kirke Securities Ltd", value: "06126" },
  { label: "[06128] Kirke Securities Ltd", value: "06128" },
  { label: "[06129] Kirke Securities Ltd", value: "06129" },
  { label: "[06176] Montagu Stockbrokers Pty Ltd", value: "06176" },
  { label: "[06186] Hogan & Partners Stockbrokers Pty Ltd", value: "06186" },
  { label: "[06206] Chartpac Securities Ltd", value: "06206" },
  { label: "[06381] Australian Investment Exchange Limited", value: "06381" },
  { label: "[06386] Australian Investment Exchange", value: "06386" },
  { label: "[06682] Tricom Equities Limited", value: "06682" },
  { label: "[06684] Stonebridge Securities Limited", value: "06684" },
  { label: "[06776] Canaccord Genuity Patersons Limited", value: "06776" },
  { label: "[06777] Canaccord Genuity Financial Ltd", value: "06777" },
  { label: "[06786] State One Stockbroking Ltd", value: "06786" },
  { label: "[06788] State One Stockbroking", value: "06788" },
  { label: "[06886] Mortimer & Chua Pty Ltd", value: "06886" },
  { label: "[07047] Shadforths Limited", value: "07047" },
  { label: "[07107] C J Weedon & Company", value: "07107" },
  { label: "[08014] Citicorp Warrants", value: "08014" },
  { label: "[08072] Rbs Warrants", value: "08072" },
  { label: "[08332] Australian Clearing House Pty Limited", value: "08332" },
  { label: "[08902] Asx International Services Pty Limited", value: "08902" },
  { label: "[09232] Optiver Australia Pty Ltd", value: "09232" },
  { label: "[09402] Timberhill Australia", value: "09402" },
  { label: "[11225] Onevue Fund Serviced Limited", value: "11225" },
  { label: "[11331] National Australia Bank Ltd", value: "11331" },
  { label: "[20001] Commonwealth Custodial Services Limited", value: "20001" },
  { label: "[20003] Jpmorgan Custodial Services Pty Ltd", value: "20003" },
  { label: "[20005] Anz Nominees Limited", value: "20005" },
  { label: "[20006] National Australia Bank Custodian Serv", value: "20006" },
  { label: "[20007] Bnp Paribas Fund Services Australasia Pl", value: "20007" },
  { label: "[20009] Rbc Global Services Australia Pty Ltd", value: "20009" },
  { label: "[20016] Navigator Australia Limited", value: "20016" },
  { label: "[20018] Citicorp Nominees Pty Ltd", value: "20018" },
  { label: "[20021] Bt Securities Limited (margin Lending)", value: "20021" },
  { label: "[20025] Trust Company Of Australia Limited", value: "20025" },
  { label: "[20027] Permanent Trustee Company Ltd", value: "20027" },
  { label: "[20031] Hih Casualty & General Insurance Ltd", value: "20031" },
  {
    label: "[20036] Merrill Lynch (australia) Nominees Pty Ltd",
    value: "20036",
  },
  { label: "[20039] Invia Custodian Pty Ltd", value: "20039" },
  { label: "[20041] Westpac Custodian Nominees Limited", value: "20041" },
  { label: "[20042] Iag Asset Management Limited", value: "20042" },
  { label: "[20052] Hsbc Asset Management Noms (aust) P/l", value: "20052" },
  { label: "[20054] Equity Trustees Limited", value: "20054" },
  { label: "[20057] Hsbc Custody Nominees (aust) Limited", value: "20057" },
  { label: "[20059] National Mutual Funds Management Limited", value: "20059" },
  { label: "[20063] Guardian Trust Australia Limited", value: "20063" },
  { label: "[20066] Certes Ct Pty Ltd", value: "20066" },
  { label: "[20068] Perpetual Trustees Consolidated Limited", value: "20068" },
  { label: "[20075] Australian Casualty & Life Limited", value: "20075" },
  { label: "[20080] Jpmorgan Custodians Limited", value: "20080" },
  { label: "[20081] Jpmorgan Custodial Services P/l (btlife)", value: "20081" },
  { label: "[20082] Sandhurst Trustees Limited", value: "20082" },
  { label: "[20083] Ioof Investment Management Limited (vic)", value: "20083" },
  { label: "[20085] Tower Australia Limited", value: "20085" },
  { label: "[20092] Sepon (australia) Pty Limited", value: "20092" },
  { label: "[20096] Gio Asset Management Ltd", value: "20096" },
  { label: "[20098] Anz Executors & Trustee Company Limited", value: "20098" },
  { label: "[20100] Cgu Insurance Limited", value: "20100" },
  { label: "[20104] Jp Morgan Nominess Australia Limited", value: "20104" },
  { label: "[20106] National Australia Trustees Limited", value: "20106" },
  { label: "[20110] Bank Of Western Australia Limited", value: "20110" },
  { label: "[20117] Margaret Street Nominees Pty Limited", value: "20117" },
  { label: "[20120] Australian Executor Trustee Limited", value: "20120" },
  { label: "[20127] Computershare Clearing Pty Ltd", value: "20127" },
  { label: "[20128] Jdv Solutions Pty Ltd", value: "20128" },
  { label: "[20131] Asgard Capital Management Limited", value: "20131" },
  { label: "[20151] Suncorp Metway Insurance Limited", value: "20151" },
  { label: "[20152] Suncorp Custodian Services Pty Ltd", value: "20152" },
  { label: "[20157] National Mutual Life Nominees Ltd", value: "20157" },
  { label: "[20159] State Trustees Limited", value: "20159" },
  { label: "[20168] Individual Portfolio Managers Pty Ltd", value: "20168" },
  { label: "[20306] Bpc Securities Pty Ltd", value: "20306" },
  { label: "[20311] Australian Stockbroking & Advisory Serv", value: "20311" },
  { label: "[20366] Merrill Lynch Investment Managers Ltd", value: "20366" },
  { label: "[20367] Netwealth Investments Limited", value: "20367" },
  { label: "[20378] Link Market Services", value: "20378" },
  { label: "[20404] Custody Execution & Clearing Services Pl", value: "20404" },
  { label: "[21000] Ing Life Limited", value: "21000" },
  { label: "[21003] Bond Street Custodians Limited Insto", value: "21003" },
  { label: "[21007] Tower Trust (sa) Ltd", value: "21007" },
  { label: "[21008] Leveraged Equities Nominees Limited", value: "21008" },
  { label: "[21009] Public Trustee (sa)", value: "21009" },
  { label: "[21013] Hsbc Securities Investments (aust) P/l", value: "21013" },
  { label: "[21015] Macquarie Investment Management Ltd", value: "21015" },
  { label: "[21017] Zurich Investment Management Limited", value: "21017" },
  { label: "[21018] Metway Credit Corporation Ltd", value: "21018" },
  { label: "[21019] Anz Margin Services Pty Ltd", value: "21019" },
  { label: "[21021] Tyndall Investment Management Limited", value: "21021" },
  { label: "[21022] Perpetual Trustees Tasmania Limited", value: "21022" },
  { label: "[21023] Acs Securities Pty Ltd", value: "21023" },
  { label: "[21024] Jpmorgan Custodial Services P/l (subcus)", value: "21024" },
  { label: "[21025] Bt Portfolio Services Limited", value: "21025" },
  { label: "[21026] Bnp Paribas Equity Finance Pty Ltd", value: "21026" },
  { label: "[21028] Bond Street Custodians Ltd Ptf Serv", value: "21028" },
  { label: "[21029] Office Of The Protective Commissioner", value: "21029" },
  { label: "[21030] National Margin Services Pty Limited", value: "21030" },
  { label: "[21031] National Stock Exchange Of Australia Ltd", value: "21031" },
  { label: "[21033] The Rock Investment Planning", value: "21033" },
  { label: "[21034] Bt Portfolio Services Ltd", value: "21034" },
  { label: "[21035] Tasmanian Perpetual Trustees Limited", value: "21035" },
  { label: "[21036] Mutual Trust Pty Ltd", value: "21036" },
  { label: "[21104] Jp Morgan Nominees Australia Ltd Gsp", value: "21104" },
  { label: "[21105] Anz Nominees Ltd", value: "21105" },
  { label: "[21119] Rbc Investor Services Trust", value: "21119" },
  { label: "[22123] Chimaera Capital Pty Ltd", value: "22123" },
  { label: "[22353] Anz Securities Limited", value: "22353" },
  { label: "[22888] Value Nominees (st George)", value: "22888" },
  { label: "[23005] Ubs Wealth Management Australia", value: "23005" },
  { label: "[27048] Shadforths/ubs Securities", value: "27048" },
  { label: "[27050] Bnp Paribas Securities Sevices", value: "27050" },
  { label: "[27052] One Managed Investment Funds Limited", value: "27052" },
  { label: "[27054] Crestone Wealth Management Limited", value: "27054" },
];

export const SETTLEMENT_METHOD = {
  SPONSORED_NEW_HIN: "SPONSORED_NEW_HIN",
  SPONSORED_HIN_TRANSFER: "SPONSORED_HIN_TRANSFER",
  DVP: "DVP",
  ISSUER_SPONSORED: "ISSUER_SPONSORED",
};

export const LIST_OF_SETTLEMENT_METHODS = [
  {
    value: SETTLEMENT_METHOD.SPONSORED_NEW_HIN,
    label: "New HIN",
  },
  {
    value: SETTLEMENT_METHOD.SPONSORED_HIN_TRANSFER,
    label: "HIN Transfer",
  },
  {
    value: SETTLEMENT_METHOD.DVP,
    label: "DVP (Delivery VS. Payment)",
  },
  {
    value: SETTLEMENT_METHOD.ISSUER_SPONSORED,
    label: "Issuer Sponsored",
  },
];

export const FATCA_STATUS = {
  DEEMED_COMPLIANT_FINANCIAL_INSTITUTION: "DeemedCompliantFinancialInstitution",
  EXCEPTED_FINANCIAL_INSTITUTION: "ExceptedFinancialInstitution",
  EXEMPT_BENEFICIAL_OWNER: "ExemptBeneficialOwner",
  NON_REPORTING_IGA_FINANCIAL_INSTITUTION:
    "NonReportingIGAFinancialInstitution",
  NONPARTICIPATING_FINANCIAL_INSTITUTION:
    "NonparticipatingFinancialInstitution",
  OTHER: "Other",
};

export const FATCA_STATUS_OPTIONS = [
  {
    value: FATCA_STATUS.DEEMED_COMPLIANT_FINANCIAL_INSTITUTION,
    label: "Deemed Compliant Financial Institution",
  },
  {
    value: FATCA_STATUS.EXCEPTED_FINANCIAL_INSTITUTION,
    label: "Excepted Financial Institution",
  },
  {
    value: FATCA_STATUS.EXEMPT_BENEFICIAL_OWNER,
    label: "Exempt Beneficial Owner",
  },
  {
    value: FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION,
    label: "Non Reporting IGA Financial Institution",
  },
  {
    value: FATCA_STATUS.NONPARTICIPATING_FINANCIAL_INSTITUTION,
    label: "Nonparticipating Financial Institution",
  },
  { value: FATCA_STATUS.OTHER, label: "Other" },
];

export const FATCA_STATUS_OPTIONALS = [
  { value: null, label: "Please Select" },
  {
    value: FATCA_STATUS.DEEMED_COMPLIANT_FINANCIAL_INSTITUTION,
    label: "Deemed Compliant Financial Institution",
  },
  {
    value: FATCA_STATUS.EXCEPTED_FINANCIAL_INSTITUTION,
    label: "Excepted Financial Institution",
  },
  {
    value: FATCA_STATUS.EXEMPT_BENEFICIAL_OWNER,
    label: "Exempt Beneficial Owner",
  },
  {
    value: FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION,
    label: "Non Reporting IGA Financial Institution",
  },
  {
    value: FATCA_STATUS.NONPARTICIPATING_FINANCIAL_INSTITUTION,
    label: "Nonparticipating Financial Institution",
  },
  { value: FATCA_STATUS.OTHER, label: "Other" },
];

export const TAX_STATUS = {
  FINANCIAL_INSTITUTION: "FinancialInstitution",
  AUSTRALIAN_REGISTERED_CHARITY_OR_DECEASED_ESTATE:
    "AustralianRegisteredCharityOrDeceasedEstate",
  ACTIVE_NONFINANCIAL_ENTITY: "ActiveNonFinancialEntity",
  OTHER: "Other",
};

export const TAX_STATUS_OPTIONS = [
  {
    value: TAX_STATUS.FINANCIAL_INSTITUTION,
    label: "Financial Institution",
  },
  {
    value: TAX_STATUS.AUSTRALIAN_REGISTERED_CHARITY_OR_DECEASED_ESTATE,
    label: "Australian Registered Charity Or Deceased Estate",
  },
  {
    value: TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY,
    label: "Active Non-Financial Entity (NFE)",
  },
  {
    value: TAX_STATUS.OTHER,
    label: "Other",
  },
];

export const TAX_STATUS_COMPANY_OPTIONS = [
  {
    value: TAX_STATUS.FINANCIAL_INSTITUTION,
    label: "Financial Institution",
  },
  {
    value: TAX_STATUS.AUSTRALIAN_REGISTERED_CHARITY_OR_DECEASED_ESTATE,
    label: "Australian Registered Charity",
  },
  {
    value: TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY,
    label: "Active Non-Financial Entity (NFE)",
  },
  {
    value: TAX_STATUS.OTHER,
    label: "Other",
  },
];

export const TIN_REASON_OPTIONS = [
  { value: null, label: "Please Select" },
  {
    value: "CountryOfTaxResidencyDoesNotIssueTIN",
    label: "The country of tax residency does not issue TINs to tax residents",
  },
  {
    value: "NotIssuedWithTIN",
    label: "The individual or entity has not been issued with a TIN",
  },
  {
    value: "NoTINRequiredForCountryOfTaxResidency",
    label:
      "The country of tax residency does not require the TIN to be disclosed",
  },
];
export const ACCOUNT_TYPE_PUT_DRAFT = {
  COMPANY: "COMPANY",
  TRUST: "TRUST",
  FUND: "SUPER_FUND",
};

export const TRUST_ACTIVITY = {
  ADMINISTRATION: "Administration",
  CHARITY_REGISTERED: "CharityRegistered",
  EDUCATION_TRAINING: "EducationTraining",
  ENGINEERING: "Engineering",
  FINANCIAL_SERVICE_ACCOUNTING: "FinancialServiceAccounting",
  INVESTMENT: "Investment",
  MANUFACTURING: "Manufacturing",
  PRIMARY_PRODUCTION_AND_SERVICES: "PrimaryProductionAndServices",
  PROFESSIONAL_SERVICES: "ProfessionalServices",
  PROPERTY_RENTAL_REAL_ESTATE: "PropertyRentalRealEstate",
  RESEARCH_CONSULTING: "ResearchConsulting",
  TECHNOLOGY_SERVICES: "TechnologyServices",
  TRADE_SERVICES: "TradeServices",
  TRADING_SHARE_PURCHASING: "TradingSharePurchasing",
  SUPERANNUATION_PENSION: "SuperannuationPension",
};

export const TRUST_ACTIVITY_OPTIONS = [
  { value: TRUST_ACTIVITY.ADMINISTRATION, label: "Administration" },
  { value: TRUST_ACTIVITY.CHARITY_REGISTERED, label: "Charity Registered" },
  { value: TRUST_ACTIVITY.EDUCATION_TRAINING, label: "Education Training" },
  { value: TRUST_ACTIVITY.ENGINEERING, label: "Engineering" },
  {
    value: TRUST_ACTIVITY.FINANCIAL_SERVICE_ACCOUNTING,
    label: "Financial Service Accounting",
  },
  { value: TRUST_ACTIVITY.INVESTMENT, label: "Investment" },
  { value: TRUST_ACTIVITY.MANUFACTURING, label: "Manufacturing" },
  {
    value: TRUST_ACTIVITY.PRIMARY_PRODUCTION_AND_SERVICES,
    label: "Primary Production and Services",
  },
  {
    value: TRUST_ACTIVITY.PROFESSIONAL_SERVICES,
    label: "Professional Services",
  },
  {
    value: TRUST_ACTIVITY.PROPERTY_RENTAL_REAL_ESTATE,
    label: "Property Rental Real Estate",
  },
  { value: TRUST_ACTIVITY.RESEARCH_CONSULTING, label: "Research Consulting" },
  { value: TRUST_ACTIVITY.TECHNOLOGY_SERVICES, label: "Technology Services" },
  { value: TRUST_ACTIVITY.TRADE_SERVICES, label: "Trade Services" },
  {
    value: TRUST_ACTIVITY.TRADING_SHARE_PURCHASING,
    label: "Trading Share Purchasing",
  },
  {
    value: TRUST_ACTIVITY.SUPERANNUATION_PENSION,
    label: "Superannuation / Pension",
  },
];

export const INDUSTRY_OPTIONS = [
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Aquaculture",
    value: "Aquaculture",
  },
  {
    label: "Forestry and Logging",
    value: "Forestry and Logging",
  },
  {
    label: "Fishing, Hunting and Trapping",
    value: "Fishing, Hunting and Trapping",
  },
  {
    label: "Agriculture, Forestry and Fishing Support Services",
    value: "Agriculture, Forestry and Fishing Support Services",
  },
  {
    label: "Coal Mining",
    value: "Coal Mining",
  },
  {
    label: "Oil and Gas Extraction",
    value: "Oil and Gas Extraction",
  },
  {
    label: "Metal Ore Mining",
    value: "Metal Ore Mining",
  },
  {
    label: "Non-Metallic Mineral Mining and Quarrying",
    value: "Non-Metallic Mineral Mining and Quarrying",
  },
  {
    label: "Exploration and Other Mining Support Services",
    value: "Exploration and Other Mining Support Services",
  },
  {
    label: "Food Product Manufacturing",
    value: "Food Product Manufacturing",
  },
  {
    label: "Beverage and Tobacco Product Manufacturing",
    value: "Beverage and Tobacco Product Manufacturing",
  },
  {
    label: "Textile, Leather, Clothing and Footwear Manufacturing",
    value: "Textile, Leather, Clothing and Footwear Manufacturing",
  },
  {
    label: "Wood Product Manufacturing",
    value: "Wood Product Manufacturing",
  },
  {
    label: "Pulp, Paper and Converted Paper Product Manufacturing",
    value: "Pulp, Paper and Converted Paper Product Manufacturing",
  },
  {
    label: "Printing (including the Reproduction of Recorded Media)",
    value: "Printing (including the Reproduction of Recorded Media)",
  },
  {
    label: "Petroleum and Coal Product Manufacturing",
    value: "Petroleum and Coal Product Manufacturing",
  },
  {
    label: "Basic Chemical and Chemical Product Manufacturing",
    value: "Basic Chemical and Chemical Product Manufacturing",
  },
  {
    label: "Polymer Product and Rubber Product Manufacturing",
    value: "Polymer Product and Rubber Product Manufacturing",
  },
  {
    label: "Non-Metallic Mineral Product Manufacturing",
    value: "Non-Metallic Mineral Product Manufacturing",
  },
  {
    label: "Primary Metal and Metal Product Manufacturing",
    value: "Primary Metal and Metal Product Manufacturing",
  },
  {
    label: "Fabricated Metal Product Manufacturing",
    value: "Fabricated Metal Product Manufacturing",
  },
  {
    label: "Transport Equipment Manufacturing",
    value: "Transport Equipment Manufacturing",
  },
  {
    label: "Machinery and Equipment Manufacturing",
    value: "Machinery and Equipment Manufacturing",
  },
  {
    label: "Furniture and Other Manufacturing",
    value: "Furniture and Other Manufacturing",
  },
  {
    label: "Electricity Supply",
    value: "Electricity Supply",
  },
  {
    label: "Gas Supply",
    value: "Gas Supply",
  },
  {
    label: "Water Supply, Sewerage and Drainage Services",
    value: "Water Supply, Sewerage and Drainage Services",
  },
  {
    label: "Waste Collection, Treatment and Disposal Services",
    value: "Waste Collection, Treatment and Disposal Services",
  },
  {
    label: "Building Construction",
    value: "Building Construction",
  },
  {
    label: "Heavy and Civil Engineering Construction",
    value: "Heavy and Civil Engineering Construction",
  },
  {
    label: "Construction Services",
    value: "Construction Services",
  },
  {
    label: "Basic Material Wholesaling",
    value: "Basic Material Wholesaling",
  },
  {
    label: "Machinery and Equipment Wholesaling",
    value: "Machinery and Equipment Wholesaling",
  },
  {
    label: "Motor Vehicle and Motor Vehicle Parts Wholesaling",
    value: "Motor Vehicle and Motor Vehicle Parts Wholesaling",
  },
  {
    label: "Grocery, Liquor and Tobacco Product Wholesaling",
    value: "Grocery, Liquor and Tobacco Product Wholesaling",
  },
  {
    label: "Other Goods Wholesaling",
    value: "Other Goods Wholesaling",
  },
  {
    label: "Commission-Based Wholesaling",
    value: "Commission-Based Wholesaling",
  },
  {
    label: "Motor Vehicle and Motor Vehicle Parts Retailing",
    value: "Motor Vehicle and Motor Vehicle Parts Retailing",
  },
  {
    label: "Fuel Retailing",
    value: "Fuel Retailing",
  },
  {
    label: "Food Retailing",
    value: "Food Retailing",
  },
  {
    label: "Other Store Based Retailing",
    value: "Other Store Based Retailing",
  },
  {
    label: "Non-Store Retailing and Retail Commission Based Buying",
    value: "Non-Store Retailing and Retail Commission Based Buying",
  },
  {
    label: "Accommodation",
    value: "Accommodation",
  },
  {
    label: "Food and Beverage Services",
    value: "Food and Beverage Services",
  },
  {
    label: "Road Transport",
    value: "Road Transport",
  },
  {
    label: "Rail Transport",
    value: "Rail Transport",
  },
  {
    label: "Water Transport",
    value: "Water Transport",
  },
  {
    label: "Air and Space Transport",
    value: "Air and Space Transport",
  },
  {
    label: "Other Transport",
    value: "Other Transport",
  },
  {
    label: "Postal and Courier Pick-up and Delivery Services",
    value: "Postal and Courier Pick-up and Delivery Services",
  },
  {
    label: "Transport Support Services",
    value: "Transport Support Services",
  },
  {
    label: "Warehousing and Storage Services",
    value: "Warehousing and Storage Services",
  },
  {
    label: "Publishing (except Internet and Music Publishing)",
    value: "Publishing (except Internet and Music Publishing)",
  },
  {
    label: "Motion Picture and Sound Recording Activities",
    value: "Motion Picture and Sound Recording Activities",
  },
  {
    label: "Broadcasting (except Internet)",
    value: "Broadcasting (except Internet)",
  },
  {
    label: "Internet Publishing and Broadcasting",
    value: "Internet Publishing and Broadcasting",
  },
  {
    label: "Telecommunications Services",
    value: "Telecommunications Services",
  },
  {
    label:
      "Internet Service Providers, Web Search Portals and Data Processing Services",
    value:
      "Internet Service Providers, Web Search Portals and Data Processing Services",
  },
  {
    label: "Library and Other Information Services",
    value: "Library and Other Information Services",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Insurance and Superannuation Funds",
    value: "Insurance and Superannuation Funds",
  },
  {
    label: "Auxiliary Finance and Insurance Services",
    value: "Auxiliary Finance and Insurance Services",
  },
  {
    label: "Rental and Hiring Services (except Real Estate)",
    value: "Rental and Hiring Services (except Real Estate)",
  },
  {
    label: "Property Operators and Real Estate Services",
    value: "Property Operators and Real Estate Services",
  },
  {
    label:
      "Professional, Scientific and Technical Services (Except Computer System Design and Related Services)",
    value:
      "Professional, Scientific and Technical Services (Except Computer System Design and Related Services)",
  },
  {
    label: "Computer System Design and Related Services",
    value: "Computer System Design and Related Services",
  },
  {
    label: "Administrative Services",
    value: "Administrative Services",
  },
  {
    label: "Building Cleaning, Pest Control and Other Support Services",
    value: "Building Cleaning, Pest Control and Other Support Services",
  },
  {
    label: "Public Administration",
    value: "Public Administration",
  },
  {
    label: "Defence",
    value: "Defence",
  },
  {
    label: "Public Order, Safety and Regulatory Services",
    value: "Public Order, Safety and Regulatory Services",
  },
  {
    label: "Preschool and School Education",
    value: "Preschool and School Education",
  },
  {
    label: "Tertiary Education",
    value: "Tertiary Education",
  },
  {
    label: "Adult, Community and Other Education",
    value: "Adult, Community and Other Education",
  },
  {
    label: "Hospitals",
    value: "Hospitals",
  },
  {
    label: "Medical and Other Health Care Services",
    value: "Medical and Other Health Care Services",
  },
  {
    label: "Residential Care Services",
    value: "Residential Care Services",
  },
  {
    label: "Social Assistance Services",
    value: "Social Assistance Services",
  },
  {
    label: "Heritage Activities",
    value: "Heritage Activities",
  },
  {
    label: "Creative and Performing Arts Activities",
    value: "Creative and Performing Arts Activities",
  },
  {
    label: "Sports and Recreation Activities",
    value: "Sports and Recreation Activities",
  },
  {
    label: "Gambling Activities",
    value: "Gambling Activities",
  },
  {
    label: "Repair and Maintenance",
    value: "Repair and Maintenance",
  },
  {
    label: "Civic Professional and Other Interest Group Services",
    value: "Civic Professional and Other Interest Group Services",
  },
  {
    label:
      "Private Households Employing Staff and Undifferentiated Goods- and Service-Producing Activities of Households for Own Use",
    value:
      "Private Households Employing Staff and Undifferentiated Goods- and Service-Producing Activities of Households for Own Use",
  },
];

export const SOURCE_OF_WEALTH_ENTITY_OPTIONS = [
  {
    label: "Savings from employment",
    value: "SAVINGS_FROM_EMPLOYMENT",
  },
  {
    label: "Business operations",
    value: "BUSINESS_OPERATIONS",
  },
  {
    label: "Superannuation",
    value: "SUPERANNUATION",
  },
  {
    label: "Investments",
    value: "INVESTMENTS",
  },
  {
    label: "Inheritance or gift",
    value: "INHERITANCE_OR_GIFT",
  },
  {
    label: "Foreign income",
    value: "FOREIGN_INCOME",
  },
  {
    label: "Sale of property or asset",
    value: "SALE_OF_PROPERTY_OR_ASSET",
  },
  {
    label: "Legal settlement",
    value: "LEGAL_SETTLEMENT",
  },
];

export const TRUST_DESCRIPTION = {
  FAMILY_TRUST: "FAMILY_TRUST",
  UNIT_TRUST: "UNIT_TRUST",
  DECEASED_ESTATE: "DECEASE_ESTATE",
  DISCRETIONARY_INVESTMENT_TRUST: "DISCRETIONARY_INVESTMENT_TRUST",
  TESTAMENTARY_TRUST: "TESTAMENTARY_TRUST",
  CHARITABLE_TRUST: "CHARITABLE_TRUST",
  DISABILITY_TRUST: "DISABILITY_TRUST",
  PROPERTY_TRUST: "PROPERTY_TRUST",
  HYBRID_TRUST: "HYBRID_TRUST",
  TRADING_TRUST: "TRADING_TRUST",
};
export const TRUST_DESCRIPTION_OPTIONS = [
  {
    label: TRUST_DESCRIPTION.FAMILY_TRUST,
  },
  {
    label: "Unit Trust",
    value: TRUST_DESCRIPTION.UNIT_TRUST,
  },
  {
    label: "Deceased Estate",
    value: TRUST_DESCRIPTION.DECEASED_ESTATE,
  },
  {
    label: "Discretionary Investment Trust",
    value: TRUST_DESCRIPTION.DISCRETIONARY_INVESTMENT_TRUST,
  },
  {
    label: "Testamentary Trust",
    value: TRUST_DESCRIPTION.TESTAMENTARY_TRUST,
  },
  {
    label: "Charitable Trust",
    value: TRUST_DESCRIPTION.CHARITABLE_TRUST,
  },
  {
    label: "Disability Trust",
    value: TRUST_DESCRIPTION.DISABILITY_TRUST,
  },
  {
    label: "Property Trust",
    value: TRUST_DESCRIPTION.PROPERTY_TRUST,
  },
  {
    label: "Hybrid Trust",
    value: TRUST_DESCRIPTION.HYBRID_TRUST,
  },
  {
    label: "Trading Trust",
    value: TRUST_DESCRIPTION.TRADING_TRUST,
  },
];

export const NATURE_OF_TRUST_ACTIVITY_OPTIONS = {
  Accommodation: [
    {
      label: "Accommodation",
      value: "Accommodation",
    },
    {
      label: "Hotel/motel",
      value: "Hotel/motel",
    },
    {
      label: "Hotel/motel with liquor and/or gambling licence",
      value: "Hotel/motel with liquor and/or gambling licence",
    },
  ],
  "Administrative Services": [
    {
      label: "Employment Placement and Recruitment Services",
      value: "Employment Placement and Recruitment Services",
    },
    {
      label: "Labour Supply Services",
      value: "Labour Supply Services",
    },
    {
      label: "Travel Agency and Tour Arrangement Services",
      value: "Travel Agency and Tour Arrangement Services",
    },
    {
      label: "Office Administrative Services",
      value: "Office Administrative Services",
    },
    {
      label: "Document Preparation Services",
      value: "Document Preparation Services",
    },
    {
      label: "Credit Reporting and Debt Collection Services",
      value: "Credit Reporting and Debt Collection Services",
    },
    {
      label: "Call Centre Operation",
      value: "Call Centre Operation",
    },
    {
      label: "Other Administrative Services n.e.c.",
      value: "Other Administrative Services n.e.c.",
    },
  ],
  "Adult, Community and Other Education": [
    {
      label: "Sports and Physical Recreation Instruction",
      value: "Sports and Physical Recreation Instruction",
    },
    {
      label: "Arts Education",
      value: "Arts Education",
    },
    {
      label: "Adult, Community and Other Education n.e.c.",
      value: "Adult, Community and Other Education n.e.c.",
    },
    {
      label: "Educational Support Services",
      value: "Educational Support Services",
    },
  ],
  Agriculture: [
    {
      label: "Nursery Production (Under Cover)",
      value: "Nursery Production (Under Cover)",
    },
    {
      label: "Nursery Production (Outdoors)",
      value: "Nursery Production (Outdoors)",
    },
    {
      label: "Turf Growing",
      value: "Turf Growing",
    },
    {
      label: "Floriculture Production (Under Cover)",
      value: "Floriculture Production (Under Cover)",
    },
    {
      label: "Floriculture Production (Outdoors)",
      value: "Floriculture Production (Outdoors)",
    },
    {
      label: "Mushroom Growing",
      value: "Mushroom Growing",
    },
    {
      label: "Vegetable Growing (Under Cover)",
      value: "Vegetable Growing (Under Cover)",
    },
    {
      label: "Vegetable Growing (Outdoors)",
      value: "Vegetable Growing (Outdoors)",
    },
    {
      label: "Grape Growing",
      value: "Grape Growing",
    },
    {
      label: "Kiwifruit Growing",
      value: "Kiwifruit Growing",
    },
    {
      label: "Berry Fruit Growing",
      value: "Berry Fruit Growing",
    },
    {
      label: "Apple and Pear Growing",
      value: "Apple and Pear Growing",
    },
    {
      label: "Stone Fruit Growing",
      value: "Stone Fruit Growing",
    },
    {
      label: "Citrus Fruit Growing",
      value: "Citrus Fruit Growing",
    },
    {
      label: "Olive Growing",
      value: "Olive Growing",
    },
    {
      label: "Other Fruit and Tree Nut Growing",
      value: "Other Fruit and Tree Nut Growing",
    },
    {
      label: "Sheep Farming (Specialised)",
      value: "Sheep Farming (Specialised)",
    },
    {
      label: "Beef Cattle Farming (Specialised)",
      value: "Beef Cattle Farming (Specialised)",
    },
    {
      label: "Beef Cattle Feedlots (Specialised)",
      value: "Beef Cattle Feedlots (Specialised)",
    },
    {
      label: "Sheep-Beef Cattle Farming",
      value: "Sheep-Beef Cattle Farming",
    },
    {
      label: "Grain-Sheep or Grain-Beef Cattle Farming",
      value: "Grain-Sheep or Grain-Beef Cattle Farming",
    },
    {
      label: "Rice Growing",
      value: "Rice Growing",
    },
    {
      label: "Other Grain Growing",
      value: "Other Grain Growing",
    },
    {
      label: "Sugar Cane Growing",
      value: "Sugar Cane Growing",
    },
    {
      label: "Cotton Growing",
      value: "Cotton Growing",
    },
    {
      label: "Drug plants growing",
      value: "Drug plants growing",
    },
    {
      label: "Pharmaceutical/cosmetic plant growing",
      value: "Pharmaceutical/cosmetic plant growing",
    },
    {
      label: "Tobacco growing",
      value: "Tobacco growing",
    },
    {
      label: "Tobacco leaf drying",
      value: "Tobacco leaf drying",
    },
    {
      label: "Tobacco seed growing",
      value: "Tobacco seed growing",
    },
    {
      label: "Other Crop Growing n.e.c.",
      value: "Other Crop Growing n.e.c.",
    },
    {
      label: "Dairy Cattle Farming",
      value: "Dairy Cattle Farming",
    },
    {
      label: "Poultry Farming (Meat)",
      value: "Poultry Farming (Meat)",
    },
    {
      label: "Poultry Farming (Eggs)",
      value: "Poultry Farming (Eggs)",
    },
    {
      label: "Deer Farming",
      value: "Deer Farming",
    },
    {
      label: "Horse Farming",
      value: "Horse Farming",
    },
    {
      label: "Pig Farming",
      value: "Pig Farming",
    },
    {
      label: "Beekeeping",
      value: "Beekeeping",
    },
    {
      label: "Other Livestock Farming n.e.c.",
      value: "Other Livestock Farming n.e.c.",
    },
  ],
  "Agriculture, Forestry and Fishing Support Services": [
    {
      label: "Forestry Support Services",
      value: "Forestry Support Services",
    },
    {
      label: "Cotton Ginning",
      value: "Cotton Ginning",
    },
    {
      label: "Shearing Services",
      value: "Shearing Services",
    },
    {
      label: "Other Agriculture and Fishing Support Services",
      value: "Other Agriculture and Fishing Support Services",
    },
  ],
  "Air and Space Transport": [
    {
      label: "Air and Space Transport",
      value: "Air and Space Transport",
    },
  ],
  Aquaculture: [
    {
      label: "Offshore Longline and Rack Aquaculture",
      value: "Offshore Longline and Rack Aquaculture",
    },
    {
      label: "Offshore Caged Aquaculture",
      value: "Offshore Caged Aquaculture",
    },
    {
      label: "Onshore Aquaculture",
      value: "Onshore Aquaculture",
    },
  ],
  "Auxiliary Finance and Insurance Services": [
    {
      label: "Financial Asset Broking Services",
      value: "Financial Asset Broking Services",
    },
    {
      label: "Digital Currency Trader",
      value: "Digital Currency Trader",
    },
    {
      label: "Other Auxiliary Finance and Investment Services",
      value: "Other Auxiliary Finance and Investment Services",
    },
    {
      label: "Money Service Business or Remittance Provider ",
      value: "Money Service Business or Remittance Provider ",
    },
    {
      label: "Auxiliary Insurance Services",
      value: "Auxiliary Insurance Services",
    },
  ],
  "Basic Chemical and Chemical Product Manufacturing": [
    {
      label: "Industrial Gas Manufacturing",
      value: "Industrial Gas Manufacturing",
    },
    {
      label: "Basic Organic Chemical Manufacturing",
      value: "Basic Organic Chemical Manufacturing",
    },
    {
      label: "Basic Inorganic Chemical Manufacturing",
      value: "Basic Inorganic Chemical Manufacturing",
    },
    {
      label: "Synthetic Resin and Synthetic Rubber Manufacturing",
      value: "Synthetic Resin and Synthetic Rubber Manufacturing",
    },
    {
      label: "Other Basic Polymer Manufacturing",
      value: "Other Basic Polymer Manufacturing",
    },
    {
      label: "Fertiliser Manufacturing",
      value: "Fertiliser Manufacturing",
    },
    {
      label: "Pesticide Manufacturing",
      value: "Pesticide Manufacturing",
    },
    {
      label: "Human Pharmaceutical and Medicinal Product Manufacturing",
      value: "Human Pharmaceutical and Medicinal Product Manufacturing",
    },
    {
      label: "Veterinary Pharmaceutical and Medicinal Product Manufacturing",
      value: "Veterinary Pharmaceutical and Medicinal Product Manufacturing",
    },
    {
      label: "Cleaning Compound Manufacturing",
      value: "Cleaning Compound Manufacturing",
    },
    {
      label: "Cosmetic and Toiletry Preparation Manufacturing",
      value: "Cosmetic and Toiletry Preparation Manufacturing",
    },
    {
      label: "Photographic Chemical Product Manufacturing",
      value: "Photographic Chemical Product Manufacturing",
    },
    {
      label: "Explosive Manufacturing",
      value: "Explosive Manufacturing",
    },
    {
      label: "Other Basic Chemical Product Manufacturing n.e.c.",
      value: "Other Basic Chemical Product Manufacturing n.e.c.",
    },
  ],
  "Basic Material Wholesaling": [
    {
      label: "Wool Wholesaling",
      value: "Wool Wholesaling",
    },
    {
      label: "Cereal Grain Wholesaling",
      value: "Cereal Grain Wholesaling",
    },
    {
      label: "Other Agricultural Product Wholesaling",
      value: "Other Agricultural Product Wholesaling",
    },
    {
      label: "Petroleum Product Wholesaling",
      value: "Petroleum Product Wholesaling",
    },
    {
      label: "Metal and Mineral Wholesaling",
      value: "Metal and Mineral Wholesaling",
    },
    {
      label: "Industrial and Agricultural Chemical Product Wholesaling",
      value: "Industrial and Agricultural Chemical Product Wholesaling",
    },
    {
      label: "Timber Wholesaling",
      value: "Timber Wholesaling",
    },
    {
      label: "Plumbing Goods Wholesaling",
      value: "Plumbing Goods Wholesaling",
    },
    {
      label: "Other Hardware Goods Wholesaling",
      value: "Other Hardware Goods Wholesaling",
    },
  ],
  "Beverage and Tobacco Product Manufacturing": [
    {
      label: "Soft Drink, Cordial and Syrup Manufacturing",
      value: "Soft Drink, Cordial and Syrup Manufacturing",
    },
    {
      label: "Beer Manufacturing",
      value: "Beer Manufacturing",
    },
    {
      label: "Spirit Manufacturing",
      value: "Spirit Manufacturing",
    },
    {
      label: "Wine and Other Alcoholic Beverage Manufacturing",
      value: "Wine and Other Alcoholic Beverage Manufacturing",
    },
    {
      label: "Cigarette and Tobacco Product Manufacturing",
      value: "Cigarette and Tobacco Product Manufacturing",
    },
  ],
  "Broadcasting (except Internet)": [
    {
      label: "Radio Broadcasting",
      value: "Radio Broadcasting",
    },
    {
      label: "Free-to-Air Television Broadcasting",
      value: "Free-to-Air Television Broadcasting",
    },
    {
      label: "Cable and Other Subscription Broadcasting",
      value: "Cable and Other Subscription Broadcasting",
    },
  ],
  "Building Cleaning, Pest Control and Other Support Services": [
    {
      label: "Building and Other Industrial Cleaning Services",
      value: "Building and Other Industrial Cleaning Services",
    },
    {
      label: "Building Pest Control Services",
      value: "Building Pest Control Services",
    },
    {
      label: "Gardening Services",
      value: "Gardening Services",
    },
    {
      label: "Packaging Services",
      value: "Packaging Services",
    },
  ],
  "Building Construction": [
    {
      label: "House Construction",
      value: "House Construction",
    },
    {
      label: "Other Residential Building Construction",
      value: "Other Residential Building Construction",
    },
    {
      label: "Non-Residential Building Construction",
      value: "Non-Residential Building Construction",
    },
  ],
  "Civic Professional and Other Interest Group Services": [
    {
      label: "Hairdressing and Beauty Services",
      value: "Hairdressing and Beauty Services",
    },
    {
      label: "Diet and Weight Reduction Centre Operation",
      value: "Diet and Weight Reduction Centre Operation",
    },
    {
      label: "Funeral, Crematorium and Cemetery Services",
      value: "Funeral, Crematorium and Cemetery Services",
    },
    {
      label: "Laundry and Dry-Cleaning Services",
      value: "Laundry and Dry-Cleaning Services",
    },
    {
      label: "Photographic Film Processing",
      value: "Photographic Film Processing",
    },
    {
      label: "Parking Services",
      value: "Parking Services",
    },
    {
      label: "Brothel Keeping and Prostitution Services",
      value: "Brothel Keeping and Prostitution Services",
    },
    {
      label: "Adult telephone message services",
      value: "Adult telephone message services",
    },
    {
      label: "Gambling tips provision on a subscription basis",
      value: "Gambling tips provision on a subscription basis",
    },
    {
      label: "Other Personal Services n.e.c.",
      value: "Other Personal Services n.e.c.",
    },
    {
      label: "Religious Services",
      value: "Religious Services",
    },
    {
      label: "Business and Professional Association Services",
      value: "Business and Professional Association Services",
    },
    {
      label: "Labour Association Services",
      value: "Labour Association Services",
    },
    {
      label: "Other Interest Group Services n.e.c.",
      value: "Other Interest Group Services n.e.c.",
    },
  ],
  "Coal Mining": [
    {
      label: "Coal Mining",
      value: "Coal Mining",
    },
  ],
  "Commission-Based Wholesaling": [
    {
      label: "Commission-Based Wholesaling",
      value: "Commission-Based Wholesaling",
    },
  ],
  "Computer System Design and Related Services": [
    {
      label: "Computer System Design and Related Services",
      value: "Computer System Design and Related Services",
    },
  ],
  "Construction Services": [
    {
      label: "Land Development and Subdivision",
      value: "Land Development and Subdivision",
    },
    {
      label: "Site Preparation Services",
      value: "Site Preparation Services",
    },
    {
      label: "Concreting Services",
      value: "Concreting Services",
    },
    {
      label: "Bricklaying Services",
      value: "Bricklaying Services",
    },
    {
      label: "Roofing Services",
      value: "Roofing Services",
    },
    {
      label: "Structural Steel Erection Services",
      value: "Structural Steel Erection Services",
    },
    {
      label: "Plumbing Services",
      value: "Plumbing Services",
    },
    {
      label: "Electrical Services",
      value: "Electrical Services",
    },
    {
      label: "Air Conditioning and Heating Services",
      value: "Air Conditioning and Heating Services",
    },
    {
      label: "Fire and Security Alarm Installation Services",
      value: "Fire and Security Alarm Installation Services",
    },
    {
      label: "Other Building Installation Services",
      value: "Other Building Installation Services",
    },
    {
      label: "Plastering and Ceiling Services",
      value: "Plastering and Ceiling Services",
    },
    {
      label: "Carpentry Services",
      value: "Carpentry Services",
    },
    {
      label: "Tiling and Carpeting Services",
      value: "Tiling and Carpeting Services",
    },
    {
      label: "Painting and Decorating Services",
      value: "Painting and Decorating Services",
    },
    {
      label: "Glazing Services",
      value: "Glazing Services",
    },
    {
      label: "Landscape Construction Services",
      value: "Landscape Construction Services",
    },
    {
      label: "Hire of Construction Machinery with Operator",
      value: "Hire of Construction Machinery with Operator",
    },
    {
      label: "Diamond drilling on a contract basis at construction sites",
      value: "Diamond drilling on a contract basis at construction sites",
    },
    {
      label: "Other Construction Services n.e.c.",
      value: "Other Construction Services n.e.c.",
    },
  ],
  "Creative and Performing Arts Activities": [
    {
      label: "Performing Arts Operation",
      value: "Performing Arts Operation",
    },
    {
      label: "Creative Artists, Musicians, Writers and Performers",
      value: "Creative Artists, Musicians, Writers and Performers",
    },
    {
      label: "Performing Arts Venue Operation",
      value: "Performing Arts Venue Operation",
    },
  ],
  Defence: [
    {
      label: "Defence",
      value: "Defence",
    },
  ],
  "Electricity Supply": [
    {
      label: "Fossil Fuel Electricity Generation",
      value: "Fossil Fuel Electricity Generation",
    },
    {
      label: "Hydro-Electricity Generation",
      value: "Hydro-Electricity Generation",
    },
    {
      label: "Other Electricity Generation",
      value: "Other Electricity Generation",
    },
    {
      label: "Electricity Transmission",
      value: "Electricity Transmission",
    },
    {
      label: "Electricity Distribution",
      value: "Electricity Distribution",
    },
    {
      label: "On Selling Electricity and Electricity Market Operation",
      value: "On Selling Electricity and Electricity Market Operation",
    },
  ],
  "Exploration and Other Mining Support Services": [
    {
      label: "Petroleum Exploration",
      value: "Petroleum Exploration",
    },
    {
      label: "Mineral Exploration",
      value: "Mineral Exploration",
    },
    {
      label: "Mining technician contracting services",
      value: "Mining technician contracting services",
    },
    {
      label: "Oil and gas field support service",
      value: "Oil and gas field support service",
    },
    {
      label: "Other Mining Support Services",
      value: "Other Mining Support Services",
    },
  ],
  "Fabricated Metal Product Manufacturing": [
    {
      label: "Iron and Steel Forging",
      value: "Iron and Steel Forging",
    },
    {
      label: "Structural Steel Fabricating",
      value: "Structural Steel Fabricating",
    },
    {
      label: "Prefabricated Metal Building Manufacturing",
      value: "Prefabricated Metal Building Manufacturing",
    },
    {
      label: "Architectural Aluminium Product Manufacturing",
      value: "Architectural Aluminium Product Manufacturing",
    },
    {
      label: "Metal Roof and Guttering Manufacturing (except Aluminium)",
      value: "Metal Roof and Guttering Manufacturing (except Aluminium)",
    },
    {
      label: "Other Structural Metal Product Manufacturing",
      value: "Other Structural Metal Product Manufacturing",
    },
    {
      label: "Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing",
      value: "Boiler, Tank and Other Heavy Gauge Metal Container Manufacturing",
    },
    {
      label: "Other Metal Container Manufacturing",
      value: "Other Metal Container Manufacturing",
    },
    {
      label:
        "Sheet Metal Product Manufacturing (except Metal Structural and Container Products)",
      value:
        "Sheet Metal Product Manufacturing (except Metal Structural and Container Products)",
    },
    {
      label: "Spring and Wire Product Manufacturing",
      value: "Spring and Wire Product Manufacturing",
    },
    {
      label: "Nut, Bolt, Screw and Rivet Manufacturing",
      value: "Nut, Bolt, Screw and Rivet Manufacturing",
    },
    {
      label: "Metal Coating and Finishing",
      value: "Metal Coating and Finishing",
    },
    {
      label: "Ammunition manufacturing",
      value: "Ammunition manufacturing",
    },
    {
      label: "Bomb manufacturing",
      value: "Bomb manufacturing",
    },
    {
      label: "Bullets manufacturing",
      value: "Bullets manufacturing",
    },
    {
      label: "Cartridge manufacturing - ammunition",
      value: "Cartridge manufacturing - ammunition",
    },
    {
      label: "Firearm manufacturing",
      value: "Firearm manufacturing",
    },
    {
      label: "Grenade manufacturing",
      value: "Grenade manufacturing",
    },
    {
      label: "Guns manufacturing",
      value: "Guns manufacturing",
    },
    {
      label: "Small-arms manufacturing",
      value: "Small-arms manufacturing",
    },
    {
      label: "Other Fabricated Metal Product Manufacturing n.e.c.",
      value: "Other Fabricated Metal Product Manufacturing n.e.c.",
    },
  ],
  Finance: [
    {
      label: "Central Banking",
      value: "Central Banking",
    },
    {
      label: "Banking",
      value: "Banking",
    },
    {
      label: "Building Society Operation",
      value: "Building Society Operation",
    },
    {
      label: "Credit Union Operation",
      value: "Credit Union Operation",
    },
    {
      label: "Other Depository Financial Intermediation",
      value: "Other Depository Financial Intermediation",
    },
    {
      label: "Non-Depository Financing",
      value: "Non-Depository Financing",
    },
    {
      label: "Financial Asset Investing",
      value: "Financial Asset Investing",
    },
  ],
  "Fishing, Hunting and Trapping": [
    {
      label: "Rock Lobster and Crab Potting",
      value: "Rock Lobster and Crab Potting",
    },
    {
      label: "Prawn Fishing",
      value: "Prawn Fishing",
    },
    {
      label: "Line Fishing",
      value: "Line Fishing",
    },
    {
      label: "Fish Trawling, Seining and Netting",
      value: "Fish Trawling, Seining and Netting",
    },
    {
      label: "Other Fishing",
      value: "Other Fishing",
    },
    {
      label: "Hunting and Trapping",
      value: "Hunting and Trapping",
    },
  ],
  "Food and Beverage Services": [
    {
      label: "Cafes and Restaurants",
      value: "Cafes and Restaurants",
    },
    {
      label: "Internet caf\u00e9",
      value: "Internet caf\u00e9",
    },
    {
      label: "Takeaway Food Services",
      value: "Takeaway Food Services",
    },
    {
      label: "Catering Services",
      value: "Catering Services",
    },
    {
      label: "Pubs, Taverns and Bars",
      value: "Pubs, Taverns and Bars",
    },
    {
      label: "Clubs (Hospitality)",
      value: "Clubs (Hospitality)",
    },
  ],
  "Food Product Manufacturing": [
    {
      label: "Meat Processing",
      value: "Meat Processing",
    },
    {
      label: "Poultry Processing",
      value: "Poultry Processing",
    },
    {
      label: "Cured Meat and Smallgoods Manufacturing",
      value: "Cured Meat and Smallgoods Manufacturing",
    },
    {
      label: "Seafood Processing",
      value: "Seafood Processing",
    },
    {
      label: "Milk and Cream Processing",
      value: "Milk and Cream Processing",
    },
    {
      label: "Ice Cream Manufacturing",
      value: "Ice Cream Manufacturing",
    },
    {
      label: "Cheese and Other Dairy Product Manufacturing",
      value: "Cheese and Other Dairy Product Manufacturing",
    },
    {
      label: "Fruit and Vegetable Processing",
      value: "Fruit and Vegetable Processing",
    },
    {
      label: "Oil and Fat Manufacturing",
      value: "Oil and Fat Manufacturing",
    },
    {
      label: "Grain Mill Product Manufacturing",
      value: "Grain Mill Product Manufacturing",
    },
    {
      label: "Cereal, Pasta and Baking Mix Manufacturing",
      value: "Cereal, Pasta and Baking Mix Manufacturing",
    },
    {
      label: "Bread Manufacturing (Factory based)",
      value: "Bread Manufacturing (Factory based)",
    },
    {
      label: "Cake and Pastry Manufacturing (Factory based)",
      value: "Cake and Pastry Manufacturing (Factory based)",
    },
    {
      label: "Biscuit Manufacturing (Factory based)",
      value: "Biscuit Manufacturing (Factory based)",
    },
    {
      label: "Bakery Product Manufacturing (Non-factory based)",
      value: "Bakery Product Manufacturing (Non-factory based)",
    },
    {
      label: "Sugar Manufacturing",
      value: "Sugar Manufacturing",
    },
    {
      label: "Confectionery Manufacturing",
      value: "Confectionery Manufacturing",
    },
    {
      label: "Potato, Corn and Other Crisp Manufacturing",
      value: "Potato, Corn and Other Crisp Manufacturing",
    },
    {
      label: "Prepared Animal and Bird Feed Manufacturing",
      value: "Prepared Animal and Bird Feed Manufacturing",
    },
    {
      label: "Other Food Product Manufacturing n.e.c.",
      value: "Other Food Product Manufacturing n.e.c.",
    },
  ],
  "Food Retailing": [
    {
      label: "Supermarket and Grocery Stores",
      value: "Supermarket and Grocery Stores",
    },
    {
      label: "Fresh Meat, Fish and Poultry Retailing",
      value: "Fresh Meat, Fish and Poultry Retailing",
    },
    {
      label: "Fruit and Vegetable Retailing",
      value: "Fruit and Vegetable Retailing",
    },
    {
      label: "Liquor Retailing",
      value: "Liquor Retailing",
    },
    {
      label: "Other Specialised Food Retailing",
      value: "Other Specialised Food Retailing",
    },
  ],
  "Forestry and Logging": [
    {
      label: "Forestry",
      value: "Forestry",
    },
    {
      label: "Logging",
      value: "Logging",
    },
  ],
  "Fuel Retailing": [
    {
      label: "Fuel Retailing",
      value: "Fuel Retailing",
    },
  ],
  "Furniture and Other Manufacturing": [
    {
      label: "Metal Furniture Manufacturing",
      value: "Metal Furniture Manufacturing",
    },
    {
      label: "Mattress Manufacturing",
      value: "Mattress Manufacturing",
    },
    {
      label: "Other Furniture Manufacturing",
      value: "Other Furniture Manufacturing",
    },
    {
      label: "Jewellery and Silverware Manufacturing",
      value: "Jewellery and Silverware Manufacturing",
    },
    {
      label: "Toy, Sporting and Recreational Product Manufacturing",
      value: "Toy, Sporting and Recreational Product Manufacturing",
    },
    {
      label: "Other Manufacturing n.e.c.",
      value: "Other Manufacturing n.e.c.",
    },
  ],
  "Gambling Activities": [
    {
      label: "Casino Operation",
      value: "Casino Operation",
    },
    {
      label: "Lottery Operation",
      value: "Lottery Operation",
    },
    {
      label: "Other Gambling Activities",
      value: "Other Gambling Activities",
    },
  ],
  "Gas Supply": [
    {
      label: "Gas Supply",
      value: "Gas Supply",
    },
  ],
  "Grocery, Liquor and Tobacco Product Wholesaling": [
    {
      label: "General Line Grocery Wholesaling",
      value: "General Line Grocery Wholesaling",
    },
    {
      label: "Meat, Poultry and Smallgoods Wholesaling",
      value: "Meat, Poultry and Smallgoods Wholesaling",
    },
    {
      label: "Dairy Produce Wholesaling",
      value: "Dairy Produce Wholesaling",
    },
    {
      label: "Fish and Seafood Wholesaling",
      value: "Fish and Seafood Wholesaling",
    },
    {
      label: "Fruit and Vegetable Wholesaling",
      value: "Fruit and Vegetable Wholesaling",
    },
    {
      label: "Liquor and Tobacco Product Wholesaling",
      value: "Liquor and Tobacco Product Wholesaling",
    },
    {
      label: "Other Grocery Wholesaling",
      value: "Other Grocery Wholesaling",
    },
  ],
  "Heavy and Civil Engineering Construction": [
    {
      label: "Road and Bridge Construction",
      value: "Road and Bridge Construction",
    },
    {
      label: "Other Heavy and Civil Engineering Construction",
      value: "Other Heavy and Civil Engineering Construction",
    },
  ],
  "Heritage Activities": [
    {
      label: "Museum Operation",
      value: "Museum Operation",
    },
    {
      label: "Zoological and Botanical Gardens Operation",
      value: "Zoological and Botanical Gardens Operation",
    },
    {
      label: "Nature Reserves and Conservation Parks Operation",
      value: "Nature Reserves and Conservation Parks Operation",
    },
  ],
  Hospitals: [
    {
      label: "Hospitals (Except Psychiatric Hospitals)",
      value: "Hospitals (Except Psychiatric Hospitals)",
    },
    {
      label: "Psychiatric Hospitals",
      value: "Psychiatric Hospitals",
    },
  ],
  "Insurance and Superannuation Funds": [
    {
      label: "Life Insurance",
      value: "Life Insurance",
    },
    {
      label: "Health Insurance",
      value: "Health Insurance",
    },
    {
      label: "General Insurance",
      value: "General Insurance",
    },
    {
      label: "Superannuation Funds",
      value: "Superannuation Funds",
    },
  ],
  "Internet Publishing and Broadcasting": [
    {
      label: "Internet Publishing and Broadcasting",
      value: "Internet Publishing and Broadcasting",
    },
  ],
  "Internet Service Providers, Web Search Portals and Data Processing Services":
    [
      {
        label: "Internet Service Providers and Web Search Portals",
        value: "Internet Service Providers and Web Search Portals",
      },
      {
        label: "Data Processing and Web Hosting Services",
        value: "Data Processing and Web Hosting Services",
      },
      {
        label: "Electronic Information Storage Services",
        value: "Electronic Information Storage Services",
      },
    ],
  "Library and Other Information Services": [
    {
      label: "Libraries and Archives",
      value: "Libraries and Archives",
    },
    {
      label: "Other Information Services",
      value: "Other Information Services",
    },
  ],
  "Machinery and Equipment Manufacturing": [
    {
      label: "Photographic, Optical and Ophthalmic Equipment Manufacturing",
      value: "Photographic, Optical and Ophthalmic Equipment Manufacturing",
    },
    {
      label: "Medical and Surgical Equipment Manufacturing",
      value: "Medical and Surgical Equipment Manufacturing",
    },
    {
      label: "Other Professional and Scientific Equipment Manufacturing",
      value: "Other Professional and Scientific Equipment Manufacturing",
    },
    {
      label: "Computer and Electronic Office Equipment Manufacturing",
      value: "Computer and Electronic Office Equipment Manufacturing",
    },
    {
      label: "Communications Equipment Manufacturing",
      value: "Communications Equipment Manufacturing",
    },
    {
      label: "Other Electronic Equipment Manufacturing",
      value: "Other Electronic Equipment Manufacturing",
    },
    {
      label: "Electric Cable and Wire Manufacturing",
      value: "Electric Cable and Wire Manufacturing",
    },
    {
      label: "Electric Lighting Equipment Manufacturing",
      value: "Electric Lighting Equipment Manufacturing",
    },
    {
      label: "Other Electrical Equipment Manufacturing",
      value: "Other Electrical Equipment Manufacturing",
    },
    {
      label: "Whiteware Appliance Manufacturing",
      value: "Whiteware Appliance Manufacturing",
    },
    {
      label: "Other Domestic Appliance Manufacturing",
      value: "Other Domestic Appliance Manufacturing",
    },
    {
      label: "Pump and Compressor Manufacturing",
      value: "Pump and Compressor Manufacturing",
    },
    {
      label:
        "Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing",
      value:
        "Fixed Space Heating, Cooling and Ventilation Equipment Manufacturing",
    },
    {
      label: "Agricultural Machinery and Equipment Manufacturing",
      value: "Agricultural Machinery and Equipment Manufacturing",
    },
    {
      label: "Mining and Construction Machinery Manufacturing",
      value: "Mining and Construction Machinery Manufacturing",
    },
    {
      label: "Machine Tool and Parts Manufacturing",
      value: "Machine Tool and Parts Manufacturing",
    },
    {
      label: "Other Specialised Machinery and Equipment Manufacturing",
      value: "Other Specialised Machinery and Equipment Manufacturing",
    },
    {
      label: "Lifting and Material Handling Equipment Manufacturing",
      value: "Lifting and Material Handling Equipment Manufacturing",
    },
    {
      label: "Other Machinery and Equipment Manufacturing n.e.c.",
      value: "Other Machinery and Equipment Manufacturing n.e.c.",
    },
    {
      label: "Wooden Furniture and Upholstered Seat Manufacturing",
      value: "Wooden Furniture and Upholstered Seat Manufacturing",
    },
  ],
  "Machinery and Equipment Wholesaling": [
    {
      label: "Agricultural and Construction Machinery Wholesaling",
      value: "Agricultural and Construction Machinery Wholesaling",
    },
    {
      label: "Other Specialised Industrial Machinery and Equipment Wholesaling",
      value: "Other Specialised Industrial Machinery and Equipment Wholesaling",
    },
    {
      label: "Professional and Scientific Goods Wholesaling",
      value: "Professional and Scientific Goods Wholesaling",
    },
    {
      label: "Computer and Computer Peripheral Wholesaling",
      value: "Computer and Computer Peripheral Wholesaling",
    },
    {
      label: "Telecommunication Goods Wholesaling",
      value: "Telecommunication Goods Wholesaling",
    },
    {
      label: "Other Electrical and Electronic Goods Wholesaling",
      value: "Other Electrical and Electronic Goods Wholesaling",
    },
    {
      label: "Other Machinery and Equipment Wholesaling n.e.c.",
      value: "Other Machinery and Equipment Wholesaling n.e.c.",
    },
  ],
  "Medical and Other Health Care Services": [
    {
      label: "General Practice Medical Services",
      value: "General Practice Medical Services",
    },
    {
      label: "Specialist Medical Services",
      value: "Specialist Medical Services",
    },
    {
      label: "Pathology and Diagnostic Imaging Services",
      value: "Pathology and Diagnostic Imaging Services",
    },
    {
      label: "Dental Services",
      value: "Dental Services",
    },
    {
      label: "Optometry and Optical Dispensing",
      value: "Optometry and Optical Dispensing",
    },
    {
      label: "Physiotherapy Services",
      value: "Physiotherapy Services",
    },
    {
      label: "Chiropractic and Osteopathic Services",
      value: "Chiropractic and Osteopathic Services",
    },
    {
      label: "Other Allied Health Services",
      value: "Other Allied Health Services",
    },
    {
      label: "Ambulance Services",
      value: "Ambulance Services",
    },
    {
      label: "Other Health Care Services n.e.c.",
      value: "Other Health Care Services n.e.c.",
    },
  ],
  "Metal Ore Mining": [
    {
      label: "Iron Ore Mining",
      value: "Iron Ore Mining",
    },
    {
      label: "Bauxite Mining",
      value: "Bauxite Mining",
    },
    {
      label: "Copper Ore Mining",
      value: "Copper Ore Mining",
    },
    {
      label: "Gold Ore Mining",
      value: "Gold Ore Mining",
    },
    {
      label: "Mineral Sand Mining",
      value: "Mineral Sand Mining",
    },
    {
      label: "Nickel Ore Mining",
      value: "Nickel Ore Mining",
    },
    {
      label: "Silver-Lead-Zinc Ore Mining",
      value: "Silver-Lead-Zinc Ore Mining",
    },
    {
      label: "Other Metal Ore Mining",
      value: "Other Metal Ore Mining",
    },
  ],
  "Motion Picture and Sound Recording Activities": [
    {
      label: "Motion Picture and Video Production",
      value: "Motion Picture and Video Production",
    },
    {
      label: "Motion Picture and Video Distribution",
      value: "Motion Picture and Video Distribution",
    },
    {
      label: "Motion Picture Exhibition",
      value: "Motion Picture Exhibition",
    },
    {
      label:
        "Post-production Services and Other Motion Picture and Video Activities",
      value:
        "Post-production Services and Other Motion Picture and Video Activities",
    },
    {
      label: "Music Publishing",
      value: "Music Publishing",
    },
    {
      label: "Music and Other Sound Recording Activities",
      value: "Music and Other Sound Recording Activities",
    },
  ],
  "Motor Vehicle and Motor Vehicle Parts Retailing": [
    {
      label: "Car Retailing",
      value: "Car Retailing",
    },
    {
      label: "Motor Cycle Retailing",
      value: "Motor Cycle Retailing",
    },
    {
      label: "Trailer and Other Motor Vehicle Retailing",
      value: "Trailer and Other Motor Vehicle Retailing",
    },
    {
      label: "Motor Vehicle Parts Retailing",
      value: "Motor Vehicle Parts Retailing",
    },
    {
      label: "Tyre Retailing",
      value: "Tyre Retailing",
    },
  ],
  "Motor Vehicle and Motor Vehicle Parts Wholesaling": [
    {
      label: "Car Wholesaling",
      value: "Car Wholesaling",
    },
    {
      label: "Commercial Vehicle Wholesaling",
      value: "Commercial Vehicle Wholesaling",
    },
    {
      label: "Trailer and Other Motor Vehicle Wholesaling",
      value: "Trailer and Other Motor Vehicle Wholesaling",
    },
    {
      label: "Motor Vehicle New Parts Wholesaling",
      value: "Motor Vehicle New Parts Wholesaling",
    },
    {
      label: "Motor Vehicle Dismantling and Used Parts Wholesaling",
      value: "Motor Vehicle Dismantling and Used Parts Wholesaling",
    },
  ],
  "Non-Metallic Mineral Mining and Quarrying": [
    {
      label: "Gravel and Sand Quarrying",
      value: "Gravel and Sand Quarrying",
    },
    {
      label: "Other Construction Material Mining",
      value: "Other Construction Material Mining",
    },
    {
      label: "Other Non-Metallic Mineral Mining and Quarrying",
      value: "Other Non-Metallic Mineral Mining and Quarrying",
    },
  ],
  "Non-Metallic Mineral Product Manufacturing": [
    {
      label: "Glass and Glass Product Manufacturing",
      value: "Glass and Glass Product Manufacturing",
    },
    {
      label: "Clay Brick Manufacturing",
      value: "Clay Brick Manufacturing",
    },
    {
      label: "Other Ceramic Product Manufacturing",
      value: "Other Ceramic Product Manufacturing",
    },
    {
      label: "Cement and Lime Manufacturing",
      value: "Cement and Lime Manufacturing",
    },
    {
      label: "Plaster Product Manufacturing",
      value: "Plaster Product Manufacturing",
    },
    {
      label: "Ready-Mixed Concrete Manufacturing",
      value: "Ready-Mixed Concrete Manufacturing",
    },
    {
      label: "Concrete Product Manufacturing",
      value: "Concrete Product Manufacturing",
    },
    {
      label: "Other Non-Metallic Mineral Product Manufacturing",
      value: "Other Non-Metallic Mineral Product Manufacturing",
    },
  ],
  "Non-Store Retailing and Retail Commission Based Buying": [
    {
      label: "Non-Store Retailing",
      value: "Non-Store Retailing",
    },
    {
      label: "Retail Commission-Based Buying and/or Selling",
      value: "Retail Commission-Based Buying and/or Selling",
    },
  ],
  "Oil and Gas Extraction": [
    {
      label: "Oil and Gas Extraction",
      value: "Oil and Gas Extraction",
    },
  ],
  "Other Goods Wholesaling": [
    {
      label: "Textile Product Wholesaling",
      value: "Textile Product Wholesaling",
    },
    {
      label: "Clothing and Footwear Wholesaling",
      value: "Clothing and Footwear Wholesaling",
    },
    {
      label: "Pharmaceutical and Toiletry Goods Wholesaling",
      value: "Pharmaceutical and Toiletry Goods Wholesaling",
    },
    {
      label: "Furniture and Floor Covering Wholesaling",
      value: "Furniture and Floor Covering Wholesaling",
    },
    {
      label: "Jewellery and Watch Wholesaling",
      value: "Jewellery and Watch Wholesaling",
    },
    {
      label: "Kitchen and Diningware Wholesaling",
      value: "Kitchen and Diningware Wholesaling",
    },
    {
      label: "Toy and Sporting Goods Wholesaling",
      value: "Toy and Sporting Goods Wholesaling",
    },
    {
      label: "Guns Wholesaling",
      value: "Guns Wholesaling",
    },
    {
      label: "Ammunition Wholesaling",
      value: "Ammunition Wholesaling",
    },
    {
      label: "Book and Magazine Wholesaling",
      value: "Book and Magazine Wholesaling",
    },
    {
      label: "Paper Product Wholesaling",
      value: "Paper Product Wholesaling",
    },
    {
      label: "Other Goods Wholesaling n.e.c.",
      value: "Other Goods Wholesaling n.e.c.",
    },
  ],
  "Other Store Based Retailing": [
    {
      label: "Furniture Retailing",
      value: "Furniture Retailing",
    },
    {
      label: "Floor Coverings Retailing",
      value: "Floor Coverings Retailing",
    },
    {
      label: "Houseware Retailing",
      value: "Houseware Retailing",
    },
    {
      label: "Manchester and Other Textile Goods Retailing",
      value: "Manchester and Other Textile Goods Retailing",
    },
    {
      label: "Electrical, Electronic and Gas Appliance Retailing",
      value: "Electrical, Electronic and Gas Appliance Retailing",
    },
    {
      label: "Computer and Computer Peripheral Retailing",
      value: "Computer and Computer Peripheral Retailing",
    },
    {
      label: "Other Electrical and Electronic Goods Retailing",
      value: "Other Electrical and Electronic Goods Retailing",
    },
    {
      label: "Hardware and Building Supplies Retailing",
      value: "Hardware and Building Supplies Retailing",
    },
    {
      label: "Garden Supplies Retailing",
      value: "Garden Supplies Retailing",
    },
    {
      label: "Sport and Camping Equipment Retailing",
      value: "Sport and Camping Equipment Retailing",
    },
    {
      label: "Guns retailing",
      value: "Guns retailing",
    },
    {
      label: "Ammunition retailing",
      value: "Ammunition retailing",
    },
    {
      label: "Entertainment Media Retailing",
      value: "Entertainment Media Retailing",
    },
    {
      label: "Toy and Game Retailing",
      value: "Toy and Game Retailing",
    },
    {
      label: "Newspaper and Book Retailing",
      value: "Newspaper and Book Retailing",
    },
    {
      label: "Marine Equipment Retailing",
      value: "Marine Equipment Retailing",
    },
    {
      label: "Clothing Retailing",
      value: "Clothing Retailing",
    },
    {
      label: "Footwear Retailing",
      value: "Footwear Retailing",
    },
    {
      label: "Watch and Jewellery Retailing",
      value: "Watch and Jewellery Retailing",
    },
    {
      label: "Other Personal Accessory Retailing",
      value: "Other Personal Accessory Retailing",
    },
    {
      label: "Department Stores",
      value: "Department Stores",
    },
    {
      label: "Pharmaceutical, Cosmetic and Toiletry Goods Retailing",
      value: "Pharmaceutical, Cosmetic and Toiletry Goods Retailing",
    },
    {
      label: "Stationery Goods Retailing",
      value: "Stationery Goods Retailing",
    },
    {
      label: "Antique and Used Goods Retailing",
      value: "Antique and Used Goods Retailing",
    },
    {
      label: "Pawnbroking",
      value: "Pawnbroking",
    },
    {
      label: "Flower Retailing",
      value: "Flower Retailing",
    },
    {
      label: "Sex shop operation",
      value: "Sex shop operation",
    },
    {
      label: "Other Store-Based Retailing n.e.c.",
      value: "Other Store-Based Retailing n.e.c.",
    },
  ],
  "Other Transport": [
    {
      label: "Scenic and Sightseeing Transport",
      value: "Scenic and Sightseeing Transport",
    },
    {
      label: "Pipeline Transport",
      value: "Pipeline Transport",
    },
    {
      label: "Other Transport n.e.c.",
      value: "Other Transport n.e.c.",
    },
  ],
  "Petroleum and Coal Product Manufacturing": [
    {
      label: "Petroleum Refining and Petroleum Fuel Manufacturing",
      value: "Petroleum Refining and Petroleum Fuel Manufacturing",
    },
    {
      label: "Other Petroleum and Coal Product Manufacturing",
      value: "Other Petroleum and Coal Product Manufacturing",
    },
  ],
  "Polymer Product and Rubber Product Manufacturing": [
    {
      label: "Polymer Film and Sheet Packaging Material Manufacturing",
      value: "Polymer Film and Sheet Packaging Material Manufacturing",
    },
    {
      label: "Rigid and Semi-Rigid Polymer Product Manufacturing",
      value: "Rigid and Semi-Rigid Polymer Product Manufacturing",
    },
    {
      label: "Polymer Foam Product Manufacturing",
      value: "Polymer Foam Product Manufacturing",
    },
    {
      label: "Tyre Manufacturing",
      value: "Tyre Manufacturing",
    },
    {
      label: "Adhesive Manufacturing",
      value: "Adhesive Manufacturing",
    },
    {
      label: "Paint and Coatings Manufacturing",
      value: "Paint and Coatings Manufacturing",
    },
    {
      label: "Other Polymer Product Manufacturing",
      value: "Other Polymer Product Manufacturing",
    },
    {
      label: "Natural Rubber Product Manufacturing",
      value: "Natural Rubber Product Manufacturing",
    },
  ],
  "Postal and Courier Pick-up and Delivery Services": [
    {
      label: "Postal Services",
      value: "Postal Services",
    },
    {
      label: "Courier Pick-up and Delivery Services",
      value: "Courier Pick-up and Delivery Services",
    },
  ],
  "Preschool and School Education": [
    {
      label: "Preschool Education",
      value: "Preschool Education",
    },
    {
      label: "Primary Education",
      value: "Primary Education",
    },
    {
      label: "Secondary Education",
      value: "Secondary Education",
    },
    {
      label: "Combined Primary and Secondary Education",
      value: "Combined Primary and Secondary Education",
    },
    {
      label: "Special School Education",
      value: "Special School Education",
    },
  ],
  "Primary Metal and Metal Product Manufacturing": [
    {
      label: "Iron Smelting and Steel Manufacturing",
      value: "Iron Smelting and Steel Manufacturing",
    },
    {
      label: "Iron and Steel Casting",
      value: "Iron and Steel Casting",
    },
    {
      label: "Steel Pipe and Tube Manufacturing",
      value: "Steel Pipe and Tube Manufacturing",
    },
    {
      label: "Alumina Production",
      value: "Alumina Production",
    },
    {
      label: "Aluminium Smelting",
      value: "Aluminium Smelting",
    },
    {
      label: "Copper, Silver, Lead and Zinc Smelting and Refining",
      value: "Copper, Silver, Lead and Zinc Smelting and Refining",
    },
    {
      label: "Other Basic Non-Ferrous Metal Manufacturing",
      value: "Other Basic Non-Ferrous Metal Manufacturing",
    },
    {
      label: "Non-Ferrous Metal Casting",
      value: "Non-Ferrous Metal Casting",
    },
    {
      label: "Aluminium Rolling, Drawing, Extruding",
      value: "Aluminium Rolling, Drawing, Extruding",
    },
    {
      label: "Other Basic Non-Ferrous Metal Product Manufacturing",
      value: "Other Basic Non-Ferrous Metal Product Manufacturing",
    },
  ],
  "Printing (including the Reproduction of Recorded Media)": [
    {
      label: "Printing",
      value: "Printing",
    },
    {
      label: "Printing Support Services",
      value: "Printing Support Services",
    },
    {
      label: "Reproduction of Recorded Media",
      value: "Reproduction of Recorded Media",
    },
  ],
  "Private Households Employing Staff and Undifferentiated Goods- and Service-Producing Activities of Households for Own Use":
    [
      {
        label: "Private Households Employing Staff",
        value: "Private Households Employing Staff",
      },
      {
        label:
          "Undifferentiated Goods-Producing Activities of Private Households for Own Use",
        value:
          "Undifferentiated Goods-Producing Activities of Private Households for Own Use",
      },
      {
        label:
          "Undifferentiated Service-Producing Activities of Private Households for Own Use",
        value:
          "Undifferentiated Service-Producing Activities of Private Households for Own Use",
      },
    ],
  "Professional, Scientific and Technical Services (Except Computer System Design and Related Services)":
    [
      {
        label: "Scientific Research Services",
        value: "Scientific Research Services",
      },
      {
        label: "Architectural Services",
        value: "Architectural Services",
      },
      {
        label: "Surveying and Mapping Services",
        value: "Surveying and Mapping Services",
      },
      {
        label: "Engineering Design and Engineering Consulting Services",
        value: "Engineering Design and Engineering Consulting Services",
      },
      {
        label: "Other Specialised Design Services",
        value: "Other Specialised Design Services",
      },
      {
        label: "Scientific Testing and Analysis Services",
        value: "Scientific Testing and Analysis Services",
      },
      {
        label: "Legal Services",
        value: "Legal Services",
      },
      {
        label: "Accounting Services",
        value: "Accounting Services",
      },
      {
        label: "Advertising Services",
        value: "Advertising Services",
      },
      {
        label: "Market Research and Statistical Services",
        value: "Market Research and Statistical Services",
      },
      {
        label: "Corporate Head Office Management Services",
        value: "Corporate Head Office Management Services",
      },
      {
        label: "Management Advice and Related Consulting Services",
        value: "Management Advice and Related Consulting Services",
      },
      {
        label: "Veterinary Services",
        value: "Veterinary Services",
      },
      {
        label: "Professional Photographic Services",
        value: "Professional Photographic Services",
      },
      {
        label: "Diamond grading service on a contract or fee basis",
        value: "Diamond grading service on a contract or fee basis",
      },
      {
        label: "Diamond sorting service on a contract or fee basis",
        value: "Diamond sorting service on a contract or fee basis",
      },
      {
        label: "Other Professional, Scientific and Technical Services n.e.c.",
        value: "Other Professional, Scientific and Technical Services n.e.c.",
      },
    ],
  "Property Operators and Real Estate Services": [
    {
      label: "Residential Property Operators",
      value: "Residential Property Operators",
    },
    {
      label: "Non-Residential Property Operators",
      value: "Non-Residential Property Operators",
    },
    {
      label: "Real Estate Services",
      value: "Real Estate Services",
    },
  ],
  "Public Administration": [
    {
      label: "Central Government Administration",
      value: "Central Government Administration",
    },
    {
      label: "State Government Administration",
      value: "State Government Administration",
    },
    {
      label: "Local Government Administration",
      value: "Local Government Administration",
    },
    {
      label: "Justice",
      value: "Justice",
    },
    {
      label: "Domestic Government Representation",
      value: "Domestic Government Representation",
    },
    {
      label: "Foreign Government Representation",
      value: "Foreign Government Representation",
    },
  ],
  "Public Order, Safety and Regulatory Services": [
    {
      label: "Police Services",
      value: "Police Services",
    },
    {
      label: "Investigation and Security Services",
      value: "Investigation and Security Services",
    },
    {
      label: "Fire Protection and Other Emergency Services",
      value: "Fire Protection and Other Emergency Services",
    },
    {
      label: "Correctional and Detention Services",
      value: "Correctional and Detention Services",
    },
    {
      label: "Other Public Order and Safety Services",
      value: "Other Public Order and Safety Services",
    },
    {
      label: "Regulatory Services",
      value: "Regulatory Services",
    },
  ],
  "Publishing (except Internet and Music Publishing)": [
    {
      label: "Newspaper Publishing",
      value: "Newspaper Publishing",
    },
    {
      label: "Magazine and Other Periodical Publishing",
      value: "Magazine and Other Periodical Publishing",
    },
    {
      label: "Book Publishing",
      value: "Book Publishing",
    },
    {
      label: "Directory and Mailing List Publishing",
      value: "Directory and Mailing List Publishing",
    },
    {
      label: "Other Publishing (except Software, Music and Internet)",
      value: "Other Publishing (except Software, Music and Internet)",
    },
    {
      label: "Software Publishing",
      value: "Software Publishing",
    },
  ],
  "Pulp, Paper and Converted Paper Product Manufacturing": [
    {
      label: "Pulp, Paper and Paperboard Manufacturing",
      value: "Pulp, Paper and Paperboard Manufacturing",
    },
    {
      label: "Corrugated Paperboard and Paperboard Container Manufacturing",
      value: "Corrugated Paperboard and Paperboard Container Manufacturing",
    },
    {
      label: "Paper Bag Manufacturing",
      value: "Paper Bag Manufacturing",
    },
    {
      label: "Paper Stationery Manufacturing",
      value: "Paper Stationery Manufacturing",
    },
    {
      label: "Sanitary Paper Product Manufacturing",
      value: "Sanitary Paper Product Manufacturing",
    },
    {
      label: "Other Converted Paper Product Manufacturing",
      value: "Other Converted Paper Product Manufacturing",
    },
  ],
  "Rail Transport": [
    {
      label: "Rail Freight Transport",
      value: "Rail Freight Transport",
    },
    {
      label: "Rail Passenger Transport",
      value: "Rail Passenger Transport",
    },
  ],
  "Rental and Hiring Services (except Real Estate)": [
    {
      label: "Passenger Car Rental and Hiring",
      value: "Passenger Car Rental and Hiring",
    },
    {
      label: "Other Motor Vehicle and Transport Equipment Rental and Hiring",
      value: "Other Motor Vehicle and Transport Equipment Rental and Hiring",
    },
    {
      label: "Farm Animal and Bloodstock Leasing",
      value: "Farm Animal and Bloodstock Leasing",
    },
    {
      label: "Heavy Machinery and Scaffolding Rental and Hiring",
      value: "Heavy Machinery and Scaffolding Rental and Hiring",
    },
    {
      label: "Video and Other Electronic Media Rental and Hiring",
      value: "Video and Other Electronic Media Rental and Hiring",
    },
    {
      label: "Other Goods and Equipment Rental and Hiring n.e.c.",
      value: "Other Goods and Equipment Rental and Hiring n.e.c.",
    },
    {
      label: "Non-Financial Intangible Assets (Except Copyrights) Leasing",
      value: "Non-Financial Intangible Assets (Except Copyrights) Leasing",
    },
  ],
  "Repair and Maintenance": [
    {
      label: "Automotive Electrical Services",
      value: "Automotive Electrical Services",
    },
    {
      label: "Automotive Body, Paint and Interior Repair",
      value: "Automotive Body, Paint and Interior Repair",
    },
    {
      label: "Other Automotive Repair and Maintenance",
      value: "Other Automotive Repair and Maintenance",
    },
    {
      label: "Domestic Appliance Repair and Maintenance",
      value: "Domestic Appliance Repair and Maintenance",
    },
    {
      label:
        "Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance",
      value:
        "Electronic (except Domestic Appliance) and Precision Equipment Repair and Maintenance",
    },
    {
      label: "Other Machinery and Equipment Repair and Maintenance",
      value: "Other Machinery and Equipment Repair and Maintenance",
    },
    {
      label: "Clothing and Footwear Repair",
      value: "Clothing and Footwear Repair",
    },
    {
      label: "Other Repair and Maintenance n.e.c.",
      value: "Other Repair and Maintenance n.e.c.",
    },
  ],
  "Residential Care Services": [
    {
      label: "Aged Care Residential Services",
      value: "Aged Care Residential Services",
    },
    {
      label: "Other Residential Care Services",
      value: "Other Residential Care Services",
    },
  ],
  "Road Transport": [
    {
      label: "Road Freight Transport",
      value: "Road Freight Transport",
    },
    {
      label: "Interurban and Rural Bus Transport",
      value: "Interurban and Rural Bus Transport",
    },
    {
      label: "Urban Bus Transport (Including Tramway)",
      value: "Urban Bus Transport (Including Tramway)",
    },
    {
      label: "Taxi and Other Road Transport",
      value: "Taxi and Other Road Transport",
    },
  ],
  "Social Assistance Services": [
    {
      label: "Child Care Services",
      value: "Child Care Services",
    },
    {
      label: "Unregistered Charities and Foundations",
      value: "Unregistered Charities and Foundations",
    },
    {
      label: "Other Social Assistance Services",
      value: "Other Social Assistance Services",
    },
  ],
  "Sports and Recreation Activities": [
    {
      label: "Health and Fitness Centres and Gymnasia Operation",
      value: "Health and Fitness Centres and Gymnasia Operation",
    },
    {
      label: "Sports and Physical Recreation Clubs and Sports Professionals",
      value: "Sports and Physical Recreation Clubs and Sports Professionals",
    },
    {
      label:
        "Sports and Physical Recreation Venues, Grounds and Facilities Operation",
      value:
        "Sports and Physical Recreation Venues, Grounds and Facilities Operation",
    },
    {
      label: "Sports and Physical Recreation Administrative Service",
      value: "Sports and Physical Recreation Administrative Service",
    },
    {
      label: "Horse and Dog Racing Administration and Track Operation",
      value: "Horse and Dog Racing Administration and Track Operation",
    },
    {
      label: "Other Horse and Dog Racing Activities",
      value: "Other Horse and Dog Racing Activities",
    },
    {
      label: "Amusement Parks and Centres Operation",
      value: "Amusement Parks and Centres Operation",
    },
    {
      label: "Amusement and Other Recreational Activities n.e.c.",
      value: "Amusement and Other Recreational Activities n.e.c.",
    },
  ],
  "Telecommunications Services": [
    {
      label: "Wired Telecommunications Network Operation",
      value: "Wired Telecommunications Network Operation",
    },
    {
      label: "Other Telecommunications Network Operation",
      value: "Other Telecommunications Network Operation",
    },
    {
      label: "Other Telecommunications Services",
      value: "Other Telecommunications Services",
    },
  ],
  "Tertiary Education": [
    {
      label: "Technical and Vocational Education and Training",
      value: "Technical and Vocational Education and Training",
    },
    {
      label: "Higher Education",
      value: "Higher Education",
    },
  ],
  "Textile, Leather, Clothing and Footwear Manufacturing": [
    {
      label: "Wool Scouring",
      value: "Wool Scouring",
    },
    {
      label: "Natural Textile Manufacturing",
      value: "Natural Textile Manufacturing",
    },
    {
      label: "Synthetic Textile Manufacturing",
      value: "Synthetic Textile Manufacturing",
    },
    {
      label: "Leather Tanning, Fur Dressing and Leather Product Manufacturing",
      value: "Leather Tanning, Fur Dressing and Leather Product Manufacturing",
    },
    {
      label: "Textile Floor Covering Manufacturing",
      value: "Textile Floor Covering Manufacturing",
    },
    {
      label: "Rope, Cordage and Twine Manufacturing",
      value: "Rope, Cordage and Twine Manufacturing",
    },
    {
      label: "Cut and Sewn Textile Product Manufacturing",
      value: "Cut and Sewn Textile Product Manufacturing",
    },
    {
      label: "Textile Finishing and Other Textile Product Manufacturing",
      value: "Textile Finishing and Other Textile Product Manufacturing",
    },
    {
      label: "Knitted Product Manufacturing",
      value: "Knitted Product Manufacturing",
    },
    {
      label: "Clothing Manufacturing",
      value: "Clothing Manufacturing",
    },
    {
      label: "Footwear Manufacturing",
      value: "Footwear Manufacturing",
    },
  ],
  "Transport Equipment Manufacturing": [
    {
      label: "Motor Vehicle Manufacturing",
      value: "Motor Vehicle Manufacturing",
    },
    {
      label: "Motor Vehicle Body and Trailer Manufacturing",
      value: "Motor Vehicle Body and Trailer Manufacturing",
    },
    {
      label: "Automotive Electrical Component Manufacturing",
      value: "Automotive Electrical Component Manufacturing",
    },
    {
      label: "Other Motor Vehicle Parts Manufacturing",
      value: "Other Motor Vehicle Parts Manufacturing",
    },
    {
      label: "Shipbuilding and Repair Services",
      value: "Shipbuilding and Repair Services",
    },
    {
      label: "Boatbuilding and Repair Services",
      value: "Boatbuilding and Repair Services",
    },
    {
      label: "Railway Rolling Stock Manufacturing and Repair Services",
      value: "Railway Rolling Stock Manufacturing and Repair Services",
    },
    {
      label: "Aircraft Manufacturing and Repair Services",
      value: "Aircraft Manufacturing and Repair Services",
    },
    {
      label: "Other Transport Equipment Manufacturing n.e.c.",
      value: "Other Transport Equipment Manufacturing n.e.c.",
    },
  ],
  "Transport Support Services": [
    {
      label: "Stevedoring Services",
      value: "Stevedoring Services",
    },
    {
      label: "Port and Water Transport Terminal Operations",
      value: "Port and Water Transport Terminal Operations",
    },
    {
      label: "Other Water Transport Support Services",
      value: "Other Water Transport Support Services",
    },
    {
      label: "Airport Operations and Other Air Transport Support Services",
      value: "Airport Operations and Other Air Transport Support Services",
    },
    {
      label: "Customs Agency Services",
      value: "Customs Agency Services",
    },
    {
      label: "Freight Forwarding Services",
      value: "Freight Forwarding Services",
    },
    {
      label: "Other Transport Support Services n.e.c.",
      value: "Other Transport Support Services n.e.c.",
    },
  ],
  "Warehousing and Storage Services": [
    {
      label: "Grain Storage Services",
      value: "Grain Storage Services",
    },
    {
      label: "Other Warehousing and Storage Services",
      value: "Other Warehousing and Storage Services",
    },
  ],
  "Waste Collection, Treatment and Disposal Services": [
    {
      label: "Solid Waste Collection Services",
      value: "Solid Waste Collection Services",
    },
    {
      label: "Other Waste Collection Services",
      value: "Other Waste Collection Services",
    },
    {
      label: "Waste Treatment and Disposal Services",
      value: "Waste Treatment and Disposal Services",
    },
    {
      label: "Waste Remediation and Materials Recovery Services",
      value: "Waste Remediation and Materials Recovery Services",
    },
  ],
  "Water Supply, Sewerage and Drainage Services": [
    {
      label: "Water Supply",
      value: "Water Supply",
    },
    {
      label: "Sewerage and Drainage Services",
      value: "Sewerage and Drainage Services",
    },
  ],
  "Water Transport": [
    {
      label: "Water Freight Transport",
      value: "Water Freight Transport",
    },
    {
      label: "Water Passenger Transport",
      value: "Water Passenger Transport",
    },
  ],
  "Wood Product Manufacturing": [
    {
      label: "Log Sawmilling",
      value: "Log Sawmilling",
    },
    {
      label: "Wood Chipping",
      value: "Wood Chipping",
    },
    {
      label: "Timber Resawing and Dressing",
      value: "Timber Resawing and Dressing",
    },
    {
      label: "Prefabricated Wooden Building Manufacturing",
      value: "Prefabricated Wooden Building Manufacturing",
    },
    {
      label: "Wooden Structural Fitting and Component Manufacturing",
      value: "Wooden Structural Fitting and Component Manufacturing",
    },
    {
      label: "Veneer and Plywood Manufacturing",
      value: "Veneer and Plywood Manufacturing",
    },
    {
      label: "Reconstituted Wood Product Manufacturing",
      value: "Reconstituted Wood Product Manufacturing",
    },
    {
      label: "Other Wood Product Manufacturing n.e.c.",
      value: "Other Wood Product Manufacturing n.e.c.",
    },
  ],
};
