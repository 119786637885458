import Yup from "@Common/customYup";
import formModels from "./formModels";
import {
  GOVERNMENT_ID_TYPE,
  CMA_SOURCE_OF_FUNDS,
  CMA_ACCOUNT_PURPOSE,
  USER_TYPE,
  OCCUPATION_TYPE,
} from "@Common/constants";
import { getEnv } from "../../../../helper/utils";

const { formField } = formModels;

const validationSchema = [
  Yup.object().shape({
    [formField.organization_code.name]: Yup.mixed().checkRequiredWithUserType(
      USER_TYPE.BROKER,
      "Organization Code is required"
    ),
    [formField.branch_code.name]: Yup.mixed().checkRequiredWithUserType(
      USER_TYPE.BROKER,
      "Branch Code is required"
    ),
    [formField.advisor_code.name]: Yup.mixed().checkRequiredWithUserType(
      USER_TYPE.BROKER,
      "Advisor Code is required"
    ),
    [formField.branch.name]: Yup.mixed().checkRequiredWithUserType(
      USER_TYPE.BROKER,
      "Vetting Rules Group is required"
    ),
    [formField.tradeable_products.name]: Yup.object().shape({
      [formField.tradeable_products.equity.originName]:
        Yup.mixed().checkRequiredWithUserType(
          USER_TYPE.BROKER,
          "Brokerage Schedule is required"
        ),
    }),
    [formField.applicant_details.name]: Yup.array().of(
      Yup.object().shape({
        [formField.applicant_details.title.originName]:
          Yup.mixed().checkRequired("Title is required"),
        [formField.applicant_details.nationality.originName]:
          Yup.mixed().checkRequired("Country of Citizenship is required"),
        [formField.applicant_details.first_name.originName]: Yup.string()
          .checkRequired("First Name is required")
          .checkLengthName("First Name"),
        [formField.applicant_details.middle_name.originName]:
          Yup.string().checkLengthName("Middle Name"),
        [formField.applicant_details.last_name.originName]: Yup.string()
          .checkRequired("Last Name is required")
          .checkLengthName("Last Name"),
        [formField.applicant_details.gender.originName]:
          Yup.mixed().checkRequired("Gender is required"),
        [formField.applicant_details.dob.originName]: Yup.mixed()
          .checkRequired("Date of Birth is required")
          .checkMinAge(18),
        [formField.applicant_details.applicant_mobile_phone.originName]:
          Yup.mixed().checkRequired("Mobile Phone is required").checkPhone(),

        [formField.applicant_details.residential_address_full_address
          .originName]: Yup.mixed().when(
          [
            formField.applicant_details.residential_address_manual_address
              .originName,
          ],
          (isManual, schema) => {
            return isManual
              ? schema
              : schema.checkRequired("Address is required");
          }
        ),
        [formField.applicant_details.government_id.name]: Yup.object().shape({
          [formField.applicant_details.government_id.type.originName]:
            Yup.mixed().checkRequired("ID Type is required"),
          [formField.applicant_details.government_id.number.originName]:
            Yup.string().when(
              [formField.applicant_details.government_id.type.originName],
              (type, schema) => {
                if (type) {
                  if (type.value === GOVERNMENT_ID_TYPE.PASSPORT) {
                    return schema.checkPassport();
                  } else if (type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE) {
                    return schema.checkDriverLicense();
                  }
                  return schema.checkMedicareCard();
                } else {
                  return schema;
                }
              }
            ),

          [formField.applicant_details.government_id.state_of_issue.originName]:
            Yup.mixed().when(
              [formField.applicant_details.government_id.type.originName],
              (type, schema) => {
                return type && type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
                  ? schema.checkRequired("State of Issue is required")
                  : schema;
              }
            ),
          [formField.applicant_details.government_id.card_number.originName]:
            Yup.string().when(
              [
                formField.applicant_details.government_id.type.originName,
                formField.applicant_details.government_id.state_of_issue
                  .originName,
              ],
              (type, stateOfIssue, schema) => {
                return type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
                  ? schema
                      .checkRequired("Driver License Card No. is required")
                      .checkDriverCardNumber(undefined, stateOfIssue)
                  : schema;
              }
            ),
          [formField.applicant_details.government_id.medicare_card_colour
            .originName]: Yup.mixed().when(
            [formField.applicant_details.government_id.type.originName],
            (type, schema) => {
              return type && type.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
                ? schema.checkRequired("Card Color is required")
                : schema;
            }
          ),
          [formField.applicant_details.government_id.medicare_card_expiry_date
            .originName]: Yup.string().when(
            [formField.applicant_details.government_id.type.originName],
            (type, schema) => {
              return type && type.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
                ? schema.checkExpireDate()
                : schema;
            }
          ),
        }),
        [formField.applicant_details.residential_address_unit_flat_number
          .originName]: Yup.mixed().when(
          formField.applicant_details.residential_address_manual_address
            .originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkLanguageField(
                    formField.applicant_details
                      .residential_address_unit_flat_number.label
                  )
                  .checkMaxLength(80, "Unit Flat Number")
              : schema;
          }
        ),
        [formField.applicant_details.residential_address_street_number
          .originName]: Yup.mixed().when(
          formField.applicant_details.residential_address_manual_address
            .originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkRequired("Street Number is required")
                  .checkLanguageField(
                    formField.applicant_details
                      .residential_address_street_number.label
                  )
                  .checkMaxLength(80, "Street Number")
              : schema;
          }
        ),
        [formField.applicant_details.residential_address_street_name
          .originName]: Yup.mixed().when(
          formField.applicant_details.residential_address_manual_address
            .originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkRequired("Street Name is required")
                  .checkLanguageField(
                    formField.applicant_details.residential_address_street_name
                      .label
                  )
                  .checkMaxLength(80, "Street Name")
              : schema;
          }
        ),
        [formField.applicant_details.residential_address_street_type
          .originName]: Yup.mixed().when(
          formField.applicant_details.residential_address_manual_address
            .originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema.checkRequired("Street Type is required")
              : schema;
          }
        ),
        [formField.applicant_details.residential_address_city_suburb
          .originName]: Yup.mixed().when(
          formField.applicant_details.residential_address_manual_address
            .originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkRequired("Suburb is required")
                  .checkLanguageField(
                    formField.applicant_details.residential_address_city_suburb
                      .label
                  )
                  .checkMaxLength(80, "Suburb")
              : schema;
          }
        ),
        [formField.applicant_details.residential_address_state.originName]:
          Yup.mixed().when(
            formField.applicant_details.residential_address_manual_address
              .originName,
            (manualAddress, schema) => {
              return manualAddress
                ? schema.checkRequired("State is required")
                : schema;
            }
          ),
        [formField.applicant_details.residential_address_postcode.originName]:
          Yup.mixed().when(
            formField.applicant_details.residential_address_manual_address
              .originName,
            (manualAddress, schema) => {
              return manualAddress
                ? schema
                    .checkRequired("Postcode is required")
                    .checkNumber("Postcode must be number")
                    .checkEqualLength(
                      4,
                      "Postcode",
                      "Postcode must be 4 digits"
                    )
                : schema;
            }
          ),
        [formField.applicant_details.australian_tax_resident.originName]:
          Yup.mixed().checkRequired("Australian Tax Resident is required"),
        [formField.applicant_details.tfn.originName]: Yup.string().when(
          formField.applicant_details.australian_tax_resident.originName,
          (tax, schema) => {
            return tax ? schema.checkTfn() : schema; // eslint-disable-line
          }
        ),
        [formField.applicant_details.tax_exemption_details.originName]:
          Yup.mixed().when(
            [
              formField.applicant_details.australian_tax_resident.originName,
              formField.applicant_details.tax_exemption.originName,
            ],
            (tax, exemption, schema) => {
              return tax && exemption
                ? schema.checkRequired("Tax Exemption Details is required")
                : schema;
            }
          ),
        [formField.applicant_details.occupation_type.originName]:
          Yup.mixed().checkRequired("Occupation Type is required"),
        [formField.applicant_details.business_owner_trading_name.originName]:
          Yup.mixed().when(
            formField.applicant_details.occupation_type.originName,
            (occupationType, schema) => {
              return occupationType?.value ===
                OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                ? schema.checkRequired(
                    "Trading Name of The Business You Own is required"
                  )
                : schema;
            }
          ),

        [formField.applicant_details.own_cafe_restaurant.originName]:
          Yup.mixed().when(
            [
              formField.applicant_details.occupation_type.originName,
              formField.applicant_details.occupation_category.originName,
            ],
            (occupationType, occupationCate, schema) => {
              const checkOccupationCate =
                occupationCate?.value === "Cafe and Restaurant";
              const checkOccupationType =
                occupationType?.value ===
                OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER;

              return checkOccupationCate && checkOccupationType
                ? schema.checkRequired(
                    "Do you own a Café or a Restaurant is required"
                  )
                : schema;
            }
          ),

        [formField.applicant_details.business_owner_trading_address_full_address
          .originName]: Yup.mixed().when(
          [
            formField.applicant_details.occupation_type.originName,
            formField.applicant_details
              .business_owner_trading_address_manual_address.originName,
          ],
          (occupationType, manualAddress, schema) => {
            return occupationType?.value ===
              OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER && !manualAddress
              ? schema.checkRequired("Business Address is required")
              : schema;
          }
        ),
        [formField.applicant_details
          .business_owner_trading_address_unit_flat_number.originName]:
          Yup.mixed().when(
            formField.applicant_details
              .business_owner_trading_address_manual_address.originName,
            (manualAddress, schema) => {
              return manualAddress
                ? schema
                    .checkLanguageField(
                      formField.applicant_details
                        .business_owner_trading_address_unit_flat_number.label
                    )
                    .checkMaxLength(5, "Unit Flat Number")
                : schema;
            }
          ),
        [formField.applicant_details
          .business_owner_trading_address_street_number.originName]:
          Yup.mixed().when(
            formField.applicant_details
              .business_owner_trading_address_manual_address.originName,
            (manualAddress, schema) => {
              return manualAddress
                ? schema
                    .checkRequired("Street Number is required")
                    .checkLanguageField(
                      formField.applicant_details
                        .business_owner_trading_address_street_number.label
                    )
                    .checkMaxLength(8, "Street Number")
                : schema;
            }
          ),
        [formField.applicant_details.business_owner_trading_address_street_name
          .originName]: Yup.mixed().when(
          formField.applicant_details
            .business_owner_trading_address_manual_address.originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkRequired("Street Name is required")
                  .checkLanguageField(
                    formField.applicant_details
                      .business_owner_trading_address_street_name.label
                  )
                  .checkMaxLength(25, "Street Name")
              : schema;
          }
        ),
        [formField.applicant_details.business_owner_trading_address_street_type
          .originName]: Yup.mixed().when(
          formField.applicant_details
            .business_owner_trading_address_manual_address.originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema.checkRequired("Street Type is required")
              : schema;
          }
        ),
        [formField.applicant_details.business_owner_trading_address_city_suburb
          .originName]: Yup.mixed().when(
          formField.applicant_details
            .business_owner_trading_address_manual_address.originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkRequired("Suburb is required")
                  .checkLanguageField(
                    formField.applicant_details
                      .business_owner_trading_address_city_suburb.label
                  )
                  .checkMaxLength(22, "Suburb")
              : schema;
          }
        ),
        [formField.applicant_details.business_owner_trading_address_state
          .originName]: Yup.mixed().when(
          formField.applicant_details
            .business_owner_trading_address_manual_address.originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema.checkRequired("State is required")
              : schema;
          }
        ),
        [formField.applicant_details.business_owner_trading_address_postcode
          .originName]: Yup.mixed().when(
          formField.applicant_details
            .business_owner_trading_address_manual_address.originName,
          (manualAddress, schema) => {
            return manualAddress
              ? schema
                  .checkRequired("Postcode is required")
                  .checkNumber("Postcode must be number")
                  .checkEqualLength(4, "Postcode", "Postcode must be 4 digits")
              : schema;
          }
        ),
        [formField.applicant_details.abn_acn_registration_number.originName]:
          Yup.mixed().when(
            formField.applicant_details.occupation_type.originName,
            (occupationType, schema) => {
              return occupationType?.value ===
                OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                ? schema.checkMaxLength(
                    11,
                    "ABN/ACN/Registration Number of The Business"
                  )
                : schema;
            }
          ),
        [formField.applicant_details.occupation_category.originName]:
          Yup.mixed().checkRequiredOccupationCategory(
            "Occupation Category is required"
          ),
        [formField.applicant_details.source_of_wealth.originName]:
          Yup.mixed().checkRequired("Source of Wealth is required"),
        [formField.applicant_details.applicant_email.originName]:
          Yup.string().checkApplicantEmail(),
      })
    ),
  }),
  Yup.object().shape({
    [formField.cma_source_of_funds.name]: Yup.mixed().when(
      "use_existing_CMT_acc",
      (iUseExist, schema) => {
        return iUseExist
          ? schema
          : schema.checkRequired("Source of Funds is required");
      }
    ),
    [formField.cma_source_of_funds_desc.name]: Yup.string().when(
      ["use_existing_CMT_acc", formField.cma_source_of_funds.name],
      (iUseExist, sourceOfFund, schema) => {
        return !iUseExist &&
          sourceOfFund?.value === CMA_SOURCE_OF_FUNDS[getEnv()].OTHER
          ? schema.checkRequired("Source of Funds Description is required")
          : schema;
      }
    ),
    [formField.cma_account_purpose.name]: Yup.mixed().when(
      "use_existing_CMT_acc",
      (iUseExist, schema) => {
        return iUseExist
          ? schema
          : schema.checkRequired("Account Purpose is required");
      }
    ),
    [formField.cma_account_purpose_desc.name]: Yup.string().when(
      ["use_existing_CMT_acc", formField.cma_account_purpose.name],
      (iUseExist, accountPurpose, schema) => {
        return !iUseExist && accountPurpose?.value === CMA_ACCOUNT_PURPOSE.OTHER
          ? schema.checkRequired("Account Purpose Description is required")
          : schema;
      }
    ),
    [formField.bank_bsb.name]: Yup.mixed().when(
      ["use_existing_CMT_acc"],
      (cma, schema) => {
        if ([null, undefined].includes(cma)) return schema;
        return cma === true ? schema.checkRequired("BSB is required") : schema;
      }
    ),
    [formField.bank_account_number.name]: Yup.string().when(
      ["use_existing_CMT_acc"],
      (cma, schema) => {
        if ([null, undefined].includes(cma)) return schema;
        return cma === true ? schema.checkMaxLength(20, "BSB") : schema;
      }
    ),
    [formField.settlement_method.name]: Yup.mixed().checkRequired(
      "Settlement Method is required"
    ),
    [formField.settlement_existing_hin.name]: Yup.string().when(
      [formField.settlement_method.name],
      (settlementMethod, schema) => {
        if ([null, undefined].includes(settlementMethod)) return schema;
        return !settlementMethod
          ? schema
              .checkRequired("Existing HIN is required")
              .checkNumber("Existing HIN must be number")
              .checkMaxLength(10, "Existing HIN")
          : schema;
      }
    ),
    [formField.settlement_pid.name]: Yup.mixed().when(
      [formField.settlement_method.name],
      (settlementMethod, schema) => {
        if ([null, undefined].includes(settlementMethod)) return schema;
        return !settlementMethod
          ? schema.checkRequired("Current Broker is required")
          : schema;
      }
    ),
  }),
  Yup.object().shape({}),
];

export default validationSchema;
