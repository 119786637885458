import React, { useState, useEffect } from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

export default function DatePickerField(props) {
  const [field, meta, helper] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helper;
  const isError = touched && error && true;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      setSelectedDate(date);
    } else {
      setSelectedDate(null);
    }
  }, [value]);

  function _onChange(date) {
    if (date == null) return setValue("");
    if (date) {
      setSelectedDate(date);
      try {
        setValue(date, true);
      } catch (error) {
        setValue(date, true);
      }
    } else {
      setValue(date);
    }
  }

  // const _onClose = () => {
  //   setTouched(field.name, true)
  // }

  const renderPropsInput = () => {
    const { inputFormat, minDate, maxDate, ...propsInput } = props; // eslint-disable-line
    return propsInput;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        {...field}
        {...props}
        autoOk={true}
        // disableToolbar
        defaultCalendarMonth={props.maxDate || props.minDate}
        variant="inline"
        inputVariant="outlined"
        value={selectedDate}
        onChange={_onChange}
        // onClose={_onClose}
        renderInput={(params) => (
          <TextField
            // {...field}
            name={field.name}
            value={field.value}
            onBlur={field.onBlur}
            {...renderPropsInput()}
            {...params}
            error={isError}
            helperText={isError && error}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  );
}
