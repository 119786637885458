import React from "react";
import {
  Box,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import dataStorage from "@s/dataStorage";
import { getWhiteLabel, isBroker } from "@s/helper/utils";
import DownloadApp from "@Components/DownloadApp";
import ImageReview from "@Components/ImageReview";

function ApplicationSuccess() {
  const { equix_id: equixId } = dataStorage.applicantInfo;
  const listApplicantOther = () => {
    const saveApplicantInfo =
      dataStorage?.applicantInfo?.applicant_details?.slice(1);
    return saveApplicantInfo
      ? saveApplicantInfo.map((currenApp) => currenApp?.first_name).join(", ")
      : null;
  };
  const sendEmail = () => {
    if (isBroker()) {
      return (
        <>
          <Typography
            component="h1"
            variant="h5"
            align="left"
            style={{ fontWeight: "bold" }}
          >
            Your Joint Account application is being processed...
          </Typography>
          <Typography component="h1" variant="body1" align="left">
            #{equixId || dataStorage.equixId}
          </Typography>
          <Typography variant="subtitle1" align="left">
            <br />
            <br />
            We have received and are processing your information. You can access
            the {getWhiteLabel()} App now while we are creating your trading
            account.
            <br />
            <br />
            For any questions, reach out to us at&nbsp;
            <Link
              className="emailLink"
              href={`mailto:${dataStorage.config.supportEmail}`}
            >
              {`${dataStorage.config.supportEmail}`}
            </Link>{" "}
            anytime!
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          component="h1"
          variant="h5"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          Your Joint Account application is being processed...
        </Typography>
        <Typography component="h1" variant="body1" align="left">
          #{equixId || dataStorage.equixId}
        </Typography>
        <Typography variant="subtitle1" align="left">
          <br />
          <br />
          We have received and are processing your information. You can access
          the {getWhiteLabel()} App now while we are creating your trading
          account.
          <br />
          <br />
          We&apos;ll send other applicants ({listApplicantOther()}) an email to
          review this application and confirm their details. If you&apos;re in a
          hurry, let them know that the email is on its way. Once we&apos;ve
          verified their details, we&apos;ll begin processing your application.
          <br />
          <br />
          For any questions, reach out to us at&nbsp;
          <Link
            className="emailLink"
            href={`mailto:${dataStorage.config.supportEmail}`}
          >
            {`${dataStorage.config.supportEmail}`}
          </Link>{" "}
          anytime!
        </Typography>
      </>
    );
  };
  const confirmApplicantOther = () => {
    return (
      <>
        <Typography component="h1" variant="h5" align="left">
          <Box fontWeight="fontWeightBold">
            Your Joint Account application #{equixId || dataStorage.equixId} is
            being processed...
          </Box>
        </Typography>
        <Typography variant="subtitle1" align="left">
          <br />
          <br />
          Thank you for submitting your information. We’ll begin processing your
          application when all applicants have finalized their details.
          <br />
          <br />
          For any questions, reach out to us at&nbsp;
          <Link
            className="emailLink"
            href={`mailto:${dataStorage.config.supportEmail}`}
          >
            {`${dataStorage.config.supportEmail}`}
          </Link>{" "}
          anytime!
        </Typography>
      </>
    );
  };
  const AllApplicationsSubmitted = () => {
    return (
      <>
        <Typography
          component="h1"
          variant="h5"
          align="left"
          style={{ fontWeight: "bold" }}
        >
          Your Joint Account application is being processed...
        </Typography>
        <Typography component="h1" variant="body1" align="left">
          #{equixId || dataStorage.equixId}
        </Typography>
        <Typography variant="subtitle1" align="left">
          <br />
          We have received and are processing. You can access the{" "}
          {getWhiteLabel()} App now while we are creating your trading account.
          <br />
          <br />
          Please contact the Primary Applicant to get login credentials to
          download and use our state-of-the-art HTML5 and mobile apps.
          <br />
          <br />
          <DownloadApp />
          For any questions, reach out to us at&nbsp;
          <Link
            className="emailLink"
            href={`mailto:${dataStorage.config.supportEmail}`}
          >
            {`${dataStorage.config.supportEmail}`}
          </Link>{" "}
          anytime!
          <br />
          <br />
        </Typography>
      </>
    );
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <ImageReview />
        <Grid item xs={12} sm={8}>
          {dataStorage?.all_application_submitted
            ? AllApplicationsSubmitted()
            : dataStorage.isSubApplicant
            ? confirmApplicantOther()
            : sendEmail()}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApplicationSuccess;
