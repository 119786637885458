import React from "react";
import { Box, Grid, Typography, Link } from "@mui/material";
import ImageReview from "@Components/ImageReview";
import dataStorage from "@s/dataStorage";

function ApplicationDeleted() {
  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <ImageReview />
        <Grid item xs={12} sm={8}>
          <Typography component="h1" variant="h5" align="left">
            <Box fontWeight="fontWeightBold">Application Deleted</Box>
          </Typography>
          <Typography variant="subtitle1" align="left">
            <br />
            <br />
            Your application has been withdrawn. If you think this was a
            mistake, please contact&nbsp;
            <Link
              className="emailLink"
              href={`mailto:${dataStorage.config.supportEmail}`}
            >
              {`${dataStorage.config.supportEmail}`}
            </Link>{" "}
            for support.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default ApplicationDeleted;
