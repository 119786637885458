import React, { useState, useRef } from "react";
import { Grid, TextField, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getOpeningAccountUrl } from "@s/api/index";
import { postData } from "@s/api/request";
import dataStorage from "../dataStorage";
import logger from "@s/helper/logger";

const useStyle = makeStyles({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  buttonResend: {
    position: "absolute",
    right: 10,
    textTransform: "capitalize",
    padding: " 0.3em 0.6em",
    marginBottom: 3,
  },
});
export default function ResendEmail({
  formField,
  formValues,
  index
}) {
  const classes = useStyle();
  const [countTime, setcountTime] = useState();
  const intervalTime = useRef(null);
  // eslint-disable-next-line quotes
  const textSendEmail = !countTime ? "Resend" : `Resend  ${countTime}`;
  React.useEffect(() => {
    return () => {
      clearInterval(intervalTime.current);
    };
  }, []);

  React.useEffect(() => {
    countTime <= 0 && clearInterval(intervalTime.current);
  }, [countTime]);

  const startTime = () => {
    setcountTime(15);
    intervalTime.current && clearInterval(intervalTime.current);
    intervalTime.current = setInterval(() => {
      setcountTime((currenTime) => currenTime - 1);
    }, 1000);
  };

  const resendEmail = async () => {
    const email = formValues.applicant_details[index].applicant_email;
    const applicantId = formValues.applicant_details[index].applicant_id;
    if (email && dataStorage.equixId) {
      startTime();
      const url = getOpeningAccountUrl("/joint/resend-email");
      const query = {
        email,
        equix_id: dataStorage.equixId,
        applicant_id: applicantId,
      };
      try {
        await postData(url, query);
      } catch (error) {
        logger.error(error, "resendEmail");
      }
    }
  };
  return (
    <Grid item xs={12} sm={12} className={classes.container}>
      <TextField
        inputProps={{ readOnly: true }}
        variant="standard"
        label={formField.applicant_details.applicant_email.label}
        value={formValues.applicant_details[index].applicant_email}
        fullWidth
      />
      <Button
        variant="outlined"
        className={classes.buttonResend}
        onClick={resendEmail}
        disabled={Boolean(countTime)}
      >
        {textSendEmail}
      </Button>
    </Grid>
  );
}
