import * as React from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import dataStorage from "@s/dataStorage";
import { ACCOUNT_STATUS } from "@Common/constants";
import { getOpeningAccountUrl } from "@s/api/index";
import { deleteData } from "@s/api/request";
import { useFormikContext } from "formik";
import { VALIDATE_ENUM } from "../common/customYup";
import logger from "@s/helper/logger";

const listCanDelete = [
  ACCOUNT_STATUS.REJECTED,
  ACCOUNT_STATUS.IN_KYC,
  ACCOUNT_STATUS.PENDING_KYC_APPROVAL,
  ACCOUNT_STATUS.PENDING_APPLICATION_SUBMIT,
  ACCOUNT_STATUS.PENDING_BANK_ACCOUNT_INFO,
  ACCOUNT_STATUS.PENDING_BANK_TPA,
];

export default function DeleteApplicationButton(props) {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const email = React.useRef("");
  const { values } = useFormikContext();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onConfirm = () => {
    if (!values.applicant_details) return;
    const primaryEmail = values?.applicant_details?.[0]?.applicant_email;
    const check = email.current === primaryEmail;
    if (check) {
      const url = getOpeningAccountUrl(`/${props.type}/${dataStorage.equixId}`);
      deleteData(url)
        .then(() => {
          setOpen(false);
          props.onDelete && props.onDelete();
        })
        .catch((error) => {
          logger.error(error, "Delete application");
          dataStorage.showError && dataStorage.showError(error);
        });
    } else {
      dataStorage.showError &&
        dataStorage.showError("Email address incorrect. Please try again!");
    }
  };

  if (
    !dataStorage.equixId ||
    dataStorage.isSubApplicant ||
    !listCanDelete.includes(dataStorage.accountStatus)
  ) {
    return <React.Fragment />;
  }

  const onChangeEmail = (e) => {
    email.current = e.target?.value;
  };

  const onBlur = () => {
    if ([null, undefined, ""].includes(email.current)) {
      setError("Email is required");
      return;
    } else {
      if (VALIDATE_ENUM.EMAIL.REGEX.test(email.current)) {
        if (email.current.length > 80) {
          setError("Email address is maximum 80 characters");
          return;
        }
      } else {
        setError(VALIDATE_ENUM.EMAIL.ERROR);
        return;
      }
    }
    if (error) setError("");
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}
    >
      <Button onClick={handleClickOpen} variant="contained">
        Delete Application
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Are you sure you want to close the application?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To delete application, please confirm your email address here.
          </DialogContentText>
          <TextField
            required
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            error={!!error}
            helperText={error}
            onBlur={onBlur}
            fullWidth
            variant="outlined"
            onChange={onChangeEmail}
          />
        </DialogContent>
        <DialogActions style={{ paddingRight: 24 }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            disabled={!!error}
            variant="contained"
            color="primary"
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
