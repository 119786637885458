import React from "react";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useField } from "formik";
import InputBase from "@mui/material/InputBase";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import at from "lodash/at";
import dataStorage from "@s/dataStorage";
import moment from "moment";
import { getFileUploaded } from "@s/helper/utils";
import HelpIcon from "../HelpIcon";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 2,
    borderRadius: 20,
    overflow: "hidden",
    borderStyle: "solid",
    boxSizing: "border-box",
    height: 48,
  },
  inputFileHidden: {
    position: "absolute",
    display: "none",
  },
  btnChooseFile: {
    background: "#156AB8",
    height: "100%",
    borderRadius: 0,
    borderRight: "2px solid #258EEF",
    fontWeight: theme.typography.fontWeightBold,
    color: "#ffffff !important",
    textTransform: "uppercase",
    pointerEvents: "none",
  },
  btnReview: {
    height: "100%",
    borderRadius: 0,
    borderLeft: "2px solid #258EEF",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 14,
    color: "#ffffff !important",
    textTransform: "capitalize",
    padding: theme.spacing(0, 1),
  },
  inputContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    alignItems: "center",
  },
  errorText: {
    color: theme.palette.error.main,
  },
  removeIcon: {
    fill: theme.palette.grey[400],
  },
  fileDisplay: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: theme.spacing(0, 1),
    pointerEvents: "none",
    overflow: "hidden",
    "& > *": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  },
}));

function ChooseFile(props) {
  const classes = useStyles();
  const {
    review,
    required,
    fileStorage = "trust_deed",
    helperText = "PDF, DOCX, JPEG (10 MB)",
    accept = ".pdf, .doc, .docx, .jpeg, .jpg",
    size = 10000000, // bytes
    name,
    label,
    index,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  const refInput = React.useRef();

  const onChangeFile = (e) => {
    helpers.setTouched(true);
    const file = e?.target?.files?.[0];
    if (!file) return;
    const { size: fileSize, name: fileName } = file;
    const extension = (fileName + "").match(/([^.]*)$/)?.[0]?.toLowerCase();
    if (
      fileSize > size ||
      !accept.includes(extension) ||
      fileName.length > 200
    ) {
      helpers.setValue(
        {
          name: fileName,
          size: fileSize,
          extension,
        },
        true
      );
      return;
    }
    if (!dataStorage[fileStorage]) dataStorage[fileStorage] = [];
    dataStorage[fileStorage][index] = file;
    if (field?.value?.id) {
      if (!dataStorage[`${fileStorage}_delete`])
        dataStorage[`${fileStorage}_delete`] = [];
      dataStorage[`${fileStorage}_delete`][index] = field.value.id;
    }
    helpers.setValue(
      {
        name: fileName,
        upload_time: Date.now() + "",
        extension,
      },
      true
    );
  };

  const onRemove = (e) => {
    e.preventDefault();
    if (dataStorage?.[fileStorage]?.[index]) {
      dataStorage[fileStorage].splice(index, 1);
    }
    refInput.current.value = null;
    helpers.setValue("");
  };

  const onReview = (e) => {
    e.preventDefault();
    const file = dataStorage?.[fileStorage]?.[index];
    if (file) {
      const url = URL.createObjectURL(file);
      if (url) {
        window.open(url, "_blank").focus();
        URL.revokeObjectURL(url);
      }
      return;
    }
    if (!file && field?.value?.id) {
      getFileUploaded(field.value.id, (file) => {
        if (!dataStorage[fileStorage]) dataStorage[fileStorage] = [];
        dataStorage[fileStorage][index] = file;
        const url = URL.createObjectURL(file);
        if (url) {
          window.open(url, "_blank").focus();
          URL.revokeObjectURL(url);
        }
      });
    }
  };

  function _renderHelperText() {
    const showError =
      isError && !(error + "").toLowerCase().includes("required");
    if (review) return <React.Fragment />;
    if (showError) {
      return (
        <FormHelperText sx={{ pl: 1.5 }} className={classes.errorText}>
          {error}
        </FormHelperText>
      );
    } else {
      return <FormHelperText sx={{ pl: 1 }}>{helperText}</FormHelperText>;
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
      <div style={{ width: "initial", maxWidth: "100%" }}>
        {review ? <InputLabel shrink>{label}</InputLabel> : <React.Fragment />}
        <label
          htmlFor={`input_file_for_${name}`}
          className={classes.container}
          style={{
            borderColor: isError ? "#E93535" : "#258EEF",
            cursor: review ? "initial" : "pointer",
            width: "fit-content",
            maxWidth: "100%",
          }}
        >
          <InputBase
            type="file"
            inputRef={refInput}
            id={`input_file_for_${name}`}
            inputProps={{
              accept: accept,
            }}
            disabled={review}
            onChange={onChangeFile}
            className={classes.inputFileHidden}
          />
          <FormControl
            {...rest}
            className={classes.inputContainer}
            error={isError}
          >
            {field?.value?.name ? (
              <React.Fragment>
                <Button className={classes.btnChooseFile}>
                  {field?.value?.extension}
                </Button>
                <div className={classes.fileDisplay}>
                  <Typography
                    title={field?.value?.name}
                    variant="subtitle2"
                    style={{ fontWeight: "bold" }}
                    sx={{ mb: -0.25 }}
                  >
                    {field?.value?.name}
                  </Typography>
                  <Typography
                    variant="caption"
                    style={{ fontSize: 12 }}
                    sx={{ mt: -0.25 }}
                  >{`Uploaded on ${moment(+field?.value?.upload_time).format(
                    "DD/MM/YYYY"
                  )}`}</Typography>
                </div>
                {review ? (
                  <Button onClick={onReview} className={classes.btnReview}>
                    Review
                  </Button>
                ) : (
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onRemove}
                  >
                    <CloseIcon style={{ width: 24, height: 24 }} />
                  </IconButton>
                )}
              </React.Fragment>
            ) : (
              <Typography
                sx={{ px: 1 }}
                style={{ color: isError ? "#E93535" : "#258EEF" }}
                variant="body1"
              >{`Upload ${label} ${required ? "*" : ""}`}</Typography>
            )}
          </FormControl>
        </label>
        {_renderHelperText()}
      </div>
      {review ? (
        <React.Fragment />
      ) : (
        <HelpIcon
          style={{
            width: 32,
            height: 32,
          }}
          iconName="Info"
          tooltip={`Please upload a copy of your ${(
            label + ""
          ).toLowerCase()}, including the cover page, schedule and signing pages in 1 file`}
          placement="right"
        />
      )}
    </div>
  );
}

export default ChooseFile;
