import React from "react";
import FormLabel from "@mui/material/FormLabel";
import HelpIcon from "./HelpIcon";

const HelpLabel = ({ label, tooltip }) => {
  return (
    <FormLabel component="legend" sx={{ pb: 1.5 }}>
      {label}
      {tooltip ? (
        <HelpIcon tooltip={tooltip} placement="right" iconName="Info" />
      ) : (
        <></>
      )}
    </FormLabel>
  );
};

export default HelpLabel;
