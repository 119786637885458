export const initialStorage = {
  applicantId: null,
  accessToken: null,
  accountStatus: null,
  accountType: null,
  deviceId: "",
  dicDraft: {},
  listDraft: [],
  registerEmail: "",
  email_trade_confirmation_consent: false,
  ekyc_consent: false,
  toc_consent: false,
  listEnv: [
    "dev1",
    "uat",
    "equix",
    "morrison",
    "ricard",
    "equitystorytrader",
    "optixtrading",
    "tradeforgood",
    "sharewise",
  ],
  applicantInfo: {},
  setError: null,
  brokerData: null,
  refreshToken: null,
  tokenRefresh: null,
  refreshTokenId: null,
  activeIndex: 0,
  indexApplicant: 0,
  equixId: null,
  applicantOther: null,
  isSubApplicant: false,
  addressData: {},
  theme: "dark",
  all_application_submitted: false,
  isOperatorSupport: false,
  isSubmitted: false,
};

const dataStorage = {
  enviroment: "UAT",
  userType: 0,
  addressKey: "",
  baseUrl: "",
  config: {},
  configAll: {},
  ...initialStorage,
};
export default dataStorage;
