import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    formControl: {
      margin: "8px 0 !important",
      "& .MuiFormControlLabel-root": {
        alignItems: "flex-start",
        "& > span": {
          "&:first-child": {
            height: "fit-content",
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
  };
});

export default function CheckboxField(props) {
  const classes = useStyles();
  const { label, helpText, condition, ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const { setValue } = helper;
  const isError = touched && error && true;

  function _renderHelperText() {
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    } else if (helpText && condition?.(field.value)) {
      return <FormHelperText>{helpText}</FormHelperText>;
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl className={classes.formControl} {...rest} error={isError}>
      <FormControlLabel
        value={field.value}
        checked={!!field.value}
        control={<Checkbox {...field} onChange={_onChange} />}
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
