import React from "react";
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "../Footer";
import dataStorage from "../../dataStorage";
import ChooseTheme from "@Components/ChooseTheme";
import Environment from "@Components/Environment";
import Error from "@Components/Error";
import { BackgroundImage } from "@patternfly/react-core";

export default function MaterialLayout(props) {
  const { children } = props;
  const [mode, setMode] = React.useState(dataStorage.theme);

  React.useEffect(() => {
    const changeTheme = (theme) => {
      dataStorage.theme = theme;
      document.body.classList.remove("dark", "light");
      document.body.classList.add(`${theme}`);
      setMode(theme);
      // setContextMenu(null);
    };
    dataStorage.changeTheme = changeTheme;
  }, []);

  const theme = React.useMemo(() => {
    const themeData =
      mode === "light"
        ? createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1895,
              },
            },
            palette: {
              mode: "light",
              verify: {
                verified: "#e0ebb2",
                failed: "#fbd4d9",
                normal: "#E6E7E8",
              },
              table: {
                header: "#f5f5f5",
              },
              checkboxContainer: {
                background: "#eeeeee",
              },
              accordion: {
                expand: "#dff0d8",
              },
              adormentDisable: "rgba(0, 0, 0, 0.38)",
            },
            typography: {
              fontSize: 16,
            },
          })
        : createTheme({
            breakpoints: {
              values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
                xxl: 1895,
              },
            },
            components: {
              MuiPaper: {
                styleOverrides: {
                  root: {
                    backgroundImage: "unset",
                  },
                },
              },
            },
            palette: {
              mode: "dark",
              verify: {
                verified: "#7ecb20",
                failed: "#cb9ca1",
                normal: "#aeaeae",
              },
              table: {
                header: "#c2c2c2",
              },
              checkboxContainer: {
                background: "#616161",
              },
              accordion: {
                expand: "#e0ebb2",
              },
              background: {
                paper: "#424242",
              },
              adormentDisable: "rgba(255, 255, 255, 0.5)",
            },
            typography: {
              fontSize: 16,
            },
          });
    return responsiveFontSizes(themeData);
  }, [mode]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div id="topMark" />
        <BackgroundImage src={`/${dataStorage.env}/background.jpeg`} />
        <Error />
        <Environment />
        <ChooseTheme />
        {/* <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundImage: `url(/${dataStorage.env}/background.jpeg)` }} className='pf-c-background-image pf-c-background-image__filter'> */}
        {children}
        {/* </div> */}
        <Footer color="white" />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
