import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const ApplicantNote = () => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: "bold", marginTop: 16 }}>
          APPLICANT DETAILS
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" align="justify">
          Please make sure your details match what is shown on your ID. This
          information will be used to create your trading account, and for the
          purpose of conducting an Electronic Verification (EV) check which is
          required by the AML/CTF (Anti-Money Laundering and Counter-Terrorism
          Financing) Act.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">* = required field</Typography>
      </Grid>
    </React.Fragment>
  );
};

export default ApplicantNote;
