import dataStorage from "@s/dataStorage";

const ENVIROMENT_BACK = {
  equix: "equix",
  morrison: "morrison",
  equitystorytrader: "equity-story",
  ricard: "ricard",
  mps: "mps",
  dev1: "dev1",
  uat: "uat",
  optixtrading: "optixtrading",
  tradeforgood: "tradeforgood",
  sharewise: "sharewise",
};

export function getFile(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", url);
    xhr.onload = function () {
      if (this.status === 200) {
        const contentTypeHeader = xhr.getResponseHeader("Content-Type");
        const blob = new Blob([this.response], { type: contentTypeHeader });
        resolve(blob);
      } else {
        resolve(null);
      }
    };
    xhr.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );
    xhr.setRequestHeader("Authorization", `Bearer ${dataStorage.accessToken}`);
    xhr.send(null);
  });
}

export function getData(url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      }),
    })
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        const data = res ? JSON.parse(res) : "";
        if (data && (data.error || data.error_code)) reject(data);
        else resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postFile(url, data) {
  let isError = false;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      }),
      body: data,
    })
      .then((res) => {
        if (res.status !== 200) isError = true;
        return res.text();
      })
      .then((res) => {
        const data = res ? JSON.parse(res) : "";
        if (isError) {
          reject(data);
        } else {
          if (data && (data.error || data.error_code)) reject(data);
          else resolve(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postData(url, data) {
  let isError = false;
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      }),
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status !== 200) isError = true;
        return res.text();
      })
      .then((res) => {
        const data = res ? JSON.parse(res) : "";
        if (isError) {
          reject(data);
        } else {
          if (data && (data.error || data.error_code)) reject(data);
          else resolve(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function putData(url, data) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "PUT",
      headers: new Headers({
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      }),
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        const data = res ? JSON.parse(res) : "";
        if (data && (data.error || data.error_code)) reject(data);
        else resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteData(url, data) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${dataStorage.accessToken}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        environment: ENVIROMENT_BACK[dataStorage.env],
      }),
      body: JSON.stringify(data),
    })
      .then((res) => {
        return res.text();
      })
      .then((res) => {
        const data = res ? JSON.parse(res) : "";
        if (data && (data.error || data.error_code)) reject(data);
        else resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
