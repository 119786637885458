import React from "react";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const useStyles = makeStyles((theme) => ({
  applicantButton: {
    "& p": {
      display: "block",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    "& svg": {
      display: "none",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
  },
}));

export const PersonRemoveIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M1,10V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z" />
    </SvgIcon>
  );
};

export default function ApplicantButton({
  isNotAllowAddApplicant,
  isNotAllowRemoveApplicant,
  addApplicant,
  removeApplicant,
}) {
  const classes = useStyles();
  return (
    <div className={classes.applicantButton}>
      {isNotAllowRemoveApplicant ? (
        <React.Fragment />
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={removeApplicant}
          style={{ marginRight: 8 }}
          title="Remove This Applicant"
        >
          <Typography>Remove This Applicant</Typography>
          <PersonRemoveIcon />
        </Button>
      )}
      {isNotAllowAddApplicant ? (
        <React.Fragment />
      ) : (
        <Button
          variant="outlined"
          color="primary"
          onClick={addApplicant}
          title="Add Applicant"
        >
          <Typography>Add Applicant</Typography>
          <PersonAddIcon />
        </Button>
      )}
    </div>
  );
}
