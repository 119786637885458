import Yup from "@Common/customYup";
import formModels from "./formModels";
import {
  GOVERNMENT_ID_TYPE,
  CMA_SOURCE_OF_FUNDS,
  CMA_ACCOUNT_PURPOSE,
  OCCUPATION_TYPE,
  ACCOUNT_TYPE,
  TAX_STATUS,
  FATCA_STATUS,
} from "@Common/constants";
import { getEnv } from "@s/helper/utils";

const { formField } = formModels;

const validationSchema = (index) => ({
  "Trustee Type": Yup.object().shape({}),
  "Account Designation": Yup.object().shape({
    [formField.account_designation.name]: Yup.mixed().checkAccountDesignation(),
  }),
  "Trust Details": Yup.object().shape({
    [formField.trust_description.name]: Yup.mixed()
      .checkRequired("Trust Description is required")
      .checkMaxLength(100, "Trust Description"),
    [formField.tax_status.name]: Yup.mixed().checkRequired(
      "Tax Status is required"
    ),
    [formField.membership_class_details.name]: Yup.mixed().when(
      [formField.beneficiaries_membership_of_a_class.name],
      (ask, schema) => {
        return ask
          ? schema.checkRequired("Details of Membership Class(es) is required")
          : schema;
      }
    ),
    [formField.taxpayer_identification_number.name]: Yup.mixed().when(
      [
        formField.tax_status.name,
        formField.is_fi_non_participarting_crs_managed_by_other_fi.name,
        formField.tax_resident_of_australia.name,
        formField.tin_ask.name,
      ],
      (status, isFi, tax, ask, schema) => {
        const validStatus =
          status?.value === TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY ||
          status?.value === TAX_STATUS.OTHER ||
          (status?.value === TAX_STATUS.FINANCIAL_INSTITUTION && isFi);
        return validStatus && tax && ask
          ? schema.checkRequired(
              "Taxpayer Identification Number (TIN) is required"
            )
          : schema;
      }
    ),
    [formField.taxpayer_identification_number_exemption_reason.name]:
      Yup.mixed().when(
        [
          formField.tax_status.name,
          formField.is_fi_non_participarting_crs_managed_by_other_fi.name,
          formField.tax_resident_of_australia.name,
          formField.tin_ask.name,
        ],
        (status, isFi, tax, ask, schema) => {
          const validStatus =
            status?.value === TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY ||
            status?.value === TAX_STATUS.OTHER ||
            (status?.value === TAX_STATUS.FINANCIAL_INSTITUTION && isFi);
          return validStatus && tax && !ask
            ? schema.checkRequired("TIN Exemption Details is required")
            : schema;
        }
      ),
    [formField.country_of_tax_residency.name]: Yup.mixed().when(
      [
        formField.tax_status.name,
        formField.tax_resident_of_australia.name,
        formField.is_fi_non_participarting_crs_managed_by_other_fi.name,
      ],
      (status, tax, isFi, schema) => {
        return tax &&
          (status?.value === TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY ||
            (status?.value === TAX_STATUS.FINANCIAL_INSTITUTION && isFi) ||
            status?.value === TAX_STATUS.OTHER)
          ? schema.checkRequired("Country of Tax Residency is required")
          : schema;
      }
    ),
    [formField.global_intermediary_identification_number.name]:
      Yup.string().when(
        [formField.tax_status.name, formField.gin_asked.name],
        (status, gin, schema) => {
          return status?.value === TAX_STATUS.FINANCIAL_INSTITUTION && gin
            ? schema.checkRequired(
                "Trust's Global Intermediary Identification Number (GIIN) is required"
              )
            : schema;
        }
      ),
    [formField.trustee_global_intermediary_identification_number.name]:
      Yup.string().when(
        [
          formField.tax_status.name,
          formField.fatca_status.name,
          formField.gin_asked.name,
        ],
        (status, fatca, gin, schema) => {
          return status?.value === TAX_STATUS.FINANCIAL_INSTITUTION &&
            fatca?.value ===
              FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION &&
            !gin
            ? schema.checkRequired(
                "Trustee's Global Intermediary Identification Number (GIIN) is required"
              )
            : schema;
        }
      ),
    [formField.fatca_status.name]: Yup.mixed().when(
      [formField.tax_status.name, formField.gin_asked.name],
      (status, ask, schema) => {
        return status?.value === TAX_STATUS.FINANCIAL_INSTITUTION && !ask
          ? schema.checkRequired("Trust's FATCA Status is required")
          : schema;
      }
    ),
    [formField.fatca_status_other.name]: Yup.mixed().when(
      [
        formField.tax_status.name,
        formField.fatca_status.name,
        formField.gin_asked.name,
      ],
      (status, fatca, gin, schema) => {
        return status?.value === TAX_STATUS.FINANCIAL_INSTITUTION &&
          fatca?.value === FATCA_STATUS.OTHER &&
          !gin
          ? schema.checkRequired("Trust's FATCA Status Description is required")
          : schema;
      }
    ),
    [formField.trust_name.name]: Yup.mixed()
      .checkRequired("Trust Name is required")
      .checkMaxLength(100, "Company Name"),
    [formField.trust_deed.name]: Yup.array().of(Yup.mixed().checkFile()),
    [formField.trust_type.name]: Yup.mixed().checkRequired(
      "Trust Type is required"
    ),
    [formField.trust_abn.name]: Yup.string().checkABN(
      "Australian Business Number (ABN) is invalid"
    ),
    [formField.trust_tfn.name]: Yup.string().when(
      [formField.trust_tax_exemption.name],
      (exemtion, schema) => {
        return exemtion ? schema : schema.checkTfn(undefined);
      }
    ),
    [formField.trust_tax_exemption_details.name]: Yup.mixed().when(
      [formField.trust_tax_exemption.name],
      (exemption, schema) => {
        return exemption
          ? schema.checkRequired("Trust Tax Exemption Details is required")
          : schema;
      }
    ),
    [formField.trust_activity.name]: Yup.mixed()
      .checkRequired("Nature Of Trust Activity is required")
      .checkMaxLength(30, "Nature Of Trust Activity"),
    [formField.trust_asset_source_details.name]: Yup.mixed().checkRequired(
      "Asset Source is required"
    ),
    [formField.trust_address_full_address.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (isManual, schema) => {
        return isManual
          ? schema
          : schema.checkRequired("Trust Address is required");
      }
    ),
    [formField.trust_address_unit_flat_number.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema.checkMaxLength(80, "Unit Flat Number", undefined)
          : schema;
      }
    ),
    [formField.trust_address_street_number.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema
              .checkRequired("Street Number is required")
              .checkMaxLength(80, "Street Number", null)
          : schema;
      }
    ),
    [formField.trust_address_street_name.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema
              .checkRequired("Street Name is required")
              .checkMaxLength(80, "Street Name", undefined)
          : schema;
      }
    ),
    [formField.trust_address_street_type.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema.checkRequired("Street Type is required")
          : schema;
      }
    ),
    [formField.trust_address_city_suburb.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema
              .checkRequired("Suburb is required")
              .checkMaxLength(80, "Suburb", undefined)
          : schema;
      }
    ),
    [formField.trust_address_state.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema.checkRequired("State is required")
          : schema;
      }
    ),
    [formField.trust_address_postcode.name]: Yup.mixed().when(
      formField.trust_address_manual_address.name,
      (manualAddress, schema) => {
        return manualAddress
          ? schema
              .checkRequired("Postcode is required")
              .checkNumber("Postcode must be number")
              .checkEqualLength(4, "Postcode", "Postcode must be 4 digits")
          : schema;
      }
    ),
  }),
  "Company Details": Yup.object().shape({
    [formField.company_name.name]: Yup.mixed()
      .checkRequired("Company Name is required")
      .checkMaxLength(100, "Company Name"),
    [formField.company_type.name]: Yup.mixed().checkRequired(
      "Company Type is required"
    ),
    [formField.company_sector.name]: Yup.mixed().checkRequired(
      "Company Sector is required"
    ),
    [formField.company_acn.name]: Yup.string()
      .checkRequired("Australian Company Number (ACN) is required")
      .checkACN("Australian Company Number (ACN) is invalid"),
    [formField.company_abn.name]: Yup.string().checkABN(
      "Australian Business Number (ABN) is invalid"
    ),
    [formField.company_tax_exemption_details.name]: Yup.mixed().when(
      [formField.company_tax_exemption.name],
      (exemption, schema) => {
        return exemption
          ? schema.checkRequired("Company Tax Exemption Details is required")
          : schema;
      }
    ),
    [formField.company_tfn.name]: Yup.string().checkTfn(undefined),
    [formField.company_date_of_incorporation.name]: Yup.mixed()
      .checkRequired("Date of Registration is required")
      .checkMaxDate("Date of Registration invalid"),
    [formField.company_work_phone.name]: Yup.mixed()
      .atLeastOneRequired(
        [formField.company_mobile_phone.name],
        "Provide at least one contact phone number"
      )
      .checkPhone("Company Landline invalid", undefined, 10),
    [formField.company_mobile_phone.name]: Yup.mixed()
      .atLeastOneRequired(
        [formField.company_work_phone.name],
        "Provide at least one contact phone number"
      )
      .checkPhone(undefined),
    [formField.company_email.name]: Yup.string().checkApplicantEmail(),
    [formField.company_registered_office_address_full_address.name]:
      Yup.mixed().when(
        formField.company_registered_office_address_manual_address.name,
        (isManual, schema) => {
          return isManual
            ? schema
            : schema.checkRequired(
                "Company Registered Office Address is required"
              );
        }
      ),
    [formField.company_principal_place_of_business_address_full_address.name]:
      Yup.mixed().when(
        [
          formField.company_same_as_roa.name,
          formField.company_principal_place_of_business_address_manual_address
            .name,
        ],
        (same, isManual, schema) => {
          return same || isManual
            ? schema
            : schema.checkRequired(
                "Company Principal Place of Business Address is required"
              );
        }
      ),
  }),
  "Applicant Details": Yup.object().shape({
    [formField.applicant_details.name]: Yup.array().of(
      Yup.object().when(
        formField.applicant_details.applicant_email.originName,
        (email, schema, options) => {
          const { path } = options;
          const indexOfElement = +path.match(/[\d]+/)[0];
          if (indexOfElement !== index) return schema;
          return schema.shape({
            [formField.applicant_details.title.originName]:
              Yup.mixed().checkRequired("Title is required"),
            [formField.applicant_details.first_name.originName]: Yup.string()
              .checkRequired("First Name is required")
              .checkLengthName("First Name"),
            [formField.applicant_details.middle_name.originName]:
              Yup.string().checkLengthName("Middle Name"),
            [formField.applicant_details.last_name.originName]: Yup.string()
              .checkRequired("Last Name is required")
              .checkLengthName("Last Name"),
            [formField.applicant_details.gender.originName]:
              Yup.mixed().checkRequired("Gender is required"),
            [formField.applicant_details.role_in_trust.originName]:
              Yup.mixed().test("checkRoleInTrust", "", (value, option) => {
                const trusteeType =
                  option?.from?.[1]?.value?.trustee_type?.value;
                return trusteeType === ACCOUNT_TYPE.COMPANY &&
                  (!value || !Object.values(value).filter((e) => e).length)
                  ? option.createError({
                      path: option.path,
                      message: "Role in Trust is required",
                    })
                  : true;
              }),
            [formField.applicant_details.dob.originName]: Yup.mixed()
              .checkRequired("Date of Birth is required")
              .checkMinAge(18, undefined),
            [formField.applicant_details.applicant_mobile_phone.originName]:
              Yup.mixed()
                .checkRequired("Mobile Phone is required")
                .checkPhone(undefined),
            [formField.applicant_details.nationality.originName]:
              Yup.mixed().checkRequired("Country of Citizenship is required"),
            [formField.applicant_details.government_id.name]: Yup.object().when(
              formField.applicant_details.applicant_email.originName,
              (email, schema) => {
                return schema.shape({
                  [formField.applicant_details.government_id.type.originName]:
                    Yup.mixed().checkRequired("ID Type is required"),
                  [formField.applicant_details.government_id.number.originName]:
                    Yup.string().when(
                      [
                        formField.applicant_details.government_id.type
                          .originName,
                      ],
                      (type, schema) => {
                        if (type) {
                          if (type.value === GOVERNMENT_ID_TYPE.PASSPORT) {
                            return schema.checkPassport(undefined);
                          } else if (
                            type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
                          ) {
                            return schema.checkDriverLicense(undefined);
                          }
                          return schema.checkMedicareCard(undefined);
                        } else {
                          return schema;
                        }
                      }
                    ),
                  [formField.applicant_details.government_id.state_of_issue
                    .originName]: Yup.mixed().when(
                    [formField.applicant_details.government_id.type.originName],
                    (type, schema) => {
                      return type &&
                        type.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
                        ? schema.checkRequired("State of Issue is required")
                        : schema;
                    }
                  ),
                  [formField.applicant_details.government_id.card_number
                    .originName]: Yup.string().when(
                    [
                      formField.applicant_details.government_id.type.originName,
                      formField.applicant_details.government_id.state_of_issue
                        .originName,
                    ],
                    (type, stateOfIssue, schema) => {
                      return type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
                        ? schema
                            .checkRequired(
                              "Driver License Card No. is required"
                            )
                            .checkDriverCardNumber(undefined, stateOfIssue)
                        : schema;
                    }
                  ),
                  [formField.applicant_details.government_id
                    .medicare_card_colour.originName]: Yup.mixed().when(
                    [formField.applicant_details.government_id.type.originName],
                    (type, schema) => {
                      return type &&
                        type.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
                        ? schema.checkRequired("Card Color is required")
                        : schema;
                    }
                  ),
                  [formField.applicant_details.government_id
                    .medicare_card_expiry_date.originName]: Yup.string().when(
                    [formField.applicant_details.government_id.type.originName],
                    (type, schema) => {
                      return type &&
                        type.value === GOVERNMENT_ID_TYPE.MEDICARE_CARD
                        ? schema.checkExpireDate(undefined)
                        : schema;
                    }
                  ),
                });
              }
            ),
            [formField.applicant_details.residential_address_full_address
              .originName]: Yup.mixed().when(
              [
                formField.applicant_details.residential_address_manual_address
                  .originName,
              ],
              (isManual, schema) => {
                return isManual
                  ? schema
                  : schema.checkRequired("Address is required");
              }
            ),
            [formField.applicant_details.residential_address_unit_flat_number
              .originName]: Yup.mixed().when(
              formField.applicant_details.residential_address_manual_address
                .originName,
              (manualAddress, schema) => {
                return manualAddress
                  ? schema
                      .checkLanguageField(
                        formField.applicant_details
                          .residential_address_unit_flat_number.label
                      )
                      .checkMaxLength(80, "Unit Flat Number")
                  : schema;
              }
            ),
            [formField.applicant_details.residential_address_street_number
              .originName]: Yup.mixed().when(
              formField.applicant_details.residential_address_manual_address
                .originName,
              (manualAddress, schema) => {
                return manualAddress
                  ? schema
                      .checkRequired("Street Number is required")
                      .checkLanguageField(
                        formField.applicant_details
                          .residential_address_street_number.label
                      )
                      .checkMaxLength(80, "Street Number")
                  : schema;
              }
            ),
            [formField.applicant_details.residential_address_street_name
              .originName]: Yup.mixed().when(
              formField.applicant_details.residential_address_manual_address
                .originName,
              (manualAddress, schema) => {
                return manualAddress
                  ? schema
                      .checkRequired("Street Name is required")
                      .checkLanguageField(
                        formField.applicant_details
                          .residential_address_street_name.label
                      )
                      .checkMaxLength(80, "Street Name")
                  : schema;
              }
            ),
            [formField.applicant_details.residential_address_street_type
              .originName]: Yup.mixed().when(
              formField.applicant_details.residential_address_manual_address
                .originName,
              (manualAddress, schema) => {
                return manualAddress
                  ? schema.checkRequired("Street Type is required")
                  : schema;
              }
            ),
            [formField.applicant_details.residential_address_city_suburb
              .originName]: Yup.mixed().when(
              formField.applicant_details.residential_address_manual_address
                .originName,
              (manualAddress, schema) => {
                return manualAddress
                  ? schema
                      .checkRequired("Suburb is required")
                      .checkLanguageField(
                        formField.applicant_details
                          .residential_address_city_suburb.label
                      )
                      .checkMaxLength(80, "Suburb")
                  : schema;
              }
            ),
            [formField.applicant_details.residential_address_state.originName]:
              Yup.mixed().when(
                formField.applicant_details.residential_address_manual_address
                  .originName,
                (manualAddress, schema) => {
                  return manualAddress
                    ? schema.checkRequired("State is required")
                    : schema;
                }
              ),
            [formField.applicant_details.residential_address_postcode
              .originName]: Yup.mixed().when(
              formField.applicant_details.residential_address_manual_address
                .originName,
              (manualAddress, schema) => {
                return manualAddress
                  ? schema
                      .checkRequired("Postcode is required")
                      .checkNumber("Postcode must be number")
                      .checkEqualLength(
                        4,
                        "Postcode",
                        "Postcode must be 4 digits"
                      )
                  : schema;
              }
            ),
            [formField.applicant_details.tfn.originName]: Yup.string().when(
              formField.applicant_details.australian_tax_resident.originName,
              (tax, schema) => {
                return tax ? schema.checkTfn(undefined) : schema;
              }
            ),
            [formField.applicant_details.tax_exemption_details.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details.australian_tax_resident
                    .originName,
                  formField.applicant_details.tax_exemption.originName,
                ],
                (tax, exemption, schema) => {
                  return tax && exemption
                    ? schema.checkRequired("Tax Exemption Details is required")
                    : schema;
                }
              ),
            [formField.applicant_details.occupation_type.originName]:
              Yup.mixed().checkRequired("Occupation Type is required"),
            [formField.applicant_details.business_owner_trading_name
              .originName]: Yup.mixed().when(
              formField.applicant_details.occupation_type.originName,
              (occupationType, schema) => {
                return occupationType?.value ===
                  OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                  ? schema.checkRequired(
                      "Trading Name of The Business You Own is required"
                    )
                  : schema;
              }
            ),

            [formField.applicant_details.own_cafe_restaurant.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details.occupation_type.originName,
                  formField.applicant_details.occupation_category.originName,
                ],
                (occupationType, occupationCate, schema) => {
                  const checkOccupationCate =
                    occupationCate?.value === "Cafe and Restaurant";
                  const checkOccupationType =
                    occupationType?.value ===
                    OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER;

                  return checkOccupationCate && checkOccupationType
                    ? schema.checkRequired(
                        "Do you own a Café or a Restaurant is required"
                      )
                    : schema;
                }
              ),

            [formField.applicant_details
              .business_owner_trading_address_full_address.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details
                    .business_owner_trading_address_manual_address.originName,
                  formField.applicant_details.occupation_type.originName,
                ],
                (isManual, occupationType, schema) => {
                  return isManual ||
                    occupationType?.value !==
                      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                    ? schema
                    : schema.checkRequired(
                        "Business Owner Trading Address is required"
                      );
                }
              ),
            [formField.applicant_details
              .business_owner_trading_address_unit_flat_number.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details
                    .business_owner_trading_address_manual_address.originName,
                  formField.applicant_details.occupation_type.originName,
                ],
                (manualAddress, occupationType, schema) => {
                  return manualAddress &&
                    occupationType?.value ===
                      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                    ? schema.checkMaxLength(80, "Unit Flat Number", undefined)
                    : schema;
                }
              ),
            [formField.applicant_details
              .business_owner_trading_address_street_number.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details
                    .business_owner_trading_address_manual_address.originName,
                  formField.applicant_details.occupation_type.originName,
                ],
                (manualAddress, occupationType, schema) => {
                  return manualAddress &&
                    occupationType?.value ===
                      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                    ? schema
                        .checkRequired("Street Number is required")
                        .checkMaxLength(80, "Street Number", null)
                    : schema;
                }
              ),
            [formField.applicant_details
              .business_owner_trading_address_street_name.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details
                    .business_owner_trading_address_manual_address.originName,
                  formField.applicant_details.occupation_type.originName,
                ],
                (manualAddress, occupationType, schema) => {
                  return manualAddress &&
                    occupationType?.value ===
                      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                    ? schema
                        .checkRequired("Street Name is required")
                        .checkMaxLength(80, "Street Name", undefined)
                    : schema;
                }
              ),
            [formField.applicant_details
              .business_owner_trading_address_street_type.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details
                    .business_owner_trading_address_manual_address.originName,
                  formField.applicant_details.occupation_type.originName,
                ],
                (manualAddress, occupationType, schema) => {
                  return manualAddress &&
                    occupationType?.value ===
                      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                    ? schema.checkRequired("Street Type is required")
                    : schema;
                }
              ),
            [formField.applicant_details
              .business_owner_trading_address_city_suburb.originName]:
              Yup.mixed().when(
                [
                  formField.applicant_details
                    .business_owner_trading_address_manual_address.originName,
                  formField.applicant_details.occupation_type.originName,
                ],
                (manualAddress, occupationType, schema) => {
                  return manualAddress &&
                    occupationType?.value ===
                      OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                    ? schema
                        .checkRequired("Suburb is required")
                        .checkMaxLength(80, "Suburb", undefined)
                    : schema;
                }
              ),
            [formField.applicant_details.business_owner_trading_address_state
              .originName]: Yup.mixed().when(
              [
                formField.applicant_details
                  .business_owner_trading_address_manual_address.originName,
                formField.applicant_details.occupation_type.originName,
              ],
              (manualAddress, occupationType, schema) => {
                return manualAddress &&
                  occupationType?.value ===
                    OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                  ? schema.checkRequired("State is required")
                  : schema;
              }
            ),
            [formField.applicant_details.business_owner_trading_address_postcode
              .originName]: Yup.mixed().when(
              [
                formField.applicant_details
                  .business_owner_trading_address_manual_address.originName,
                formField.applicant_details.occupation_type.originName,
              ],
              (manualAddress, occupationType, schema) => {
                return manualAddress &&
                  occupationType?.value ===
                    OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                  ? schema
                      .checkRequired("Postcode is required")
                      .checkNumber("Postcode must be number")
                      .checkEqualLength(
                        4,
                        "Postcode",
                        "Postcode must be 4 digits"
                      )
                  : schema;
              }
            ),
            [formField.applicant_details.abn_acn_registration_number
              .originName]: Yup.mixed().when(
              formField.applicant_details.occupation_type.originName,
              (occupationType, schema) => {
                return occupationType?.value ===
                  OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
                  ? schema.checkMaxLength(
                      11,
                      "ABN/ACN/Registration Number of The Business",
                      undefined
                    )
                  : schema;
              }
            ),
            [formField.applicant_details.occupation_category.originName]:
              Yup.mixed().checkRequiredOccupationCategory(
                "Occupation Category is required"
              ),
            [formField.applicant_details.source_of_wealth.originName]:
              Yup.mixed().checkRequired("Source of Wealth is required"),
            [formField.applicant_details.applicant_email.originName]:
              Yup.string().checkApplicantEmail(),
          });
        }
      )
    ),
  }),
  "Additional Information": Yup.object().shape({
    [formField.applicant.name]: Yup.mixed().checkRequired(
      "An applicant for account mailing address is required"
    ),
    [formField.mailing_address_full_address.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (isManual, applicant, schema) => {
        return isManual || applicant?.value
          ? schema
          : schema.checkRequired("Mailing Address is required");
      }
    ),
    [formField.mailing_address_unit_flat_number.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema.checkMaxLength(80, "Unit Flat Number", undefined)
          : schema;
      }
    ),
    [formField.mailing_address_street_number.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema
              .checkRequired("Street Number is required")
              .checkMaxLength(80, "Street Number", null)
          : schema;
      }
    ),
    [formField.mailing_address_street_name.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema
              .checkRequired("Street Name is required")
              .checkMaxLength(80, "Street Name", undefined)
          : schema;
      }
    ),
    [formField.mailing_address_street_type.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema.checkRequired("Street Type is required")
          : schema;
      }
    ),
    [formField.mailing_address_city_suburb.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema
              .checkRequired("Suburb is required")
              .checkMaxLength(80, "Suburb", undefined)
          : schema;
      }
    ),
    [formField.mailing_address_state.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema.checkRequired("State is required")
          : schema;
      }
    ),
    [formField.mailing_address_postcode.name]: Yup.mixed().when(
      [formField.mailing_address_manual_address.name, formField.applicant.name],
      (manualAddress, applicant, schema) => {
        return manualAddress && applicant?.value === "OTHER"
          ? schema
              .checkRequired("Postcode is required")
              .checkNumber("Postcode must be number")
              .checkEqualLength(4, "Postcode", "Postcode must be 4 digits")
          : schema;
      }
    ),
    [formField.cma_source_of_funds.name]: Yup.mixed().when(
      "use_existing_CMT_acc",
      (iUseExist, schema) => {
        return iUseExist
          ? schema
          : schema.checkRequired("Source of Funds is required");
      }
    ),
    [formField.cma_source_of_funds_desc.name]: Yup.string().when(
      ["use_existing_CMT_acc", formField.cma_source_of_funds.name],
      (iUseExist, sourceOfFund, schema) => {
        return !iUseExist &&
          sourceOfFund?.value === CMA_SOURCE_OF_FUNDS[getEnv()].OTHER
          ? schema.checkRequired("Source of Funds Description is required")
          : schema;
      }
    ),
    [formField.cma_account_purpose.name]: Yup.mixed().when(
      "use_existing_CMT_acc",
      (iUseExist, schema) => {
        return iUseExist
          ? schema
          : schema.checkRequired("Account Purpose is required");
      }
    ),
    [formField.cma_account_purpose_desc.name]: Yup.string().when(
      ["use_existing_CMT_acc", formField.cma_account_purpose.name],
      (iUseExist, accountPurpose, schema) => {
        return !iUseExist && accountPurpose?.value === CMA_ACCOUNT_PURPOSE.OTHER
          ? schema.checkRequired("Account Purpose Description is required")
          : schema;
      }
    ),
    [formField.bank_bsb.name]: Yup.mixed().when(
      ["use_existing_CMT_acc"],
      (cma, schema) => {
        if ([null, undefined].includes(cma)) return schema;
        return cma === true ? schema.checkRequired("BSB is required") : schema;
      }
    ),
    [formField.bank_account_number.name]: Yup.string().when(
      ["use_existing_CMT_acc"],
      (cma, schema) => {
        if ([null, undefined].includes(cma)) return schema;
        return cma === true ? schema.checkMaxLength(20, "BSB") : schema;
      }
    ),
    [formField.settlement_method.name]: Yup.mixed().checkRequired(
      "Settlement Method is required"
    ),
    [formField.settlement_existing_hin.name]: Yup.string().when(
      [formField.settlement_method.name],
      (settlementMethod, schema) => {
        if ([null, undefined].includes(settlementMethod)) return schema;
        return !settlementMethod
          ? schema
              .checkRequired("Existing HIN is required")
              .checkNumber("Existing HIN must be number")
              .checkMaxLength(10, "Existing HIN")
          : schema;
      }
    ),
    [formField.settlement_pid.name]: Yup.mixed().when(
      [formField.settlement_method.name],
      (settlementMethod, schema) => {
        if ([null, undefined].includes(settlementMethod)) return schema;
        return !settlementMethod
          ? schema.checkRequired("Current Broker is required")
          : schema;
      }
    ),
  }),
  Review: Yup.object().shape({}),
});

export default validationSchema;
