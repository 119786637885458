import { makeStyles } from "@mui/styles";
export default makeStyles((theme) => ({
  appFooter: {
    padding: theme.spacing(3),
    [theme.breakpoints.down(600)]: {
      padding: theme.spacing(2),
    },
    backgroundColor: "transparent",
  },
}));
