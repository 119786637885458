import React from "react";
import PropTypes from "prop-types";
import { at } from "lodash";
import { useField } from "formik";
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

function SelectField(props) {
  const { label, data, helperText, variant = "outlined", ...rest } = props;
  const [field, meta, helper] = useField(props);
  const { setValue: setValueForm } = helper || {};
  const { value: selectedValue } = field;
  // const openRef = React.useRef(false);
  const [touched, error] = at(meta, "touched", "error");
  const isError = touched && error && true;
  // const [open, setOpen] = React.useState(false);

  // React.useEffect(() => {
  //   openRef.current = open;
  // }, [open]);

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    } else if (helperText) {
      return <FormHelperText>{helperText}</FormHelperText>;
    }
  }

  const _onChange = (event) => {
    const valueSelected = event.target.value;
    const option = data.find((e) => e.value === valueSelected);
    option && setValueForm && setValueForm(option);
  };

  /**
   * when list option expand and user scroll => remove list expand
   */
  // React.useEffect(() => {
  //   let timeoutid = null;
  //   const handleScroll = () => {
  //     timeoutid && clearTimeout(timeoutid);
  //     timeoutid = setTimeout(() => {
  //       openRef.current && setOpen(false);
  //     }, 100);
  //   };
  //   document.addEventListener("scroll", handleScroll);
  //   return () => {
  //     document.removeEventListener("scroll", handleScroll);
  //     clearTimeout(timeoutid);
  //   };
  // }, []);

  return (
    <FormControl
      variant={variant}
      {...rest}
      error={isError}
      className="selectField"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        value={
          selectedValue && data.length
            ? selectedValue.value ?? selectedValue
            : ""
        }
        // open={open}
        // onOpen={() => {
        //   setOpen(true);
        // }}
        // onClose={() => {
        //   setOpen(false);
        // }}
        onChange={_onChange}
        onBlur={field.onBlur}
        name={field.name}
        label={`${label}1`}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 228,
            },
          },
          // disableScrollLock: true,
        }}
        // {...field}
        // {...rest}
      >
        {data.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      {_renderHelperText()}
    </FormControl>
  );
}

SelectField.defaultProps = {
  data: [],
};

SelectField.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SelectField;
