import React from "react";
import {
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import { InputField } from "@Components/CustomFields";
import { getDraft } from "@s/helper/utils";
import OnboardingFormModel from "../FormModel/onboardingFormModel";
import formInitialValues from "../FormModel/formInitialValues";
import validationSchema from "../FormModel/validationSchema";
import { getSendOTPUrl, verifyOTP } from "@s/api/index";
import { getData, postData } from "@s/api/request";
import dataStorage, { initialStorage } from "@s/dataStorage";
import ChooseDraft from "../../PageApplication/ChooseDraft";
import ReactCodeInput from "react-code-input";
import { makeStyles, useTheme } from "@mui/styles";
import {
  LoginPage,
} from "@patternfly/react-core";
import logger from "@s/helper/logger";

const { formId, formField } = OnboardingFormModel;

const useStyles = makeStyles((theme) => ({
  centerContainer: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 64,
  },
  paper: {
    maxWidth: "400px",
    width: "100%",
    padding: theme.spacing(3),
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    // ,
    // // background: theme.palette.background.default
  },
  button: {
    width: "100%",
  },
  buttonProgress: {
    position: "absolute",
  },
  absoluteButton: {
    textTransform: "none",
    marginTop: theme.spacing(0),
  },
  loginWrapper: {
    minHeight: 0,
    display: "flex",
    flex: 1,
    "& .pf-c-brand": {
      height: 96,
      maxWidth: "calc(100vw - 32px)",
    },
    [theme.breakpoints.up("sm")]: {
      "& .pf-c-brand": {
        height: 128,
        maxWidth: 600,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& .pf-c-brand": {
        height: 128,
        maxWidth: 600,
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .pf-c-brand": {
        height: 128,
        maxWidth: 600,
      },
    },
    [theme.breakpoints.up("xl")]: {
      "& .pf-c-brand": {
        height: 200,
        maxWidth: 800,
      },
    },
  },
}));

export default function OnboardingPage() {
  const theme = useTheme();
  dataStorage.userType = 0;
  const classes = useStyles();
  const [step, setStep] = React.useState(0);
  const [verified, setVerified] = React.useState();
  const [pinCode, setPinCode] = React.useState("");
  const [userEmail, setUserEmail] = React.useState("");
  // const [btnIsPressed, setBtnIsPressed] = React.useState(false);
  const [invalidPIN, setInvalidPIN] = React.useState(false);
  const pinRef = React.useRef(undefined);

  const handlePinChange = (pinCode) => {
    setPinCode(pinCode);
    // setBtnIsPressed(false);
    setInvalidPIN(false);

    // check to go
    if (pinCode.length === 4) {
      const queryBody = {
        user_login_id: userEmail,
        otp: pinCode,
      };
      const listInput = document.querySelectorAll(".ReactCodeInput input");
      if (listInput && listInput[3]) listInput[3]?.blur?.();
      postData(verifyOTP(), queryBody)
        .then((res) => {
          const { application_token: token } = res;
          dataStorage.accessToken = token;
          dataStorage.registerEmail = queryBody.user_login_id;
          getDraft();
        })
        .catch((error) => {
          clearPin();
          setPinCode("");
          dataStorage.showError && dataStorage.showError(error);
          logger.error(error, "handlePinChange");
        });
    }
  };

  React.useEffect(() => {
    const goToApp = () => {
      setVerified(true);
    };
    dataStorage.goToApp = goToApp;
  }, []);

  function _handleSubmit(values, actions) {
    Object.assign(dataStorage, initialStorage);
    const { email } = values;
    if (step) {
      // go to application page
      const queryBody = {
        user_login_id: email,
        otp: pinCode,
      };
      postData(verifyOTP(), queryBody)
        .then((res) => {
          const { application_token: token } = res;
          dataStorage.accessToken = token;
          dataStorage.registerEmail = queryBody.user_login_id;
          getDraft()
            .then(() => {
              actions.setSubmitting(false);
            })
            .catch(() => {
              actions.setSubmitting(false);
            });
        })
        .catch((error) => {
          dataStorage.showError && dataStorage.showError(error);
          actions.setSubmitting(true);
        });
    } else {
      sendOTP(values.email)
        .then((res) => {
          if (res.errorCode) {
            dataStorage.showError && dataStorage.showError(res);
            throw Error();
          }
          setUserEmail(values.email);
          setStep(1);
          actions.setTouched({});
          actions.setSubmitting(false);
        })
        .catch(() => {
          actions.setTouched({});
          actions.setSubmitting(false);
        });
    }
  }

  const sendOTP = (email) => {
    return new Promise((resolve, reject) => {
      const url = getSendOTPUrl(email);
      getData(url)
        .then((res) => {
          if (res && res.errorCode) {
            dataStorage.showError && dataStorage.showError(res);
            reject(); // eslint-disable-line
          } else {
            logger.log(`send OTP success for ${email}`, "sendOTP");
            resolve(res);
          }
        })
        .catch((error) => {
          logger.error(`send OTP failed ${email}`, "sendOTP");
          dataStorage.showError && dataStorage.showError(error);
          reject(error);
        });
    });
  };

  const clearPin = () => {
    if (pinRef.current.textInput[0]) pinRef.current.textInput[0].focus();
    pinRef.current.state.input[0] = "";
    pinRef.current.state.input[1] = "";
    pinRef.current.state.input[2] = "";
    pinRef.current.state.input[3] = "";
  };

  function renderLoginForm(isSubmitting, values) {
    return (
      <React.Fragment>
        {!step ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <InputField
                required
                name={formField.email.name}
                label={formField.email.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                A verification PIN number will be sent to your email address
              </Typography>
            </Grid>
            <Grid item xs={12} align="right">
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Next
                {isSubmitting && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} align="center">
              <ReactCodeInput
                id="pinCode"
                type="number"
                name={formField.otp.name}
                className="ReactCodeInput"
                isValid={!invalidPIN}
                fields={4}
                pattern="[0-9]*"
                inputmode="numeric"
                onChange={handlePinChange}
                ref={pinRef}
                value={pinCode}
                inputStyle={{
                  fontFamily: "monospace",
                  margin: "4px",
                  MozAppearance: "textfield",
                  width: "48px",
                  borderRadius: "4px",
                  fontSize: "24px",
                  height: "56px",
                  paddingLeft: "16px",
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  border: "1px solid darkgrey",
                }}
                inputStyleInvalid={{
                  fontFamily: "monospace",
                  margin: "4px",
                  MozAppearance: "textfield",
                  width: "48px",
                  borderRadius: "4px",
                  fontSize: "24px",
                  height: "56px",
                  paddingLeft: "16px",
                  backgroundColor: "white",
                  color: "red",
                  border: "2px solid red",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              {/* If invalid PIN, show it here */}
              {invalidPIN ? (
                <Box
                  fontWeight="fontWeightLight"
                  style={{ backgroundColor: "Crimson" }}
                  color="White"
                  textAlign="center"
                >
                  wrong email code!
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} align="center">
              <ResendButton
                onClick={(e) => {
                  e.stopPropagation();
                  values.email && sendOTP(values.email);
                }}
                className={classes.absoluteButton}
              />
            </Grid>
          </Grid>
        )}
      </React.Fragment>
    );
  }

  if (verified) {
    return <ChooseDraft />;
  }

  const renderLogin = () => {
    return (
      <React.Fragment>
        <div className={classes.centerContainer}>
          <Paper className={classes.paper} elevation={15}>
            {/* Display header */}
            <Typography component="div">
              <Box fontWeight="fontWeightLight" textAlign="center"></Box>
              <Box
                fontWeight="fontWeightLight"
                fontSize="h4.fontSize"
                textAlign={!step ? "left" : "center"}
              >
                {!step ? "New Trading Account" : "Enter Email Code"}
              </Box>
              {!step ? (
                <Box fontWeight="fontWeightBold" textAlign="center">
                  <br />{" "}
                </Box>
              ) : (
                <Box fontWeight="fontWeightBold" textAlign="center">
                  <br />
                  {userEmail}
                  <br />
                  <br />
                </Box>
              )}
              {/* Display input box */}
            </Typography>
            <Formik
              initialValues={formInitialValues}
              validationSchema={validationSchema[step]}
              onSubmit={_handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form id={formId}>{renderLoginForm(isSubmitting, values)}</Form>
              )}
            </Formik>
          </Paper>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <LoginPage
        className={classes.loginWrapper}
        // brandImgSrc="https://www.novus-fintech.com/images/logo.svg"
        brandImgSrc={dataStorage.config?.logoDark}
        // brandImgAlt="PatternFly logo"
        // backgroundImgSrc={`/${dataStorage.env}/background.jpeg`}
        backgroundImgAlt="Images"
        // footerListItems={listItem()}
        // textContent="This is placeholder text only. Use this area to place any information or introductory message about your application that may be relevant to users."
        // loginTitle="Log in to your account"
        // cols={1}
        // loginSubtitle="Enter your single sign-on LDAP credentials."
        // socialMediaLoginContent={socialMediaLoginContent()}
        // signUpForAccountMessage={signUpForAccountMessage()}
        // forgotCredentials={forgotCredentials()}
      >
        {renderLogin()}
      </LoginPage>
    </React.Fragment>
  );
}

function ResendButton(props) {
  const [count, setCount] = React.useState();
  const intervalId = React.useRef(null);

  React.useEffect(() => {
    startCount();
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  React.useEffect(() => {
    if (count === 0) {
      clearInterval(intervalId.current);
    }
  }, [count]);

  const startCount = () => {
    setCount(60);
    intervalId.current && clearInterval(intervalId.current);
    intervalId.current = setInterval(() => {
      setCount((old) => old - 1);
    }, 1000);
  };

  const onResend = (e) => {
    if (count) return;
    startCount();
    props.onClick && props.onClick(e);
  };

  if (count) return <React.Fragment />;

  return (
    <Button
      // color="lightBlue"
      // variant="capital"
      style={{ justifyContent: "flex-start" }}
      onClick={onResend}
      // disabled={!!count}
      // variant="contained"
      className={props.className}
    >
      {`CLICK HERE TO SEND VERIFICATION CODE AGAIN ${
        count ? "(" + count + "s)" : ""
      }`}
    </Button>
  );
}
