import React from "react";
import { Grid, Typography, CardMedia, Link } from "@mui/material";
import { useFormikContext, FieldArray } from "formik";
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  RadioField,
  BooleanRadio,
  PhoneNumberField,
} from "@Components/CustomFields";
import ApplicantNote from "@Components/ApplicantNote";
import {
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  AUSTRALIAN_TAX_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS,
  OWN_CAFE_RESTAURANT_OPTIONS,
  SOURCE_OF_WEALTH,
} from "@Common/constants";
import COUNTRIES from "@Common/countries";
import dataStorage from "@s/dataStorage";
import { makeStyles } from "@mui/styles";
import formModel from "../FormModels/formModels";
import { getEnv, checkKycVerify, getMatchYear } from "@s/helper/utils";

const { formField } = formModel;

const useStyles = makeStyles((theme) => ({
  governmentIdImage: {
    objectFit: "contain",
    maxWidth: 600,
  },
  genderContainer: {
    "& .radio-horizontal": {
      display: "none",
    },
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xxl")]: {
      "& .radio-vertical": {
        display: "none",
      },
      "& .radio-horizontal": {
        display: "flex",
      },
    },
  },
}));

const ApplicationForm = React.forwardRef(() => {
  const classes = useStyles();
  const [activeIndex] = React.useState(0);
  const { values: formValues } = useFormikContext();
  const isVerified = checkKycVerify(
    formValues.applicant_details?.[activeIndex]?.ekyc_overall_status
  );

  const renderManualAddress = (nameField) => {
    if (!nameField) return <></>;
    return (
      <React.Fragment>
        <Grid item xs={12} className="addressContainer">
          {formValues.applicant_details[activeIndex][
            `${nameField}_manual_address`
          ] ? (
            <React.Fragment />
          ) : (
            <AutocompleteFieldAsync
              required
              name={`applicant_details[${activeIndex}][${nameField}_full_address]`}
              label={
                formField.applicant_details[`${nameField}_full_address`].label
              }
              fullWidth
            />
          )}
          <CheckboxField
            name={`applicant_details[${activeIndex}][${nameField}_manual_address]`}
            label="Cannot find your address? Manual Input"
          />
        </Grid>
        {formValues.applicant_details[activeIndex][
          `${nameField}_manual_address`
        ] ? (
          <>
            <Grid item xs={6} sm={6}>
              <InputField
                name={`applicant_details[${activeIndex}].${nameField}_unit_flat_number`}
                label={
                  formField.applicant_details[nameField + "_unit_flat_number"]
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_number`}
                label={
                  formField.applicant_details[nameField + "_street_number"]
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_name`}
                label={
                  formField.applicant_details[nameField + "_street_name"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_type`}
                label={
                  formField.applicant_details[nameField + "_street_type"].label
                }
                data={STREET_TYPE}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_city_suburb`}
                label={
                  formField.applicant_details[nameField + "_city_suburb"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].${nameField}_state`}
                label={formField.applicant_details[nameField + "_state"].label}
                data={STATE_OF_ISSUE_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_postcode`}
                label={
                  formField.applicant_details[nameField + "_postcode"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const renderVerification = () => {
    let imagePath = "";
    if (
      formValues.applicant_details[activeIndex].government_id?.type?.value ===
      GOVERNMENT_ID_TYPE.DRIVER_LICENSE
    ) {
      if (
        formValues.applicant_details[activeIndex].government_id?.state_of_issue
          ?.value
      ) {
        imagePath = `/images/${formValues.applicant_details[activeIndex].government_id.type?.value}_${formValues.applicant_details[activeIndex].government_id?.state_of_issue?.value}.svg`;
      }
    } else if (
      formValues.applicant_details[activeIndex].government_id?.type?.value ===
      GOVERNMENT_ID_TYPE.PASSPORT
    ) {
      imagePath = "/images/passport.svg";
    }
    return (
      <React.Fragment>
        <Grid item container spacing={3} xs={12} sm={6}>
          <Grid item xs={12}>
            <RadioField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].government_id.type`}
              label={formField.applicant_details.government_id.type.label}
              data={GOVERNMENT_ID_TYPE_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid style={{ margin: 16 }}></Grid>
          {formValues.applicant_details[activeIndex]?.government_id?.type
            ?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE ? (
            <React.Fragment>
              <Grid item xs={12}>
                <SelectField
                  disabled={isVerified}
                  required
                  name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
                  label={
                    formField.applicant_details.government_id.state_of_issue
                      .label
                  }
                  data={STATE_OF_ISSUE_OPTIONS}
                  fullWidth
                />
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment />
          )}
          <Grid item xs={12}>
            <InputField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].government_id.number`}
              label={
                formField.applicant_details.government_id.number.label[
                  formValues.applicant_details[activeIndex]?.government_id?.type
                    ?.value
                ]
              }
              fullWidth
            />
          </Grid>
          {formValues.applicant_details[activeIndex]?.government_id?.type
            ?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE ? (
            <Grid item xs={12}>
              <InputField
                inputProps={{
                  maxLength: 10,
                }}
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].government_id.card_number`}
                label={
                  formField.applicant_details.government_id.card_number.label
                }
                fullWidth
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item container spacing={0} xs={12} sm={6}>
          <Grid item xs={12}>
            {imagePath ? (
              <CardMedia
                className={classes.governmentIdImage}
                component="img"
                alt="Id Type Image"
                image={imagePath}
              />
            ) : (
              <React.Fragment />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <AutocompleteField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].title`}
            label={formField.applicant_details.title.label}
            data={TITLE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].first_name`}
            label={formField.applicant_details.first_name.label}
            ext={formField.applicant_details.first_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].middle_name`}
            label={formField.applicant_details.middle_name.label}
            ext={formField.applicant_details.middle_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].last_name`}
            label={formField.applicant_details.last_name.label}
            ext={formField.applicant_details.last_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 4 }}>
          <Typography variant="body2" className="textStatic">
            Full Name Example: John Peter Leslie Smith
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <DatePickerField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            // inputFormat="dd/MM/yyyy"
            inputFormat="dd/MM/yyyy"
            minDate={new Date("1920/1/1")}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <AutocompleteField
            disabled={true}
            required
            helperText="Note: Only Australian Citizens accepted"
            name={`applicant_details[${activeIndex}].nationality`}
            label={formField.applicant_details.nationality.label}
            data={COUNTRIES}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xxl={3}
          className={classes.genderContainer}
        >
          <RadioField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].gender`}
            label={formField.applicant_details.gender.label}
            data={GENDER_OPTIONS}
            fullWidth
          />
          <RadioField
            disabled={isVerified}
            isHorizontal
            required
            name={`applicant_details[${activeIndex}].gender`}
            label={formField.applicant_details.gender.label}
            data={GENDER_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <PhoneNumberField
            disabled={isVerified}
            required
            prefix="04"
            name={`applicant_details[${activeIndex}].applicant_mobile_phone`}
            label={formField.applicant_details.applicant_mobile_phone.label}
            fullWidth
          />
        </Grid>
        {renderManualAddress("residential_address")}
        {dataStorage.userType === 0 && activeIndex === 0 ? (
          <React.Fragment />
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={6}>
              <InputField
                disabled={isVerified || dataStorage.isOperatorSupport}
                required
                name={`applicant_details[${activeIndex}].applicant_email`}
                label={formField.applicant_details.applicant_email.label}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  };

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        {formValues.applicant_details[activeIndex].australian_tax_resident ? (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={
                  formField.applicant_details.australian_tax_resident.label
                }
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].tax_exemption`}
                label={formField.applicant_details.tax_exemption.label}
                data={AUSTRALIAN_TAX_OPTIONS}
              />
            </Grid>
            {formValues.applicant_details[activeIndex].tax_exemption ? (
              <Grid item xs={12} sm={12}>
                <SelectField
                  disabled={isVerified}
                  required
                  name={`applicant_details[${activeIndex}].tax_exemption_details`}
                  label={
                    formField.applicant_details.tax_exemption_details.label
                  }
                  data={TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS}
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12}>
                <InputField
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].tfn`}
                  label={formField.applicant_details.tfn.label}
                  helperText={
                    "Note: Quotation of your Australian Tax File Number(s) (TFN) is optional"
                  }
                  fullWidth
                />
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={
                  formField.applicant_details.australian_tax_resident.label
                }
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].source_of_wealth`}
            label={formField.applicant_details.source_of_wealth.label}
            data={SOURCE_OF_WEALTH_OPTIONS}
            fullWidth
          />
        </Grid>
        {formValues.applicant_details[activeIndex].source_of_wealth?.value ===
        SOURCE_OF_WEALTH.FOREIGN_INCOME ? (
          <Grid item xs={12} sm={12}>
            <BooleanRadio
              name={`applicant_details[${activeIndex}].subclass_visa`}
              label={formField.applicant_details.subclass_visa.label}
              data={AUSTRALIAN_TAX_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={12}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].occupation_type`}
            label={formField.applicant_details.occupation_type.label}
            data={OCCUPATION_TYPE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField
            disabled={isVerified}
            required
            name={`applicant_details[${activeIndex}].occupation_category`}
            label={formField.applicant_details.occupation_category.label}
            data={
              OCCUPATION_CATEGORY_OPTIONS[
                formValues.applicant_details[activeIndex].occupation_type?.value
              ]
            }
            fullWidth
          />
        </Grid>
        {formValues.applicant_details[activeIndex].occupation_type?.value ===
        OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER ? (
          <React.Fragment>
            {["Hotel and Motel", "Accommodation and Food Services"].includes(
              formValues.applicant_details[activeIndex]?.occupation_category
                .value
            ) ? (
              <Grid item xs={12} sm={12}>
                <BooleanRadio
                  disabled={isVerified}
                  required
                  name={`applicant_details[${activeIndex}].liquor_gambling_licence`}
                  label={
                    formField.applicant_details.liquor_gambling_licence.label
                  }
                  data={AUSTRALIAN_TAX_OPTIONS}
                  fullWidth
                />
              </Grid>
            ) : null}
            {formValues.applicant_details[activeIndex]?.occupation_category
              ?.value === "Charity Community or Social Services" ? (
              <>
                <Grid item xs={12} sm={12}>
                  <BooleanRadio
                    disabled={isVerified}
                    required
                    name={`applicant_details[${activeIndex}].if_business_is_regulated_charity`}
                    label={
                      formField.applicant_details
                        .if_business_is_regulated_charity.label
                    }
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}
            {formValues.applicant_details[activeIndex]?.occupation_category
              ?.value === "Cafe and Restaurant" ? (
              <>
                <Grid item xs={12}>
                  <SelectField
                    disabled={isVerified}
                    required
                    name={`applicant_details[${activeIndex}].own_cafe_restaurant`}
                    label={
                      formField.applicant_details.own_cafe_restaurant.label
                    }
                    data={OWN_CAFE_RESTAURANT_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}
            <Grid item xs={12} sm={12}>
              <InputField
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].business_owner_trading_name`}
                label={
                  formField.applicant_details.business_owner_trading_name.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                disabled={isVerified}
                type="number"
                name={`applicant_details[${activeIndex}].abn_acn_registration_number`}
                label={
                  formField.applicant_details.abn_acn_registration_number.label
                }
                fullWidth
              />
            </Grid>
            {renderManualAddress("business_owner_trading_address")}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  const renderConfirm = () => {
    return (
      <Grid item xs={12}>
        <CheckboxField
          disabled={isVerified}
          name={`applicant_details[${activeIndex}].ekyc_aml_consent`}
          label={
            <div>
              <span>I agree with </span>
              <Link
                className={classes.link}
                target="_blank"
                href={`/ekyc-and-aml-consent.pdf?${+new Date()}`}
              >
                the eKYC & AML Consent
              </Link>
              <span>.</span>
            </div>
          }
        />
      </Grid>
    );
  };

  return (
    <Grid container spacing={3}>
      <ApplicantNote />
      <FieldArray name={formField.applicant_details.name}>
        {() => {
          return formValues.applicant_details.map((e, i) => {
            if (i !== activeIndex)
              return <React.Fragment key={`applicant_details_${i}`} />;
            return (
              <React.Fragment key={`applicant_details_${i}`}>
                {renderPersonalInfo()}
                {renderVerification()}
                {renderFinancialInfo()}
                {renderConfirm()}
              </React.Fragment>
            );
          });
        }}
      </FieldArray>
    </Grid>
  );
});
ApplicationForm.displayName = "ApplicationForm";
export default ApplicationForm;
