import Typography from "@mui/material/Typography";
import React from "react";

export default function HelpText({ children, ...props }) {
  return (
    <Typography {...props} variant="body1">
      {children}
    </Typography>
  );
}
