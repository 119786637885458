import React from "react";
import {
  Grid,
  Typography,
  Select,
  Paper,
  ListItemText,
  MenuItem,
  Button,
} from "@mui/material";
import dataStorage from "@s/dataStorage";
import { ACCOUNT_TYPE } from "@Common/constants";
import { makeStyles } from "@mui/styles";
import { saveDraft, scrollToTop } from "@s/helper/utils";
import OnboardingModal from "./OnboardingModal";
import Logo from "@Components/Logo";

const useStyle = makeStyles((theme) => ({
  container: {
    maxWidth: 800,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: theme.spacing(4, 3, 2, 3),
    padding: theme.spacing(5),
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      margin: theme.spacing(2, 2, 0, 2),
      padding: theme.spacing(1, 2, 2, 2),
      maxWidth: "calc(100vw - 32px)",
    },
    alignItems: "flex-start",
    justifyContent: "space-between",
    "& ul": {
      margin: 0,
    },
    "& li": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiListItemText-multiline": {
      marginBottom: 22,
    },
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      "& img": {
        maxWidth: "30%",
      },
    },
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  itemSelect: {
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: theme?.palette?.mode === "dark" ? "#6e6e6e" : "#c4c4c4",
  },
}));

const options = [
  {
    value: ACCOUNT_TYPE.INDIVIDUAL,
    title: "Individual - Australian Resident",
    description: "You trade under your name",
  },
  {
    value: ACCOUNT_TYPE.JOINT,
    title: "Joint (2 or 3 Persons)",
    description: "You trade in joint names (more than 1 person)",
  },
  {
    value: ACCOUNT_TYPE.COMPANY,
    title: "Company",
    description: "You trade on behalf of a company or corporation",
  },
  {
    value: ACCOUNT_TYPE.TRUST,
    title: "Trust",
    description:
      "You trade as an individual trustee or on behalf of a corporate trust",
  },
  {
    value: ACCOUNT_TYPE.FUND,
    title: "Superannuation Fund",
    description: "You trade on behalf of a superannuation fund",
  },
  {
    value: ACCOUNT_TYPE.INTERNATIONAL_INVESTOR,
    title: "Internaltional Investor (Soon)",
    description: "You want to trade and are not an Australian resident",
    disabled: true,
  },
];

export default function ChooseAccountType({ backChooseDraft }) {
  const classes = useStyle();
  const accountType = React.useRef(
    dataStorage.accountType || ACCOUNT_TYPE.INDIVIDUAL
  );
  const [open, setOpen] = React.useState(false);
  const [start, setStart] = React.useState(
    !!Object.keys(dataStorage.dicDraft).length
  );

  React.useEffect(() => {
    if (!dataStorage.accountType)
      dataStorage.accountType = ACCOUNT_TYPE.INDIVIDUAL;
  }, []);

  const onStart = () => {
    scrollToTop();
    if (dataStorage.dicDraft?.id) {
      const id = dataStorage.dicDraft?.id;
      dataStorage.dicDraft = {
        data: {
          formData: {
            account_type: accountType.current,
          },
        },
        id,
      };
      saveDraft(
        {
          formData: {
            account_type: accountType.current,
          },
          id,
        },
        () => {
          setStart(true);
        }
      );
    } else {
      saveDraft(
        {
          formData: {
            account_type: accountType.current,
          },
        },
        () => {
          setStart(true);
        }
      );
    }
  };

  function renderChooseAccountType() {
    return (
      <Paper className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Logo />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Select the account type you would like to apply for:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              value={accountType.current}
              onChange={(e) => {
                accountType.current = e.target.value;
                dataStorage.accountType = accountType.current;
              }}
              variant="filled"
              fullWidth
            >
              {options.map((e, i) => {
                return (
                  <MenuItem
                    key={`account_type_MenuItem_${i}`}
                    value={e.value}
                    disabled={e.disabled}
                    className={classes.itemSelect}
                  >
                    <ListItemText
                      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                      primary={e.title}
                      secondary={e.description}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">To register you will need:</Typography>
            <ul>
              <li>
                <Typography variant="body1">
                  To be 18 years or older and an Australian resident.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Identification documents: Drivers License, Passport.
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Australian Tax File Number /ABN for businesses (optional).
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  To open a new CMA account during this application.
                </Typography>
              </li>
            </ul>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.rowContainer}>
              <Typography variant="body1">
                As part of this process you will have an account with our
                banking partner
              </Typography>
              <img
                className="logoDark logoDarkLight"
                src={"/images/logos/Macquarie_logo_dark.svg"}
                alt="Logo"
                width="auto"
                height="40px"
                style={{ marginLeft: 32 }}
              />
              <img
                className="logoLight logoDarkLight"
                src={"/images/logos/Macquarie_logo_light.svg"}
                alt="Logo"
                width="auto"
                height="40px"
                style={{ marginLeft: 32 }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonContainer}>
              <Button variant="contained" color="primary" onClick={onStart}>
                Next
              </Button>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  const resetToChooseAccountType = () => {
    dataStorage.equixId = null;
    dataStorage.indexApplicant = 0;
    dataStorage.isSubApplicant = false;
    dataStorage.accountStatus = "";
    setStart(false);
  };

  return (
    <React.Fragment>
      {start ? (
        <OnboardingModal
          onBack={resetToChooseAccountType}
          backChooseDraft={backChooseDraft}
        />
      ) : (
        renderChooseAccountType()
      )}
    </React.Fragment>
  );
}
