import {
  GOVERNMENT_ID_TYPE,
  CMA_ACCOUNT_PURPOSE,
  CMA_SOURCE_OF_FUNDS,
  OCCUPATION_TYPE,
  TAX_STATUS,
  FATCA_STATUS,
  SOURCE_OF_WEALTH,
} from "@Common/constants";
import { getEnv } from "../../../../helper/utils";

const fromModels = {
  formId: "companyForm",
  formField: {
    tax_status: {
      name: "tax_status",
      label: "Please select the most appropriate Tax Status",
    },
    gin_asked: {
      name: "gin_asked",
      label: "Does your Company have a GIIN?",
    },
    global_intermediary_identification_number: {
      name: "global_intermediary_identification_number",
      label: "Company's Global Intermediary Identification Number (GIIN)",
      dependentShow: {
        gin_asked: true,
        tax_status: TAX_STATUS.FINANCIAL_INSTITUTION,
      },
    },
    fatca_status: {
      name: "fatca_status",
      label: "Company's FATCA Status",
      dependentShow: {
        tax_status: TAX_STATUS.FINANCIAL_INSTITUTION,
        gin_asked: false,
      },
    },
    fatca_status_other: {
      name: "fatca_status_other",
      label: "Trust's FATCA Description",
      dependentShow: {
        tax_status: TAX_STATUS.FINANCIAL_INSTITUTION,
        gin_asked: false,
        fatca_status: FATCA_STATUS.OTHER,
      },
    },
    is_controlling_person_foreign_tax_resident: {
      name: "is_controlling_person_foreign_tax_resident",
      label:
        "Are any of the Company's Beneficial Owners or Controlling Persons tax residents of countries other than Australia?",
      dependentShow: {
        tax_status: TAX_STATUS.OTHER,
      },
    },
    tax_resident_of_australia: {
      name: "tax_resident_of_australia",
      label: "Is the Company a tax resident of a country other than Australia?",
      dependentShow: {
        tax_status: [TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY, TAX_STATUS.OTHER],
      },
    },
    country_of_tax_residency: {
      name: "country_of_tax_residency",
      label: "Country of Tax Residency",
      dependentShow: {
        tax_status: [TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY, TAX_STATUS.OTHER],
        tax_resident_of_australia: true,
      },
    },
    tin_ask: {
      name: "tin_ask",
      label: "Does the Company have a Taxpayer Identification Number (TIN)?",
      dependentShow: {
        tax_status: [TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY, TAX_STATUS.OTHER],
      },
    },
    taxpayer_identification_number: {
      name: "taxpayer_identification_number",
      label: "Taxpayer Identification Number (TIN)",
      dependentShow: {
        tax_status: [TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY, TAX_STATUS.OTHER],
        tax_resident_of_australia: true,
        tin_ask: true,
      },
    },
    taxpayer_identification_number_exemption_reason: {
      name: "taxpayer_identification_number_exemption_reason",
      label: "TIN Exemption Details",
      dependentShow: {
        tax_status: [TAX_STATUS.ACTIVE_NONFINANCIAL_ENTITY, TAX_STATUS.OTHER],
        tax_resident_of_australia: true,
        tin_ask: false,
      },
    },
    applicant: {
      name: "applicant",
      label: "Applicant",
    },
    mailing_address_manual_address: {
      name: "mailing_address_manual_address",
      dependentShow: {
        applicant: "OTHER",
      },
    },
    mailing_address_street_number: {
      name: "mailing_address_street_number",
      label: "Street Number",
    },
    mailing_address_unit_flat_number: {
      name: "mailing_address_unit_flat_number",
      label: "Unit/Flat",
    },
    mailing_address_postcode: {
      name: "mailing_address_postcode",
      label: "Postcode",
    },
    mailing_address_address_line_1: {
      name: "mailing_address_address_line_1",
      label: "Address Line 1",
    },
    mailing_address_address_line_2: {
      name: "mailing_address_address_line_2",
      label: "Address Line 1",
    },
    mailing_address_state: {
      name: "mailing_address_state",
      label: "State",
    },
    mailing_address_street_name: {
      name: "mailing_address_street_name",
      label: "Street Name",
    },
    mailing_address_city_suburb: {
      name: "mailing_address_city_suburb",
      label: "Suburb",
    },
    mailing_address_street_type: {
      name: "mailing_address_street_type",
      label: "Street Type",
    },
    mailing_address_country: {
      name: "mailing_address_country",
      label: "Country",
    },
    mailing_address_full_address: {
      name: "mailing_address_full_address",
      type: "address",
      label: "Mailing Address",
    },
    account_designation: {
      name: "account_designation",
      label: "Account Designation",
    },
    company_registered_office_address_manual_address: {
      name: "company_registered_office_address_manual_address",
    },
    company_principal_place_of_business_address_manual_address: {
      name: "company_principal_place_of_business_address_manual_address",
    },
    account_type: {
      name: "account_type",
      label: "Account Type",
    },
    company_name: {
      name: "company_name",
      label: "Full Name of Company",
    },
    company_type: {
      name: "company_type",
      label: "Company Type",
    },
    company_industry: {
      name: "company_industry",
      label: "Industry in which the company operates",
    },
    company_nature_of_business_activity: {
      name: "company_nature_of_business_activity",
      label: "Nature of the business activity",
    },
    company_source_of_wealth: {
      name: "company_source_of_wealth",
      label: "Source of wealth",
    },
    company_sector: {
      name: "company_sector",
      label: "Company Sector",
    },
    company_acn: {
      name: "company_acn",
      label: "Australian Company Number (ACN)",
    },
    company_abn: {
      name: "company_abn",
      label: "Australian Business Number (ABN)",
    },
    company_tfn: {
      name: "company_tfn",
      label: "Tax File Number (TFN)",
    },
    company_tax_exemption: {
      name: "company_tax_exemption",
      label: "TFN Exemption",
    },
    company_tax_exemption_details: {
      name: "company_tax_exemption_details",
      label: "Tax Exemption Details",
      dependentShow: {
        company_tax_exemption: true,
      },
    },
    company_date_of_incorporation: {
      name: "company_date_of_incorporation",
      label: "Date of Registration",
    },
    company_country_of_incorporation: {
      name: "company_country_of_incorporation",
      label: "Company Country of Incorporation",
    },
    company_work_phone: {
      name: "company_work_phone",
      label: "Company Landline",
    },
    company_mobile_phone: {
      name: "company_mobile_phone",
      label: "Company Mobile Number",
    },
    company_email: {
      name: "company_email",
      label: "Company Email",
    },
    company_registered_office_address_street_number: {
      name: "company_registered_office_address_street_number",
      label: "Street Number",
    },
    company_registered_office_address_unit_flat_number: {
      name: "company_registered_office_address_unit_flat_number",
      label: "Unit/Flat",
    },
    company_registered_office_address_postcode: {
      name: "company_registered_office_address_postcode",
      label: "Postcode",
    },
    company_registered_office_address_address_line_1: {
      name: "company_registered_office_address_address_line_1",
      label: "Address Line 1",
    },
    company_registered_office_address_address_line_2: {
      name: "company_registered_office_address_address_line_2",
      label: "Address Line 1",
    },
    company_registered_office_address_state: {
      name: "company_registered_office_address_state",
      label: "State",
    },
    company_registered_office_address_street_name: {
      name: "company_registered_office_address_street_name",
      label: "Street Name",
    },
    company_registered_office_address_city_suburb: {
      name: "company_registered_office_address_city_suburb",
      label: "Suburb",
    },
    company_registered_office_address_street_type: {
      name: "company_registered_office_address_street_type",
      label: "Street Type",
    },
    company_registered_office_address_country: {
      name: "company_registered_office_address_country",
      label: "Country",
    },
    company_registered_office_address_full_address: {
      name: "company_registered_office_address_full_address",
      type: "address",
      label: "Company Registered Office Address",
    },
    company_same_as_roa: {
      name: "company_same_as_roa",
      label: "Same as Registered Office Address",
    },
    company_principal_place_of_business_address_street_number: {
      name: "company_principal_place_of_business_address_street_number",
      label: "Street Number",
    },
    company_principal_place_of_business_address_unit_flat_number: {
      name: "company_principal_place_of_business_address_unit_flat_number",
      label: "Unit/Flat",
    },
    company_principal_place_of_business_address_postcode: {
      name: "company_principal_place_of_business_address_postcode",
      label: "Postcode",
    },
    company_principal_place_of_business_address_address_line_1: {
      name: "company_principal_place_of_business_address_address_line_1",
      label: "Address Line 1",
    },
    company_principal_place_of_business_address_address_line_2: {
      name: "company_principal_place_of_business_address_address_line_2",
      label: "Address Line 1",
    },
    company_principal_place_of_business_address_state: {
      name: "company_principal_place_of_business_address_state",
      label: "State",
    },
    company_principal_place_of_business_address_street_name: {
      name: "company_principal_place_of_business_address_street_name",
      label: "Street Name",
    },
    company_principal_place_of_business_address_city_suburb: {
      name: "company_principal_place_of_business_address_city_suburb",
      label: "Suburb",
    },
    company_principal_place_of_business_address_street_type: {
      name: "company_principal_place_of_business_address_street_type",
      label: "Street Type",
    },
    company_principal_place_of_business_address_country: {
      name: "company_principal_place_of_business_address_country",
      label: "Country",
    },
    company_principal_place_of_business_address_full_address: {
      name: "company_principal_place_of_business_address_full_address",
      type: "address",
      label: "Company Principal Place of Business Address",
    },
    password: {
      name: "password",
      label: "Password",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
    },
    new_cma: {
      name: "new_cma",
      label: "Link your existing Macquarie CMA Account?",
    },
    cma_source_of_funds: {
      name: "cma_source_of_funds",
      label: "Source of Funds",
    },
    cma_source_of_funds_desc: {
      name: "cma_source_of_funds_desc",
      label: "Source of Funds Description",
      dependentShow: {
        cma_source_of_funds: CMA_SOURCE_OF_FUNDS[getEnv()].OTHER,
      },
    },
    cma_account_purpose: {
      name: "cma_account_purpose",
      label: "Account Purpose",
    },
    cma_account_purpose_desc: {
      name: "cma_account_purpose_desc",
      label: "Account Purpose Description",
      dependentShow: {
        cma_account_purpose: CMA_ACCOUNT_PURPOSE.OTHER,
      },
    },
    send_registration_email: {
      name: "send_registration_email",
      label: "Create User Login?",
    },
    organization_code: {
      name: "organization_code",
      label: "Organization Code",
    },
    branch_code: {
      name: "branch_code",
      label: "Branch Code",
    },
    advisor_code: {
      name: "advisor_code",
      label: "Advisor Code",
    },
    branch: {
      name: "branch",
      label: "Vetting Rules Group",
    },
    bank_bsb: {
      name: "bank_bsb",
      dependentShow: {
        use_existing_CMT_acc: true,
      },
      label: "BSB",
    },
    bank_account_name: {
      name: "bank_account_name",
      dependentShow: {
        use_existing_CMT_acc: true,
      },
      label: "Account Name",
    },
    bank_account_number: {
      name: "bank_account_number",
      dependentShow: {
        use_existing_CMT_acc: true,
      },
      label: "Account Number",
    },
    bank_cmt_provider: {
      name: "bank_cmt_provider",
      label: "Bank CMT Provider",
    },
    tradeable_products: {
      name: "tradeable_products",
      equity: {
        name: "tradeable_products.equity",
        originName: "equity",
        label: "Brokerage Schedule",
      },
    },
    settlement_method: {
      name: "settlement_method",
      label:
        "Would you like Morrison Securities to establish a new Sponsored HIN?",
    },
    settlement_existing_hin: {
      name: "settlement_existing_hin",
      dependentShow: {
        settlement_method: false,
      },
      label: "Existing HIN",
    },
    settlement_pid: {
      name: "settlement_pid",
      dependentShow: {
        settlement_method: false,
      },
      label: "Current Broker",
    },
    settlement_supplementary_reference: {
      name: "settlement_supplementary_reference",
      label: "Supplementary Reference",
    },
    trade_confirmations: {
      name: "trade_confirmations",
      email: {
        name: "trade_confirmations.email",
        label: "Email",
        originName: "email",
      },
      client_address: {
        name: "trade_confirmations.client_address",
        label: "I Confirm This Is My Email Address",
        originName: "client_address",
      },
    },
    applicant_details: {
      name: "applicant_details",
      country_of_birth: {
        name: "applicant_details.country_of_birth",
        originName: "country_of_birth",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Country of Birth",
      },
      town_of_birth: {
        name: "applicant_details.town_of_birth",
        originName: "town_of_birth",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Town of Birth",
      },
      tax_identification: {
        name: "applicant_details.tax_identification",
        originName: "tax_identification",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Do you have a Tax Identification Number?",
      },
      no_tin_reason: {
        name: "applicant_details.no_tin_reason",
        originName: "no_tin_reason",
        dependentShow: {
          australian_tax_resident: false,
          tax_identification: true,
        },
        label: "Reason TIN cannot be provided?",
      },
      tin: {
        name: "applicant_details.tin",
        originName: "tin",
        dependentShow: {
          australian_tax_resident: false,
          tax_identification: false,
        },
        label: "Tax Identification Number",
      },
      country_of_tax_residency: {
        name: "applicant_details.country_of_tax_residency",
        originName: "country_of_tax_residency",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Country of Tax Residency",
      },
      applicant_id: {
        name: "applicant_details.applicant_id",
        label: "Applicant ID",
      },
      equity: {
        name: "applicant_details.equity",
        label: "Brokerage Schedule*",
      },
      ekyc_aml_consent: {
        name: "applicant_details.ekyc_aml_consent",
        show: false,
        originName: "ekyc_aml_consent",
        label: "I agree with the eKYC & AML Consent.",
      },
      tos_consent: {
        name: "applicant_details.tos_consent",
        originName: "tos_consent",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      terms_confirm: {
        name: "applicant_details.terms_confirm",
        show: false,
        originName: "terms_confirm",
        label: "I agree with the eKYC & AML Consent.",
      },
      morrison_confirm: {
        name: "applicant_details.morrison_confirm",
        show: false,
        originName: "morrison_confirm",
        label: "I agree with the eKYC & AML Consent.",
      },
      quant_edge_privacy_statement_confirm: {
        name: "applicant_details.quant_edge_privacy_statement_confirm",
        originName: "quant_edge_privacy_statement_confirm",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      macquarie_confirm: {
        name: "applicant_details.macquarie_confirm",
        originName: "macquarie_confirm",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      total_confirm: {
        name: "applicant_details.total_confirm",
        originName: "total_confirm",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      title: {
        name: "applicant_details.title",
        originName: "title",
        label: "Title",
      },
      gender: {
        name: "applicant_details.gender",
        originName: "gender",
        label: "Gender",
      },
      first_name: {
        name: "applicant_details.first_name",
        originName: "first_name",
        label: "First Name (e.g. John)",
        ext: "First Name",
      },
      middle_name: {
        name: "applicant_details.middle_name",
        originName: "middle_name",
        label: "Middle Name (e.g. Peter Leslie)",
        ext: "Middle Name",
      },
      last_name: {
        name: "applicant_details.last_name",
        originName: "last_name",
        label: "Last Name (e.g. Smith)",
        ext: "Last Name",
      },
      role_in_company: {
        name: "applicant_details.role_in_company",
        originName: "role_in_company",
        label: "Role in Company",
      },
      dob: {
        name: "applicant_details.dob",
        originName: "dob",
        type: "date",
        format: "DD/MM/YYYY",
        label: "Date of Birth",
      },
      relationship_type: {
        name: "applicant_details.relationship_type",
        label: "Relationship to The Applicant*",
      },
      nationality: {
        name: "applicant_details.nationality",
        originName: "nationality",
        label: "Country of Citizenship",
      },
      occupation_type: {
        name: "applicant_details.occupation_type",
        originName: "occupation_type",
        label: "Occupation Type",
      },
      occupation_category: {
        name: "applicant_details.occupation_category",
        originName: "occupation_category",
        label: "Occupation Category",
      },
      business_owner_trading_name: {
        name: "applicant_details.business_owner_trading_name",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_name",
        label: "Trading Name of The Business You Own",
      },
      abn_acn_registration_number: {
        name: "applicant_details.abn_acn_registraxion_number",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "abn_acn_registration_number",
        label: "ABN/ACN/Registration Number of The Business",
      },
      liquor_gambling_licence: {
        name: "applicant_details.liquor_gambling_licence",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
          occupation_category: [
            "Hotel and Motel",
            "Accommodation and Food Services",
          ],
        },
        originName: "liquor_gambling_licence",
        label: "Do you hold a liquor and/or gambling licence?",
      },
      if_business_is_regulated_charity: {
        name: "applicant_details.if_business_is_regulated_charity",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
          occupation_category: "Charity Community or Social Services",
        },
        originName: "if_business_is_regulated_charity",
        label: "If business is a regulated charity?",
      },
      own_cafe_restaurant: {
        name: "applicant_details.own_cafe_restaurant",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
          occupation_category: "Cafe and Restaurant",
        },
        originName: "own_cafe_restaurant",
        label: "Do you own a Café or a Restaurant?",
      },
      business_owner_trading_address_manual_address: {
        name: "applicant_details.business_owner_trading_address_manual_address",
        originName: "business_owner_trading_address_manual_address",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
      },
      business_owner_trading_address_street_number: {
        name: "applicant_details.business_owner_trading_address_street_number",
        label: "Street Number",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_street_number",
        invalidErrorMsg: "Street Number is invalid (e.g. 357)",
      },
      business_owner_trading_address_unit_flat_number: {
        name: "applicant_details.business_owner_trading_address_unit_flat_number",
        label: "Unit/Flat",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_unit_flat_number",
        invalidErrorMsg: "Unit Flat Number is invalid (e.g. 357)",
      },
      business_owner_trading_address_postcode: {
        name: "applicant_details.business_owner_trading_address_postcode",
        label: "Postcode",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_postcode",
        invalidErrorMsg: "Postcode is invalid (e.g. 357)",
      },
      business_owner_trading_address_address_line_1: {
        name: "applicant_details.business_owner_trading_address_address_line_1",
        label: "Address Line 1",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_address_line_1",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      business_owner_trading_address_address_line_2: {
        name: "applicant_details.business_owner_trading_address_address_line_2",
        label: "Address Line 1",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_address_line_2",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      business_owner_trading_address_state: {
        name: "applicant_details.business_owner_trading_address_state",
        label: "State",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_state",
        invalidErrorMsg: "State is invalid (e.g. 357)",
      },
      business_owner_trading_address_street_name: {
        name: "applicant_details.business_owner_trading_address_street_name",
        label: "Street Name",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_street_name",
        invalidErrorMsg: "Street Name is invalid (e.g. 357)",
      },
      business_owner_trading_address_city_suburb: {
        name: "applicant_details.business_owner_trading_address_city_suburb",
        label: "Suburb",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_city_suburb",
        invalidErrorMsg: "Suburb is invalid (e.g. 357)",
      },
      business_owner_trading_address_street_type: {
        name: "applicant_details.business_owner_trading_address_street_type",
        label: "Street Type",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_street_type",
        invalidErrorMsg: "Street Type is invalid (e.g. 357)",
      },
      business_owner_trading_address_country: {
        name: "applicant_details.business_owner_trading_address_country",
        originName: "business_owner_trading_address_country",
        label: "Country",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        invalidErrorMsg: "Country is invalid (e.g. 357)",
      },
      business_owner_trading_address_full_address: {
        name: "applicant_details.business_owner_trading_address_full_address",
        originName: "business_owner_trading_address_full_address",
        type: "address",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        label: "Business Address",
        invalidErrorMsg: "Full Address is invalid (e.g. 357)",
      },
      source_of_wealth: {
        name: "applicant_details.source_of_wealth",
        originName: "source_of_wealth",
        label: "Source of Wealth",
      },
      subclass_visa: {
        name: "applicant_details.subclass_visa",
        originName: "subclass_visa",
        dependentShow: {
          source_of_wealth: SOURCE_OF_WEALTH.FOREIGN_INCOME,
        },
        label:
          "Do you hold a provisional subclass 188 or permanent subclass 888 visa?",
      },
      australian_tax_resident: {
        name: "applicant_details.australian_tax_resident",
        originName: "australian_tax_resident",
        label: "Are you a resident of Australia for Tax purposes?",
      },
      tfn: {
        name: "applicant_details.tfn",
        originName: "tfn",
        dependentShow: {
          australian_tax_resident: true,
        },
        label: "Tax File Number",
      },
      tax_exemption: {
        name: "applicant_details.tax_exemption",
        originName: "tax_exemption",
        dependentShow: {
          australian_tax_resident: true,
        },
        label: "Tax Exemption",
      },
      tax_exemption_details: {
        name: "applicant_details.tax_exemption_details",
        originName: "tax_exemption_details",
        dependentShow: {
          tax_exemption: true,
          australian_tax_resident: true,
        },
        label: "Tax Exemption Details",
      },
      government_id: {
        name: "government_id",
        type: {
          name: "applicant_details.government_id.type",
          originName: "type",
          label: "ID Type",
        },
        state_of_issue: {
          name: "applicant_details.government_id.state_of_issue",
          originName: "state_of_issue",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.DRIVER_LICENSE,
          },
          label: "State of Issue",
        },
        number: {
          name: "applicant_details.government_id.number",
          dependentLabel: "applicant_details.government_id.type",
          originName: "number",
          label: {
            [GOVERNMENT_ID_TYPE.DRIVER_LICENSE]: "Drivers License No.",
            [GOVERNMENT_ID_TYPE.PASSPORT]: "Passport No.",
            [GOVERNMENT_ID_TYPE.MEDICARE_CARD]: "Medicare Card No.",
          },
        },
        card_number: {
          name: "applicant_details.government_id.card_number",
          originName: "card_number",
          label: "Drivers License Card No.",
        },
        medicare_card_expiry_date: {
          name: "applicant_details.government_id.medicare_card_expiry_date",
          originName: "medicare_card_expiry_date",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          type: "date",
          format: "MM/YYYY",
          label: "Expiry Date",
        },
        medicare_card_colour: {
          name: "applicant_details.government_id.medicare_card_colour",
          originName: "medicare_card_colour",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          label: "Card Color",
        },
        medicare_name_on_card: {
          name: "applicant_details.government_id.medicare_name_on_card",
          originName: "medicare_name_on_card",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          label: "Name on Card",
        },
        medicare_individual_reference_number: {
          name: "applicant_details.government_id.medicare_individual_reference_number",
          originName: "medicare_individual_reference_number",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          label: "Individual Number",
        },
      },
      residential_address_manual_address: {
        name: "applicant_details.residential_address_manual_address",
        originName: "residential_address_manual_address",
      },
      residential_address_street_number: {
        name: "applicant_details.residential_address_street_number",
        label: "Street Number",
        originName: "residential_address_street_number",
        invalidErrorMsg: "Street Number is invalid (e.g. 357)",
      },
      residential_address_unit_flat_number: {
        name: "applicant_details.residential_address_unit_flat_number",
        label: "Unit/Flat",
        originName: "residential_address_unit_flat_number",
        invalidErrorMsg: "Unit Flat Number is invalid (e.g. 357)",
      },
      residential_address_postcode: {
        name: "applicant_details.residential_address_postcode",
        label: "Postcode",
        originName: "residential_address_postcode",
        invalidErrorMsg: "Postcode is invalid (e.g. 357)",
      },
      residential_address_address_line_1: {
        name: "applicant_details.residential_address_address_line_1",
        label: "Address Line 1",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      residential_address_address_line_2: {
        name: "applicant_details.residential_address_address_line_2",
        label: "Address Line 1",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      residential_address_state: {
        name: "applicant_details.residential_address_state",
        label: "State",
        originName: "residential_address_state",
        invalidErrorMsg: "State is invalid (e.g. 357)",
      },
      residential_address_street_name: {
        name: "applicant_details.residential_address_street_name",
        label: "Street Name",
        originName: "residential_address_street_name",
        invalidErrorMsg: "Street Name is invalid (e.g. 357)",
      },
      residential_address_city_suburb: {
        name: "applicant_details.residential_address_city_suburb",
        label: "Suburb",
        originName: "residential_address_city_suburb",
        invalidErrorMsg: "Suburb is invalid (e.g. 357)",
      },
      residential_address_street_type: {
        name: "applicant_details.residential_address_street_type",
        label: "Street Type",
        originName: "residential_address_street_type",
        invalidErrorMsg: "Street Type is invalid (e.g. 357)",
      },
      residential_address_country: {
        name: "applicant_details.residential_address_country",
        originName: "residential_address_country",
        label: "Country",
        invalidErrorMsg: "Country is invalid (e.g. 357)",
      },
      residential_address_full_address: {
        name: "applicant_details.residential_address_full_address",
        originName: "residential_address_full_address",
        type: "address",
        label: "Residential Address",
        invalidErrorMsg: "Full Address is invalid (e.g. 357)",
      },
      applicant_mobile_phone: {
        name: "applicant_details.applicant_mobile_phone",
        originName: "applicant_mobile_phone",
        type: "phone",
        label: "Mobile Phone",
        invalidErrorMsg: "Mobile Phone is invalid (e.g. 357)",
      },
      applicant_email: {
        name: "applicant_details.applicant_email",
        originName: "applicant_email",
        label: "Email",
        invalidErrorMsg: "Email is invalid",
      },
      client_address: {
        name: "applicant_details.client_address",
        label: "I Confirm This Is My Email Address",
        originName: "client_address",
      },
    },
  },
};

export default fromModels;
