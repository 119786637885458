import React from "react";
import MaterialLayout from "./containers/Layout/MaterialLayout";
import ChooseDraft from "./containers/PageApplication";
import OnboardingPage from "./containers/PageOnboarding/Forms/OnboardingPage";
import dataStorage from "@s/dataStorage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  getDraft,
  getDraftOfRetailByOperator,
  addEventListenerWindow,
} from "@s/helper/utils";
import { verifyOTP, verifyOTPApplicantOther } from "./api";
import { postData } from "./api/request";

function App() {
  const UrlParams = new URLSearchParams(window.location.search);
  const email = UrlParams.get("email");
  const otp = UrlParams.get("otp");
  const accessToken = UrlParams.get("accessToken");
  const draftId = UrlParams.get("draft_id");
  email && (dataStorage.registerEmail = email);
  const [loading, setLoading] = React.useState(
    !!(email && (otp || accessToken))
  );
  const equixId = UrlParams.get("equix_id");
  equixId && (dataStorage.equixId = equixId);
  const applicantId = UrlParams.get("applicantId");
  dataStorage.applicantId = applicantId;
  accessToken && (dataStorage.accessToken = accessToken);
  // const { t } = useTranslation();
  // const changeLang = React.useRef(false)
  // const [changelang, setchangelang] = React.useState(false);

  React.useEffect(() => {
    addEventListenerWindow();
    if (dataStorage.accessToken && draftId) {
      let suffix = "retail";
      if ((equixId + "").toUpperCase().endsWith("A")) {
        dataStorage.userType = 1; // broker, operator
        suffix = "broker";
      }
      dataStorage.isOperatorSupport = true; // broker, operator
      getDraftOfRetailByOperator(draftId, dataStorage.registerEmail)
        .then(() => {
          setLoading(false);
          window.history.replaceState(
            "",
            "",
            `${window.location.origin}/${dataStorage.env}/${suffix}`
          );
        })
        .catch(() => {
          setLoading(false);
          window.history.replaceState(
            "",
            "",
            `${window.location.origin}/${dataStorage.env}/${suffix}`
          );
        });
    } else {
      if (equixId) {
        dataStorage.isSubApplicant = true;
        const bodyData = {
          user_login_id: email,
          otp,
        };
        const getUrlOTP = applicantId
          ? verifyOTPApplicantOther(applicantId)
          : verifyOTP();
        postData(getUrlOTP, bodyData)
          .then((res) => {
            const { application_token: token } = res;
            dataStorage.accessToken = token;
            getDraft(`?draft_id=${draftId}`, draftId)
              .then(() => {
                setLoading(false);
                window.history.replaceState(
                  "",
                  "",
                  `${window.location.origin}/${dataStorage.env}`
                );
              })
              .catch(() => {
                setLoading(false);
                window.history.replaceState(
                  "",
                  "",
                  `${window.location.origin}/${dataStorage.env}`
                );
              });
          })
          .catch((error) => {
            setLoading(false);
            window.history.replaceState(
              "",
              "",
              `${window.location.origin}/${dataStorage.env}`
            );
            dataStorage.showError && dataStorage.showError(error);
          });
      } else {
        if (email && otp) {
          const bodyData = {
            user_login_id: email,
            otp,
          };
          postData(verifyOTP(), bodyData)
            .then((res) => {
              const { application_token: token } = res;
              dataStorage.accessToken = token;
              getDraft()
                .then(() => {
                  setLoading(false);
                  window.history.replaceState(
                    "",
                    "",
                    `${window.location.origin}/${dataStorage.env}`
                  );
                })
                .catch(() => {
                  setLoading(false);
                  window.history.replaceState(
                    "",
                    "",
                    `${window.location.origin}/${dataStorage.env}`
                  );
                });
            })
            .catch((error) => {
              setLoading(false);
              window.history.replaceState(
                "",
                "",
                `${window.location.origin}/${dataStorage.env}`
              );
              dataStorage.showError && dataStorage.showError(error);
            });
        }
      }
    }
  }, []);

  if (email && (otp || accessToken)) {
    return (
      <MaterialLayout>
        {loading ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <ChooseDraft already={!!dataStorage.accountType} />
        )}
      </MaterialLayout>
    );
  }
  // const chooseLanguage = () => {
  //   const getLang = i18n.language ? (i18n.language === 'en') : null;
  //   const chooselang = getLang ? !getLang : !changeLang.current;
  //   changeLang.current = chooselang
  //   return i18n.changeLanguage(chooselang ? 'en' : 'vn')
  // }

  return (
    <>
      <MaterialLayout>
        <Router>
          <Switch>
            <Route path={`/${dataStorage.env}/`} component={OnboardingPage} />
          </Switch>
        </Router>
      </MaterialLayout>
    </>
  );
}

export default App;
