import React from "react";
import { at } from "lodash";
import { useField, useFormikContext } from "formik";
// import { TextField } from '@mui/material';
import { TextField } from "@mui/material";

export default function InputField({ ...props }) {
  const { helperText, variant = "outlined", ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, "touched", "error");
  const { setFieldValue } = useFormikContext();
  const [label, setlableField] = React.useState(rest.label);
  function _renderHelperText() {
    if (touched && error) {
      return error;
    } else if (helperText) {
      return helperText;
    }
  }
  React.useEffect(() => {
    field?.value?.length && rest?.ext && setlableField(rest.ext);
  }, []);
  const onChange = (event) => {
    const getValueField = event.target.value;
    setFieldValue(field.name, getValueField);
    // if (rest.type === 'email' && !!error) { // remove old error
    //   setFieldError(field.name, '')
    // }
    // rest.ext && setlableField(rest.ext)
  };
  const onFocus = () => {
    rest?.ext && setlableField(rest.ext);
  };
  const onBlur = (event) => {
    const getValueField = event.target.value;
    helper.setTouched(true);
    setFieldValue(field.name, getValueField, true);
    if (field?.value?.length) {
      rest?.ext && setlableField(rest.ext);
    } else {
      rest?.ext && setlableField(`${rest.label}`);
    }
  };
  return (
    <TextField
      variant={variant}
      type="text"
      error={Boolean(touched && error && true)}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      label={rest?.ext ? label : rest.label}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={field.value || ""}
    />
  );
}
