import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { useFormikContext, FieldArray } from "formik";
import {
  InputField,
  CheckboxField,
  BooleanRadio,
  AutocompleteFieldAsync,
  SelectField,
  FileUpload,
  AutocompleteField,
} from "@Components/CustomFields";
import {
  NATURE_OF_TRUST_ACTIVITY_OPTIONS,
  SOURCE_OF_WEALTH_ENTITY_OPTIONS,
  TAX_EXEMPTION_DETAILS_OPTIONS,
  AUSTRALIAN_TAX_OPTIONS,
  STREET_TYPE,
  STATE_OF_ISSUE_OPTIONS,
  FATCA_STATUS_OPTIONALS,
  FATCA_STATUS,
  TRUST_ACTIVITY,
  RELATIONSHIP_TYPE,
  ACCOUNT_TYPE,
  INDUSTRY_OPTIONS,
} from "@Common/constants";
import formModel from "../FormModels/formModels";
import { getFullAddress } from "../../../../helper/utils";
import LabelText from "@Components/LabelText";
import HeadText from "@Components/HeadText";

const { formField } = formModel;

export default function FundDetails() {
  const { values: formValues, setFieldValue } = useFormikContext();
  const abnRef = React.useRef(formValues.super_fund_abn);
  const smsfRef = React.useRef(formValues.smsf);

  React.useEffect(() => {
    if (smsfRef.current !== formValues.smsf) {
      smsfRef.current = formValues.smsf;
      if (formValues.smsf) {
        if (
          formValues.super_fund_activity?.value !==
          TRUST_ACTIVITY.SUPERANNUATION_PENSION
        )
          setFieldValue(
            formField.super_fund_activity.name,
            TRUST_ACTIVITY.SUPERANNUATION_PENSION
          );
        if (
          formValues.super_fund_industry?.value !==
          "INSURANCE_AND_SUPERANNUATION_FUNDS"
        )
          setFieldValue(
            formField.super_fund_industry.name,
            "INSURANCE_AND_SUPERANNUATION_FUNDS"
          );
      }
    }
  }, [formValues.smsf]);

  React.useEffect(() => {
    if (abnRef.current !== formValues.super_fund_abn) {
      abnRef.current = formValues.super_fund_abn;
      setFieldValue(
        formField.abn_or_registration_licensing_details.name,
        formValues.super_fund_abn
      );
    }
  }, [formValues.super_fund_abn]);
  React.useEffect(() => {
    if (formValues.company_same_as_roa) {
      if (
        formValues.pick_fund_address?.value ===
        "company_principal_place_of_business_address"
      ) {
        setFieldValue(formField.pick_fund_address.name, "");
      }
    }
  }, []);
  const DataAddress = () => {
    try {
      let filterAddressDirector =
        formValues.applicant_details
          ?.filter((e) => {
            const formatKey = Object.keys(e?.role_in_super);
            return formatKey.includes(RELATIONSHIP_TYPE.DIRECTOR);
          })
          ?.map((e) => {
            // eslint-disable-next-line no-self-compare
            const findApplicant = formValues.applicant_details.find(
              (currentV) => currentV.applicant_id === e.applicant_id
            );
            const label = getFullAddress(findApplicant, "residential_address");
            return { label, value: e.applicant_id };
          }) || [];
      const label = getFullAddress(
        formValues,
        "company_registered_office_address"
      );
      filterAddressDirector = [
        ...filterAddressDirector,
        { label, value: "company_registered_office_address" },
      ];
      if (!formValues.company_same_as_roa) {
        const label = getFullAddress(
          formValues,
          "company_principal_place_of_business_address"
        );
        filterAddressDirector = [
          ...filterAddressDirector,
          { label, value: "company_principal_place_of_business_address" },
        ];
      }
      return filterAddressDirector;
    } catch (err) {
      return [];
    }
  };
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <HeadText>Fund Details</HeadText>
      </Grid>
      <Grid item xs={12}>
        <InputField
          required
          name={formField.super_fund_name.name}
          label={formField.super_fund_name.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <BooleanRadio
          name={formField.smsf.name}
          label={formField.smsf.label}
          data={AUSTRALIAN_TAX_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <InputField
          name={formField.super_fund_abn.name}
          label={formField.super_fund_abn.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteField
          disabled={formValues.smsf}
          required
          name={formField.super_fund_industry.name}
          label={formField.super_fund_industry.label}
          data={INDUSTRY_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AutocompleteField
          disabled={formValues.smsf}
          required
          name={formField.super_fund_activity.name}
          label={formField.super_fund_activity.label}
          data={
            NATURE_OF_TRUST_ACTIVITY_OPTIONS[
              formValues.super_fund_industry?.value
            ]
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SelectField
          required
          name={formField.super_fund_source_of_wealth.name}
          label={formField.super_fund_source_of_wealth.label}
          data={SOURCE_OF_WEALTH_ENTITY_OPTIONS}
          fullWidth
        />
      </Grid>
      {formValues.abn_not_available ? (
        <React.Fragment />
      ) : (
        <Grid item xs={12}>
          <InputField
            required
            name={formField.abn_or_registration_licensing_details.name}
            label={formField.abn_or_registration_licensing_details.label}
            helperText={
              "Note: We are unable to finalise the account opening without the regulated Trust's ABN or Registration/Licensing details being provided."
            }
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <CheckboxField
          name={formField.abn_not_available.name}
          condition={(value) => value}
          helpText={
            "Note: You will need to contact Customer Support later to provide your ABN or Registration/Licensing details, in order to complete the account opening process."
          }
          label={formField.abn_not_available.label}
        />
      </Grid>
      <Grid item xs={12}>
        <InputField
          required
          name={formField.name_of_regulator.name}
          label={formField.name_of_regulator.label}
          fullWidth
        />
      </Grid>
      {!formValues.super_fund_address_manual_address &&
      formValues.trustee_type.value === ACCOUNT_TYPE.COMPANY ? (
        <React.Fragment>
          <Grid item xs={12}>
            <CheckboxField
              name={formField.pick_address_director.name}
              label={formField.pick_address_director.label}
            />
          </Grid>
        </React.Fragment>
      ) : null}
      {!formValues.pick_address_director ? (
        <Grid item xs={12} className="addressContainer">
          {formValues.super_fund_address_manual_address ? (
            <React.Fragment />
          ) : (
            <AutocompleteFieldAsync
              required
              name={formField.super_fund_address_full_address.name}
              label={formField.super_fund_address_full_address.label}
              fullWidth
            />
          )}
          <CheckboxField
            name={formField.super_fund_address_manual_address.name}
            label="Cannot find your address? Manual Input"
          />
        </Grid>
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <SelectField
              required
              name={formField.pick_fund_address.name}
              label={formField.pick_fund_address.label}
              data={DataAddress()}
              fullWidth
            />
          </Grid>
        </React.Fragment>
      )}
      {formValues.super_fund_address_manual_address ? (
        <React.Fragment>
          <Grid item xs={6} sm={6}>
            <InputField
              name={formField.super_fund_address_unit_flat_number.name}
              label={formField.super_fund_address_unit_flat_number.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              required
              name={formField.super_fund_address_street_number.name}
              label={formField.super_fund_address_street_number.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              required
              name={formField.super_fund_address_street_name.name}
              label={formField.super_fund_address_street_name.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectField
              required
              name={formField.super_fund_address_street_type.name}
              label={formField.super_fund_address_street_type.label}
              data={STREET_TYPE}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              required
              name={formField.super_fund_address_city_suburb.name}
              label={formField.super_fund_address_city_suburb.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <SelectField
              required
              name={formField.super_fund_address_state.name}
              label={formField.super_fund_address_state.label}
              data={STATE_OF_ISSUE_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <InputField
              required
              name={formField.super_fund_address_postcode.name}
              label={formField.super_fund_address_postcode.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={6}></Grid>
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      <Grid item xs={12}>
        <BooleanRadio
          name={formField.beneficiaries_membership_of_a_class.name}
          label={formField.beneficiaries_membership_of_a_class.label}
          data={AUSTRALIAN_TAX_OPTIONS}
          fullWidth
        />
      </Grid>
      {!formValues.beneficiaries_membership_of_a_class ? (
        <React.Fragment />
      ) : (
        <Grid item xs={12}>
          <InputField
            required
            name={formField.membership_class_details.name}
            label={formField.membership_class_details.label}
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <HeadText>Tax Information</HeadText>
      </Grid>
      <Grid item xs={12}>
        <LabelText style={{ marginBottom: -16 }}>TFN exemption</LabelText>
      </Grid>
      <Grid item xs={12}>
        <BooleanRadio
          tooltip={
            <Typography component="span" variant="body2">
              Some people or organisations are exempt from quoting a TFN in
              relation to their investments. For details to see if this applies
              to you,{" "}
              <Link
                target="_blank"
                href="https://www.ato.gov.au/print-publications/tfn-and-abn-information-for-savings-accounts-and-investments/?page=8"
              >
                see the ATO website.
              </Link>
            </Typography>
          }
          name={formField.super_fund_tax_exemption.name}
          data={AUSTRALIAN_TAX_OPTIONS}
          fullWidth
        />
      </Grid>
      {formValues.super_fund_tax_exemption ? (
        <Grid item xs={12}>
          <SelectField
            required
            name={formField.super_fund_tax_exemption_details.name}
            label={formField.super_fund_tax_exemption_details.label}
            data={TAX_EXEMPTION_DETAILS_OPTIONS}
            fullWidth
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <InputField
            name={formField.super_fund_tfn.name}
            label={formField.super_fund_tfn.label}
            helperText={
              "We ask for your TFN (Tax File Number) so we can make sure you are charged tax at the correct rate. You don't have to provide your TFN, but if you do, it can help ensure you don't pay too much tax."
            }
            fullWidth
          />
        </Grid>
      )}
      {formValues.smsf ? (
        <React.Fragment />
      ) : (
        <React.Fragment>
          <Grid item xs={12}>
            <InputField
              name={formField.global_intermediary_identification_number.name}
              label={formField.global_intermediary_identification_number.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SelectField
              name={formField.fatca_status.name}
              label={formField.fatca_status.label}
              data={FATCA_STATUS_OPTIONALS}
              helperText={
                "Note: If the Trust is a Financial Institution but does not have a Global Intermediary Identification Number (GIIN), please provide the Trust's FATCA status"
              }
              fullWidth
            />
          </Grid>
          {formValues.fatca_status?.value ===
          FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION ? (
            <Grid item xs={12}>
              <InputField
                name={
                  formField.trustee_global_intermediary_identification_number
                    .name
                }
                label={
                  formField.trustee_global_intermediary_identification_number
                    .label
                }
                helperText={
                  "Note: If the Trust is a Trustee-Documented Trust, please provide the Trustee's GIIN"
                }
                fullWidth
              />
            </Grid>
          ) : (
            <React.Fragment />
          )}
          {formValues.fatca_status?.value === FATCA_STATUS.OTHER ? (
            <Grid item xs={12}>
              <InputField
                required
                name={formField.fatca_status_other.name}
                label={formField.fatca_status_other.label}
                fullWidth
              />
            </Grid>
          ) : (
            <React.Fragment />
          )}
        </React.Fragment>
      )}
      <FieldArray
        name={formField.trust_deed.name}
        render={() => (
          <React.Fragment>
            {formValues.trust_deed.map((e, i) => (
              <Grid item xs={12} md={6} key={`fund_FileUpload_${i}`}>
                <FileUpload
                  required
                  fileStorage="super_fund_deed"
                  name={`trust_deed[${i}]`}
                  index={i}
                  label={formField.trust_deed.label}
                />
              </Grid>
            ))}
          </React.Fragment>
        )}
      />
    </Grid>
  );
}
