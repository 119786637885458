import React from "react";
import dataStorage from "../dataStorage";

const Logo = ({ onlyDark }) => {
  return (
    <React.Fragment>
      <img className="logoDark" src={dataStorage.config?.logoDark} alt="Logo" />
      <img
        className="logoLight"
        src={
          onlyDark
            ? dataStorage.config?.logoDark
            : dataStorage.config?.logoLight
        }
        alt="Logo"
      />
    </React.Fragment>
  );
};

export default Logo;
