/* eslint-disable camelcase */
/* eslint-disable multiline-ternary */
import React from "react";
import { Grid, Typography, CardMedia } from "@mui/material";
import { useFormikContext } from "formik";
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  PhoneNumberField,
  MultipleSelectField,
  RadioField,
  BooleanRadio,
} from "@Components/CustomFields";
import {
  AUSTRALIAN_TAX_OPTIONS,
  ACCOUNT_TYPE,
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  SOURCE_OF_WEALTH,
  TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS,
  ROLE_IN_SUPER_OPTIONS,
  RELATIONSHIP_TYPE,
  OWN_CAFE_RESTAURANT_OPTIONS,
} from "@Common/constants";
import { getEnv } from "@s/helper/utils";
import COUNTRIES from "@Common/countries";
import dataStorage from "@s/dataStorage";
import { makeStyles } from "@mui/styles";
import formModel from "../FormModels/formModels";
import HelpText from "@Components/HelpText";
import HeadText from "@Components/HeadText";
import ApplicantNote from "@Components/ApplicantNote";

const { formField } = formModel;

const useStyle = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: "hidden",
    minHeight: "400px",
    display: "flex",
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper,
  },
  SelectionBox: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "transparent",
  },
  cardBox: {
    width: "33%",
    minHeight: "200px",
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  cardBox2: {
    width: "33%",
    minHeight: "200px",
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "cover",
  },
  cardBox3: {
    width: "33%",
    minHeight: "200px",
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: "cover",
  },
  media: {
    marginTop: "15%",
    // height: '60%',
    marginLeft: "30%",
    width: "40% !important",
  },
  buttons: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(0),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
  },
  deleteIcon: {
    display: "none",
  },
  listItem: {
    "&:hover $deleteIcon": {
      display: "block",
    },
    "& .MuiListItemIcon-root": {
      minWidth: 0,
    },
  },
  leftButtons: {
    display: "flex",
    flexDirection: "row",
    "& $wrapper": {
      "& button": {
        marginRight: 16,
      },
      "&:first-child": {
        marginLeft: 0,
        "& button": {
          marginLeft: 0,
        },
      },
    },
  },
  rightButtons: {
    display: "flex",
    flexDirection: "row",
  },
  governmentIdImage: {
    objectFit: "contain",
    maxWidth: 600,
  },
  link: {
    color: theme.palette.info.dark,
  },
  genderContainer: {
    "& .radio-horizontal": {
      display: "none",
    },
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
    [theme.breakpoints.up("xxl")]: {
      "& .radio-vertical": {
        display: "none",
      },
      "& .radio-horizontal": {
        display: "flex",
      },
    },
  },
}));

function ApplicationForm(props) {
  const classes = useStyle();
  const { values: formValues, setFieldValue } = useFormikContext();
  const { index: activeIndex } = props;

  const getMatchYear = (year) => {
    if (year == null) return Date.now();
    const ConfigDate = new Date(Date.now());
    ConfigDate.setFullYear(ConfigDate.getFullYear() - year);
    return ConfigDate;
  };
  React.useEffect(() => {
    if (
      formValues.pick_fund_address?.value ===
      formValues.applicant_details?.[activeIndex]?.applicant_id
    ) {
      const checkValue = formValues.applicant_details[activeIndex];
      const valueSelect = Object.keys(checkValue?.role_in_super || {}) || [];
      if (valueSelect && !valueSelect.includes(RELATIONSHIP_TYPE.DIRECTOR)) {
        valueSelect.length &&
          setFieldValue(formField.pick_fund_address.name, "");
      }
    }
  }, [formValues.applicant_details?.[activeIndex]?.role_in_super]);

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        {formValues.trustee_type?.value === ACCOUNT_TYPE.INDIVIDUAL ? (
          <React.Fragment>
            <Grid item xs={12}>
              <RadioField
                required
                isHorizontal
                name={`applicant_details[${activeIndex}].role_in_super_individual`}
                label={
                  formField.applicant_details.role_in_super_individual.label
                }
                data={ROLE_IN_SUPER_OPTIONS.INDIVIDUAL}
                fullWidth
              />
            </Grid>
            {formValues.applicant_details[activeIndex].role_in_super_individual
              ?.value === "OTHER" ? (
              <Grid item xs={12}>
                <InputField
                  required
                  name={`applicant_details[${activeIndex}].role_in_super_other`}
                  label={formField.applicant_details.role_in_super_other.label}
                  fullWidth
                />
              </Grid>
            ) : (
              <React.Fragment />
            )}
          </React.Fragment>
        ) : (
          <Grid item xs={12}>
            <MultipleSelectField
              required
              name={`applicant_details[${activeIndex}].role_in_super`}
              label={formField.applicant_details.role_in_super.label}
              data={ROLE_IN_SUPER_OPTIONS.COMPANY}
              fullWidth
            />
          </Grid>
        )}
        <ApplicantNote />
        <Grid item xs={12}>
          <AutocompleteField
            required
            name={`applicant_details[${activeIndex}].title`}
            label={formField.applicant_details.title.label}
            data={TITLE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            required
            name={`applicant_details[${activeIndex}].first_name`}
            label={formField.applicant_details.first_name.label}
            ext={formField.applicant_details.first_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            name={`applicant_details[${activeIndex}].middle_name`}
            label={formField.applicant_details.middle_name.label}
            ext={formField.applicant_details.middle_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            required
            name={`applicant_details[${activeIndex}].last_name`}
            label={formField.applicant_details.last_name.label}
            ext={formField.applicant_details.last_name?.ext}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 4 }}>
          <Typography variant="body2" className="textStatic">
            Full Name Example: John Peter Leslie Smith
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <DatePickerField
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            inputFormat="dd/MM/yyyy"
            minDate={new Date("1920/1/1")}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <AutocompleteField
            disabled
            required
            helperText="Note: Only Australian Citizens accepted"
            name={`applicant_details[${activeIndex}].nationality`}
            label={formField.applicant_details.nationality.label}
            data={COUNTRIES}
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xxl={3}
          className={classes.genderContainer}
        >
          <RadioField
            required
            name={`applicant_details[${activeIndex}].gender`}
            label={formField.applicant_details.gender.label}
            data={GENDER_OPTIONS}
            fullWidth
          />
          <RadioField
            isHorizontal
            required
            name={`applicant_details[${activeIndex}].gender`}
            label={formField.applicant_details.gender.label}
            data={GENDER_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <PhoneNumberField
            required
            prefix="04"
            name={`applicant_details[${activeIndex}].applicant_mobile_phone`}
            label={formField.applicant_details.applicant_mobile_phone.label}
            fullWidth
          />
        </Grid>
        {renderManualAddress("residential_address")}
        <React.Fragment>
          <Grid item xs={12} sm={6}>
            <InputField
              required
              disabled={activeIndex === 0 || dataStorage.isOperatorSupport}
              name={`applicant_details[${activeIndex}].applicant_email`}
              label={formField.applicant_details.applicant_email.label}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </React.Fragment>
      </React.Fragment>
    );
  };

  const renderVerification = () => {
    let imagePath = "";
    if (
      formValues.applicant_details[activeIndex]?.government_id?.type?.value ===
      GOVERNMENT_ID_TYPE.DRIVER_LICENSE
    ) {
      if (
        formValues.applicant_details[activeIndex].government_id?.state_of_issue
          ?.value
      ) {
        imagePath = `/images/${formValues.applicant_details[activeIndex].government_id.type?.value}_${formValues.applicant_details[activeIndex].government_id?.state_of_issue?.value}.svg`;
      }
    } else if (
      formValues.applicant_details[activeIndex]?.government_id?.type?.value ===
      GOVERNMENT_ID_TYPE.PASSPORT
    ) {
      imagePath = "/images/passport.svg";
    }
    return (
      <React.Fragment>
        <Grid item container spacing={3} xs={12} sm={6}>
          <Grid item xs={12}>
            <RadioField
              required
              name={`applicant_details[${activeIndex}].government_id.type`}
              label={formField.applicant_details.government_id.type.label}
              data={GOVERNMENT_ID_TYPE_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid style={{ margin: 16 }}></Grid>
          {formValues.applicant_details[activeIndex]?.government_id?.type
            ?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE ? (
            <Grid item xs={12}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
                label={
                  formField.applicant_details.government_id.state_of_issue.label
                }
                data={STATE_OF_ISSUE_OPTIONS}
                fullWidth
              />
            </Grid>
          ) : (
            <React.Fragment />
          )}
          <Grid item xs={12}>
            <InputField
              required
              name={`applicant_details[${activeIndex}].government_id.number`}
              label={
                formField.applicant_details.government_id.number.label[
                  formValues.applicant_details[activeIndex]?.government_id?.type
                    ?.value
                ]
              }
              fullWidth
            />
          </Grid>
          {formValues.applicant_details[activeIndex]?.government_id?.type
            ?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE ? (
            <Grid item xs={12}>
              <InputField
                inputProps={{
                  maxLength: 10,
                }}
                required
                name={`applicant_details[${activeIndex}].government_id.card_number`}
                label={
                  formField.applicant_details.government_id.card_number.label
                }
                fullWidth
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item container spacing={0} xs={12} sm={6}>
          <Grid item xs={12}>
            {imagePath ? (
              <CardMedia
                className={classes.governmentIdImage}
                component="img"
                alt="Id Type Image"
                image={imagePath}
              />
            ) : (
              <React.Fragment />
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderManualAddress = (nameField) => {
    if (!nameField) return <></>;
    return (
      <React.Fragment>
        <Grid item xs={12} className="addressContainer">
          {formValues.applicant_details[activeIndex][
            `${nameField}_manual_address`
          ] ? (
            <React.Fragment />
          ) : (
            <AutocompleteFieldAsync
              required
              name={`applicant_details[${activeIndex}][${nameField}_full_address]`}
              label={
                formField.applicant_details[`${nameField}_full_address`].label
              }
              fullWidth
            />
          )}
          <CheckboxField
            name={`applicant_details[${activeIndex}][${nameField}_manual_address]`}
            label="Cannot find your address? Manual Input"
          />
        </Grid>
        {formValues.applicant_details[activeIndex][
          `${nameField}_manual_address`
        ] ? (
          <>
            <Grid item xs={6} sm={6}>
              <InputField
                name={`applicant_details[${activeIndex}].${nameField}_unit_flat_number`}
                label={
                  formField.applicant_details[nameField + "_unit_flat_number"]
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_number`}
                label={
                  formField.applicant_details[nameField + "_street_number"]
                    .label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_name`}
                label={
                  formField.applicant_details[nameField + "_street_name"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].${nameField}_street_type`}
                label={
                  formField.applicant_details[nameField + "_street_type"].label
                }
                data={STREET_TYPE}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_city_suburb`}
                label={
                  formField.applicant_details[nameField + "_city_suburb"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField
                required
                name={`applicant_details[${activeIndex}].${nameField}_state`}
                label={formField.applicant_details[nameField + "_state"].label}
                data={STATE_OF_ISSUE_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].${nameField}_postcode`}
                label={
                  formField.applicant_details[nameField + "_postcode"].label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    );
  };

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        {formValues.applicant_details[activeIndex].australian_tax_resident ? (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                required
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={
                  formField.applicant_details.australian_tax_resident.label
                }
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                name={`applicant_details[${activeIndex}].tax_exemption`}
                label={formField.applicant_details.tax_exemption.label}
                data={AUSTRALIAN_TAX_OPTIONS}
              />
            </Grid>
            {formValues.applicant_details[activeIndex].tax_exemption ? (
              <Grid item xs={12} sm={12}>
                <SelectField
                  required
                  name={`applicant_details[${activeIndex}].tax_exemption_details`}
                  label={
                    formField.applicant_details.tax_exemption_details.label
                  }
                  data={TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS}
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={12}>
                <InputField
                  name={`applicant_details[${activeIndex}].tfn`}
                  label={formField.applicant_details.tfn.label}
                  helperText={
                    "Note: Quotation of your Australian Tax File Number(s) (TFN) is optional"
                  }
                  fullWidth
                />
              </Grid>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                required
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={
                  formField.applicant_details.australian_tax_resident.label
                }
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} sm={12}>
          <SelectField
            required
            name={`applicant_details[${activeIndex}].source_of_wealth`}
            label={formField.applicant_details.source_of_wealth.label}
            data={SOURCE_OF_WEALTH_OPTIONS}
            fullWidth
          />
        </Grid>
        {formValues.applicant_details[activeIndex].source_of_wealth?.value ===
        SOURCE_OF_WEALTH.FOREIGN_INCOME ? (
          <Grid item xs={12} sm={12}>
            <BooleanRadio
              name={`applicant_details[${activeIndex}].subclass_visa`}
              label={formField.applicant_details.subclass_visa.label}
              data={AUSTRALIAN_TAX_OPTIONS}
              fullWidth
            />
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={12}>
          <SelectField
            required
            name={`applicant_details[${activeIndex}].occupation_type`}
            label={formField.applicant_details.occupation_type.label}
            data={OCCUPATION_TYPE_OPTIONS}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField
            required
            name={`applicant_details[${activeIndex}].occupation_category`}
            label={formField.applicant_details.occupation_category.label}
            data={
              OCCUPATION_CATEGORY_OPTIONS[
                formValues.applicant_details[activeIndex].occupation_type?.value
              ]
            }
            fullWidth
          />
        </Grid>
        {formValues.applicant_details[activeIndex].occupation_type?.value ===
        OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER ? (
          <React.Fragment>
            {["Hotel and Motel", "Accommodation and Food Services"].includes(
              formValues.applicant_details[activeIndex]?.occupation_category
                .value
            ) ? (
              <>
                <Grid item xs={12} sm={12}>
                  <BooleanRadio
                    required
                    name={`applicant_details[${activeIndex}].liquor_gambling_licence`}
                    label={
                      formField.applicant_details.liquor_gambling_licence.label
                    }
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}

            {formValues.applicant_details[activeIndex]?.occupation_category
              .value === "Charity Community or Social Services" ? (
              <>
                <Grid item xs={12} sm={12}>
                  <BooleanRadio
                    required
                    name={`applicant_details[${activeIndex}].if_business_is_regulated_charity`}
                    label={
                      formField.applicant_details
                        .if_business_is_regulated_charity.label
                    }
                    data={AUSTRALIAN_TAX_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}

            {formValues.applicant_details[activeIndex]?.occupation_category
              .value === "Cafe and Restaurant" ? (
              <>
                <Grid item xs={12}>
                  <SelectField
                    required
                    name={`applicant_details[${activeIndex}].own_cafe_restaurant`}
                    label={
                      formField.applicant_details.own_cafe_restaurant.label
                    }
                    data={OWN_CAFE_RESTAURANT_OPTIONS}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} sm={12}>
              <InputField
                required
                name={`applicant_details[${activeIndex}].business_owner_trading_name`}
                label={
                  formField.applicant_details.business_owner_trading_name.label
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputField
                type="number"
                name={`applicant_details[${activeIndex}].abn_acn_registration_number`}
                label={
                  formField.applicant_details.abn_acn_registration_number.label
                }
                fullWidth
              />
            </Grid>
            {renderManualAddress("business_owner_trading_address")}
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  };

  return (
    <Grid container spacing={3} key={`fund_applicant_${activeIndex}`}>
      <Grid item xs={12}>
        <HeadText>Personal details</HeadText>
      </Grid>
      <Grid item xs={12}>
        {formValues?.trustee_type?.value === "INDIVIDUAL" ? (
          <HelpText>
            Each member must have their details provided. If there is more than
            two members please click “ADD APPLICANT” and complete the additional
            members information.
          </HelpText>
        ) : (
          <HelpText>
            Each trustee must have their details provided. If there is more than
            one Trustee / Member please click &quot;ADD APPLICANT&quot; to enter
            their details.
          </HelpText>
        )}
      </Grid>
      {renderPersonalInfo()}
      {renderVerification()}
      {renderFinancialInfo()}
    </Grid>
  );
}

export default ApplicationForm;
