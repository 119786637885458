import {
  GOVERNMENT_ID_TYPE,
  CMA_ACCOUNT_PURPOSE,
  CMA_SOURCE_OF_FUNDS,
  OCCUPATION_TYPE,
  ACCOUNT_TYPE,
  FATCA_STATUS,
  SOURCE_OF_WEALTH,
} from "@Common/constants";
import { getEnv } from "../../../../helper/utils";

const fromModels = {
  formId: "fundForm",
  formField: {
    smsf: {
      name: "smsf",
      label: "Is this super fund a Self Managed Super Fund (SMSF)?",
    },
    fatca_status: {
      name: "fatca_status",
      label: "Trust's FATCA Status",
      dependentShow: {
        smsf: false,
      },
    },
    fatca_status_other: {
      name: "fatca_status_other",
      label: "Trust's FATCA Description",
      dependentShow: {
        smsf: false,
        fatca_status: FATCA_STATUS.OTHER,
      },
    },
    name_of_regulator: {
      name: "name_of_regulator",
      label: "Name of Regulator",
    },
    abn_or_registration_licensing_details: {
      name: "abn_or_registration_licensing_details",
      label: "ABN or Registration/Licensing details",
    },
    abn_not_available: {
      name: "abn_not_available",
      label:
        "ABN or Registration/Licensing details applied for but not yet available",
    },
    global_intermediary_identification_number: {
      name: "global_intermediary_identification_number",
      label: "Trust's Global Intermediary Identification Number (GIIN)",
      dependentShow: {
        smsf: false,
      },
    },
    trustee_global_intermediary_identification_number: {
      name: "trustee_global_intermediary_identification_number",
      label: "Trustee's GIIN",
      dependentShow: {
        smsf: false,
        fatca_status: FATCA_STATUS.NON_REPORTING_IGA_FINANCIAL_INSTITUTION,
      },
    },
    applicant: {
      name: "applicant",
      label: "Applicant",
    },
    mailing_address_manual_address: {
      name: "mailing_address_manual_address",
      dependentShow: {
        applicant: "OTHER",
      },
    },
    mailing_address_street_number: {
      name: "mailing_address_street_number",
      label: "Street Number",
    },
    mailing_address_unit_flat_number: {
      name: "mailing_address_unit_flat_number",
      label: "Unit/Flat",
    },
    mailing_address_postcode: {
      name: "mailing_address_postcode",
      label: "Postcode",
    },
    mailing_address_address_line_1: {
      name: "mailing_address_address_line_1",
      label: "Address Line 1",
    },
    mailing_address_address_line_2: {
      name: "mailing_address_address_line_2",
      label: "Address Line 1",
    },
    mailing_address_state: {
      name: "mailing_address_state",
      label: "State",
    },
    mailing_address_street_name: {
      name: "mailing_address_street_name",
      label: "Street Name",
    },
    mailing_address_city_suburb: {
      name: "mailing_address_city_suburb",
      label: "Suburb",
    },
    mailing_address_street_type: {
      name: "mailing_address_street_type",
      label: "Street Type",
    },
    mailing_address_country: {
      name: "mailing_address_country",
      label: "Country",
    },
    mailing_address_full_address: {
      name: "mailing_address_full_address",
      type: "address",
      label: "Mailing Address",
    },
    account_designation: {
      name: "account_designation",
      label: "Account Designation",
    },
    super_fund_address_manual_address: {
      name: "super_fund_address_manual_address",
    },
    pick_address_director: {
      name: "pick_address_director",
      label:
        "Same as Company Office Address or Address of Applicant with Director Role.",
    },
    super_fund_address_street_number: {
      name: "super_fund_address_street_number",
      label: "Street Number",
    },
    super_fund_address_unit_flat_number: {
      name: "super_fund_address_unit_flat_number",
      label: "Unit/Flat",
    },
    super_fund_address_postcode: {
      name: "super_fund_address_postcode",
      label: "Postcode",
    },
    super_fund_address_address_line_1: {
      name: "super_fund_address_address_line_1",
      label: "Address Line 1",
    },
    super_fund_address_address_line_2: {
      name: "super_fund_address_address_line_2",
      label: "Address Line 1",
    },
    super_fund_address_state: {
      name: "super_fund_address_state",
      label: "State",
    },
    super_fund_address_street_name: {
      name: "super_fund_address_street_name",
      label: "Street Name",
    },
    super_fund_address_city_suburb: {
      name: "super_fund_address_city_suburb",
      label: "Suburb",
    },
    super_fund_address_street_type: {
      name: "super_fund_address_street_type",
      label: "Street Type",
    },
    super_fund_address_country: {
      name: "super_fund_address_country",
      label: "Country",
    },
    super_fund_address_full_address: {
      name: "super_fund_address_full_address",
      type: "address",
      label: "Fund Address",
    },
    pick_fund_address: {
      name: "pick_fund_address",
      type: "address",
      label: "Fund Address",
    },
    company_registered_office_address_manual_address: {
      name: "company_registered_office_address_manual_address",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
    },
    company_principal_place_of_business_address_manual_address: {
      name: "company_principal_place_of_business_address_manual_address",
      dependentShow: {
        company_same_as_roa: false,
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
    },
    account_type: {
      name: "account_type",
      label: "Account Type",
    },
    trustee_type: {
      name: "trustee_type",
      label: "Trustee Type",
    },
    trust_deed: {
      name: "trust_deed",
      label: "Fund Deed",
    },
    super_fund_name: {
      name: "super_fund_name",
      label: "Full Name of Fund",
    },
    super_fund_type: {
      name: "super_fund_type",
      label: "Type of Trust",
    },
    super_fund_abn: {
      name: "super_fund_abn",
      label: "Australian Business Number (ABN)",
    },
    super_fund_tfn: {
      name: "super_fund_tfn",
      label: "Tax File Number (TFN)",
      dependentShow: {
        super_fund_tax_exemption: false,
      },
    },
    super_fund_tax_exemption: {
      name: "super_fund_tax_exemption",
      label: "TFN Exemption",
    },
    super_fund_tax_exemption_details: {
      name: "super_fund_tax_exemption_details",
      label: "Tax Exemption Details",
      dependentShow: {
        super_fund_tax_exemption: true,
      },
    },
    super_fund_activity: {
      name: "super_fund_activity",
      label: "Nature of Trust Activity",
    },
    super_fund_source_of_wealth: {
      name: "super_fund_source_of_wealth",
      label: "How has this entity mainly accumulated its wealth?",
    },
    company_name: {
      name: "company_name",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Full Name of Company",
    },
    company_type: {
      name: "company_type",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Company Type",
    },
    company_industry: {
      name: "company_industry",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Industry in which the company operates",
    },
    company_nature_of_business_activity: {
      name: "company_nature_of_business_activity",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Nature of the business activity",
    },
    company_source_of_wealth: {
      name: "company_source_of_wealth",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Source of wealth",
    },
    company_sector: {
      name: "company_sector",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Company Sector",
    },
    company_acn: {
      name: "company_acn",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Australian Company Number (ACN)",
    },
    company_abn: {
      name: "company_abn",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Australian Business Number (ABN)",
    },
    super_fund_industry: {
      name: "super_fund_industry",
      label: "Industry in which trust operates",
    },
    beneficiaries_membership_of_a_class: {
      name: "beneficiaries_membership_of_a_class",
      label:
        "Do the terms of the Trust Deed Identify beneficiaries by reference to membership of a class?",
    },
    membership_class_details: {
      name: "membership_class_details",
      dependentShow: {
        beneficiaries_membership_of_a_class: true,
      },
      label: "Details of membership class(es)",
    },
    company_tfn: {
      name: "company_tfn",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Tax File Number (TFN)",
    },
    company_tax_exemption: {
      name: "company_tax_exemption",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "TFN Exemption",
    },
    company_tax_exemption_details: {
      name: "company_tax_exemption_details",
      label: "Tax Exemption Details",
      dependentShow: {
        company_tax_exemption: true,
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
    },
    company_date_of_incorporation: {
      name: "company_date_of_incorporation",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Date of Registration",
    },
    company_country_of_incorporation: {
      name: "company_country_of_incorporation",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Company Country of Incorporation",
    },
    company_work_phone: {
      name: "company_work_phone",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Company Landline",
    },
    company_mobile_phone: {
      name: "company_mobile_phone",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Company Mobile Number",
    },
    company_email: {
      name: "company_email",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Company Email",
    },
    company_registered_office_address_street_number: {
      name: "company_registered_office_address_street_number",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Street Number",
    },
    company_registered_office_address_unit_flat_number: {
      name: "company_registered_office_address_unit_flat_number",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Unit/Flat",
    },
    company_registered_office_address_postcode: {
      name: "company_registered_office_address_postcode",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Postcode",
    },
    company_registered_office_address_address_line_1: {
      name: "company_registered_office_address_address_line_1",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Address Line 1",
    },
    company_registered_office_address_address_line_2: {
      name: "company_registered_office_address_address_line_2",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Address Line 1",
    },
    company_registered_office_address_state: {
      name: "company_registered_office_address_state",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "State",
    },
    company_registered_office_address_street_name: {
      name: "company_registered_office_address_street_name",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Street Name",
    },
    company_registered_office_address_city_suburb: {
      name: "company_registered_office_address_city_suburb",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Suburb",
    },
    company_registered_office_address_street_type: {
      name: "company_registered_office_address_street_type",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Street Type",
    },
    company_registered_office_address_country: {
      name: "company_registered_office_address_country",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Country",
    },
    company_registered_office_address_full_address: {
      name: "company_registered_office_address_full_address",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      type: "address",
      label: "Company Registered Office Address",
    },
    company_same_as_roa: {
      name: "company_same_as_roa",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Same as Registered Office Address",
    },
    company_principal_place_of_business_address_street_number: {
      name: "company_principal_place_of_business_address_street_number",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Street Number",
    },
    company_principal_place_of_business_address_unit_flat_number: {
      name: "company_principal_place_of_business_address_unit_flat_number",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Unit/Flat",
    },
    company_principal_place_of_business_address_postcode: {
      name: "company_principal_place_of_business_address_postcode",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Postcode",
    },
    company_principal_place_of_business_address_address_line_1: {
      name: "company_principal_place_of_business_address_address_line_1",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Address Line 1",
    },
    company_principal_place_of_business_address_address_line_2: {
      name: "company_principal_place_of_business_address_address_line_2",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Address Line 1",
    },
    company_principal_place_of_business_address_state: {
      name: "company_principal_place_of_business_address_state",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "State",
    },
    company_principal_place_of_business_address_street_name: {
      name: "company_principal_place_of_business_address_street_name",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Street Name",
    },
    company_principal_place_of_business_address_city_suburb: {
      name: "company_principal_place_of_business_address_city_suburb",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Suburb",
    },
    company_principal_place_of_business_address_street_type: {
      name: "company_principal_place_of_business_address_street_type",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Street Type",
    },
    company_principal_place_of_business_address_country: {
      name: "company_principal_place_of_business_address_country",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      label: "Country",
    },
    company_principal_place_of_business_address_full_address: {
      name: "company_principal_place_of_business_address_full_address",
      dependentShow: {
        trustee_type: ACCOUNT_TYPE.COMPANY,
      },
      type: "address",
      label: "Company Principal Place of Business Address",
    },
    password: {
      name: "password",
      label: "Password",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
    },
    new_cma: {
      name: "new_cma",
      label: "Link your existing Macquarie CMA Account?",
    },
    cma_source_of_funds: {
      name: "cma_source_of_funds",
      label: "Source of Funds",
    },
    cma_source_of_funds_desc: {
      name: "cma_source_of_funds_desc",
      label: "Source of Funds Description",
      dependentShow: {
        cma_source_of_funds: CMA_SOURCE_OF_FUNDS[getEnv()].OTHER,
      },
    },
    cma_account_purpose: {
      name: "cma_account_purpose",
      label: "Account Purpose",
    },
    cma_account_purpose_desc: {
      name: "cma_account_purpose_desc",
      label: "Account Purpose Description",
      dependentShow: {
        cma_account_purpose: CMA_ACCOUNT_PURPOSE.OTHER,
      },
    },
    send_registration_email: {
      name: "send_registration_email",
      label: "Create User Login?",
    },
    organization_code: {
      name: "organization_code",
      label: "Organization Code",
    },
    branch_code: {
      name: "branch_code",
      label: "Branch Code",
    },
    advisor_code: {
      name: "advisor_code",
      label: "Advisor Code",
    },
    branch: {
      name: "branch",
      label: "Vetting Rules Group",
    },
    bank_bsb: {
      name: "bank_bsb",
      dependentShow: {
        use_existing_CMT_acc: true,
      },
      label: "BSB",
    },
    bank_account_name: {
      name: "bank_account_name",
      dependentShow: {
        use_existing_CMT_acc: true,
      },
      label: "Account Name",
    },
    bank_account_number: {
      name: "bank_account_number",
      dependentShow: {
        use_existing_CMT_acc: true,
      },
      label: "Account Number",
    },
    bank_cmt_provider: {
      name: "bank_cmt_provider",
      label: "Bank CMT Provider",
    },
    tradeable_products: {
      name: "tradeable_products",
      equity: {
        name: "tradeable_products.equity",
        originName: "equity",
        label: "Brokerage Schedule",
      },
    },
    settlement_method: {
      name: "settlement_method",
      label:
        "Would you like Morrison Securities to establish a new Sponsored HIN?",
    },
    settlement_existing_hin: {
      name: "settlement_existing_hin",
      dependentShow: {
        settlement_method: false,
      },
      label: "Existing HIN",
    },
    settlement_pid: {
      name: "settlement_pid",
      dependentShow: {
        settlement_method: false,
      },
      label: "Current Broker",
    },
    settlement_supplementary_reference: {
      name: "settlement_supplementary_reference",
      label: "Supplementary Reference",
    },
    trade_confirmations: {
      name: "trade_confirmations",
      email: {
        name: "trade_confirmations.email",
        label: "Email",
        originName: "email",
      },
      client_address: {
        name: "trade_confirmations.client_address",
        label: "I Confirm This Is My Email Address",
        originName: "client_address",
      },
    },
    applicant_details: {
      name: "applicant_details",
      country_of_birth: {
        name: "applicant_details.country_of_birth",
        originName: "country_of_birth",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Country of Birth",
      },
      town_of_birth: {
        name: "applicant_details.town_of_birth",
        originName: "town_of_birth",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Town of Birth",
      },
      tax_identification: {
        name: "applicant_details.tax_identification",
        originName: "tax_identification",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Do you have a Tax Identification Number?",
      },
      no_tin_reason: {
        name: "applicant_details.no_tin_reason",
        originName: "no_tin_reason",
        dependentShow: {
          australian_tax_resident: false,
          tax_identification: true,
        },
        label: "Reason TIN cannot be provided?",
      },
      tin: {
        name: "applicant_details.tin",
        originName: "tin",
        dependentShow: {
          australian_tax_resident: false,
          tax_identification: false,
        },
        label: "Tax Identification Number",
      },
      country_of_tax_residency: {
        name: "applicant_details.country_of_tax_residency",
        originName: "country_of_tax_residency",
        dependentShow: {
          australian_tax_resident: false,
        },
        label: "Country of Tax Residency",
      },
      applicant_id: {
        name: "applicant_details.applicant_id",
        label: "Applicant ID",
      },
      equity: {
        name: "applicant_details.equity",
        label: "Brokerage Schedule*",
      },
      ekyc_aml_consent: {
        name: "applicant_details.ekyc_aml_consent",
        show: false,
        originName: "ekyc_aml_consent",
        label: "I agree with the eKYC & AML Consent.",
      },
      role_in_super: {
        name: "applicant_details.role_in_super",
        originName: "role_in_super",
        label: "Role in Super",
      },
      role_in_super_individual: {
        name: "applicant_details.role_in_super_individual",
        originName: "role_in_super_individual",
        label: "Role in Super",
      },
      role_in_super_other: {
        name: "applicant_details.role_in_super_other",
        originName: "role_in_super_other",
        label: "If choose Other, please specify",
      },
      tos_consent: {
        name: "applicant_details.tos_consent",
        originName: "tos_consent",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      terms_confirm: {
        name: "applicant_details.terms_confirm",
        show: false,
        originName: "terms_confirm",
        label: "I agree with the eKYC & AML Consent.",
      },
      morrison_confirm: {
        name: "applicant_details.morrison_confirm",
        show: false,
        originName: "morrison_confirm",
        label: "I agree with the eKYC & AML Consent.",
      },
      quant_edge_privacy_statement_confirm: {
        name: "applicant_details.quant_edge_privacy_statement_confirm",
        originName: "quant_edge_privacy_statement_confirm",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      macquarie_confirm: {
        name: "applicant_details.macquarie_confirm",
        originName: "macquarie_confirm",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      total_confirm: {
        name: "applicant_details.total_confirm",
        originName: "total_confirm",
        show: false,
        label: "I agree with the Terms of Services and Privacy Policy.",
      },
      title: {
        name: "applicant_details.title",
        originName: "title",
        label: "Title",
      },
      gender: {
        name: "applicant_details.gender",
        originName: "gender",
        label: "Gender",
      },
      first_name: {
        name: "applicant_details.first_name",
        originName: "first_name",
        label: "First Name (e.g. John)",
        ext: "First Name",
      },
      middle_name: {
        name: "applicant_details.middle_name",
        originName: "middle_name",
        label: "Middle Name (e.g. Peter Leslie)",
        ext: "Middle Name",
      },
      last_name: {
        name: "applicant_details.last_name",
        originName: "last_name",
        label: "Last Name (e.g. Smith)",
        ext: "Last Name",
      },
      role_in_company: {
        name: "applicant_details.role_in_company",
        originName: "role_in_company",
        label: "Role in Company",
      },
      dob: {
        name: "applicant_details.dob",
        originName: "dob",
        type: "date",
        format: "DD/MM/YYYY",
        label: "Date of Birth",
      },
      relationship_type: {
        name: "applicant_details.relationship_type",
        label: "Relationship to The Applicant*",
      },
      nationality: {
        name: "applicant_details.nationality",
        originName: "nationality",
        label: "Country of Citizenship",
      },
      occupation_type: {
        name: "applicant_details.occupation_type",
        originName: "occupation_type",
        label: "Occupation Type",
      },
      occupation_category: {
        name: "applicant_details.occupation_category",
        originName: "occupation_category",
        label: "Occupation Category",
      },
      business_owner_trading_name: {
        name: "applicant_details.business_owner_trading_name",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_name",
        label: "Trading Name of The Business You Own",
      },
      abn_acn_registration_number: {
        name: "applicant_details.abn_acn_registration_number",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "abn_acn_registration_number",
        label: "ABN/ACN/Registration Number of The Business",
      },
      liquor_gambling_licence: {
        name: "applicant_details.liquor_gambling_licence",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
          occupation_category: [
            "Hotel and Motel",
            "Accommodation and Food Services",
          ],
        },
        originName: "liquor_gambling_licence",
        label: "Do you hold a liquor and/or gambling licence?",
      },
      if_business_is_regulated_charity: {
        name: "applicant_details.if_business_is_regulated_charity",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
          occupation_category: "Charity Community or Social Services",
        },
        originName: "if_business_is_regulated_charity",
        label: "If business is a regulated charity?",
      },
      own_cafe_restaurant: {
        name: "applicant_details.own_cafe_restaurant",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
          occupation_category: "Cafe and Restaurant",
        },
        originName: "own_cafe_restaurant",
        label: "Do you own a Café or a Restaurant?",
      },
      business_owner_trading_address_manual_address: {
        name: "applicant_details.business_owner_trading_address_manual_address",
        originName: "business_owner_trading_address_manual_address",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
      },
      business_owner_trading_address_street_number: {
        name: "applicant_details.business_owner_trading_address_street_number",
        label: "Street Number",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_street_number",
        invalidErrorMsg: "Street Number is invalid (e.g. 357)",
      },
      business_owner_trading_address_unit_flat_number: {
        name: "applicant_details.business_owner_trading_address_unit_flat_number",
        label: "Unit/Flat",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_unit_flat_number",
        invalidErrorMsg: "Unit Flat Number is invalid (e.g. 357)",
      },
      business_owner_trading_address_postcode: {
        name: "applicant_details.business_owner_trading_address_postcode",
        label: "Postcode",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_postcode",
        invalidErrorMsg: "Postcode is invalid (e.g. 357)",
      },
      business_owner_trading_address_address_line_1: {
        name: "applicant_details.business_owner_trading_address_address_line_1",
        label: "Address Line 1",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_address_line_1",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      business_owner_trading_address_address_line_2: {
        name: "applicant_details.business_owner_trading_address_address_line_2",
        label: "Address Line 1",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_address_line_2",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      business_owner_trading_address_state: {
        name: "applicant_details.business_owner_trading_address_state",
        label: "State",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_state",
        invalidErrorMsg: "State is invalid (e.g. 357)",
      },
      business_owner_trading_address_street_name: {
        name: "applicant_details.business_owner_trading_address_street_name",
        label: "Street Name",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_street_name",
        invalidErrorMsg: "Street Name is invalid (e.g. 357)",
      },
      business_owner_trading_address_city_suburb: {
        name: "applicant_details.business_owner_trading_address_city_suburb",
        label: "Suburb",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_city_suburb",
        invalidErrorMsg: "Suburb is invalid (e.g. 357)",
      },
      business_owner_trading_address_street_type: {
        name: "applicant_details.business_owner_trading_address_street_type",
        label: "Street Type",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        originName: "business_owner_trading_address_street_type",
        invalidErrorMsg: "Street Type is invalid (e.g. 357)",
      },
      business_owner_trading_address_country: {
        name: "applicant_details.business_owner_trading_address_country",
        originName: "business_owner_trading_address_country",
        label: "Country",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        invalidErrorMsg: "Country is invalid (e.g. 357)",
      },
      business_owner_trading_address_full_address: {
        name: "applicant_details.business_owner_trading_address_full_address",
        originName: "business_owner_trading_address_full_address",
        type: "address",
        dependentShow: {
          occupation_type: OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER,
        },
        label: "Business Address",
        invalidErrorMsg: "Full Address is invalid (e.g. 357)",
      },
      source_of_wealth: {
        name: "applicant_details.source_of_wealth",
        originName: "source_of_wealth",
        label: "Source of Wealth",
      },
      subclass_visa: {
        name: "applicant_details.subclass_visa",
        originName: "subclass_visa",
        dependentShow: {
          source_of_wealth: SOURCE_OF_WEALTH.FOREIGN_INCOME,
        },
        label:
          "Do you hold a provisional subclass 188 or permanent subclass 888 visa?",
      },
      australian_tax_resident: {
        name: "applicant_details.australian_tax_resident",
        originName: "australian_tax_resident",
        label: "Are you a resident of Australia for Tax purposes?",
      },
      tfn: {
        name: "applicant_details.tfn",
        originName: "tfn",
        dependentShow: {
          australian_tax_resident: true,
        },
        label: "Tax File Number",
      },
      tax_exemption: {
        name: "applicant_details.tax_exemption",
        originName: "tax_exemption",
        dependentShow: {
          australian_tax_resident: true,
        },
        label: "Tax Exemption",
      },
      tax_exemption_details: {
        name: "applicant_details.tax_exemption_details",
        originName: "tax_exemption_details",
        dependentShow: {
          tax_exemption: true,
          australian_tax_resident: true,
        },
        label: "Tax Exemption Details",
      },
      government_id: {
        name: "government_id",
        type: {
          name: "applicant_details.government_id.type",
          originName: "type",
          label: "ID Type",
        },
        state_of_issue: {
          name: "applicant_details.government_id.state_of_issue",
          originName: "state_of_issue",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.DRIVER_LICENSE,
          },
          label: "State of Issue",
        },
        number: {
          name: "applicant_details.government_id.number",
          dependentLabel: "applicant_details.government_id.type",
          originName: "number",
          label: {
            [GOVERNMENT_ID_TYPE.DRIVER_LICENSE]: "Drivers License No.",
            [GOVERNMENT_ID_TYPE.PASSPORT]: "Passport No.",
            [GOVERNMENT_ID_TYPE.MEDICARE_CARD]: "Medicare Card No.",
          },
        },
        card_number: {
          name: "applicant_details.government_id.card_number",
          originName: "card_number",
          label: "Drivers License Card No.",
        },
        medicare_card_expiry_date: {
          name: "applicant_details.government_id.medicare_card_expiry_date",
          originName: "medicare_card_expiry_date",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          type: "date",
          format: "MM/YYYY",
          label: "Expiry Date",
        },
        medicare_card_colour: {
          name: "applicant_details.government_id.medicare_card_colour",
          originName: "medicare_card_colour",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          label: "Card Color",
        },
        medicare_name_on_card: {
          name: "applicant_details.government_id.medicare_name_on_card",
          originName: "medicare_name_on_card",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          label: "Name on Card",
        },
        medicare_individual_reference_number: {
          name: "applicant_details.government_id.medicare_individual_reference_number",
          originName: "medicare_individual_reference_number",
          dependentShow: {
            "government_id.type": GOVERNMENT_ID_TYPE.MEDICARE_CARD,
          },
          label: "Individual Number",
        },
      },
      residential_address_manual_address: {
        name: "applicant_details.residential_address_manual_address",
        originName: "residential_address_manual_address",
      },
      residential_address_street_number: {
        name: "applicant_details.residential_address_street_number",
        label: "Street Number",
        originName: "residential_address_street_number",
        invalidErrorMsg: "Street Number is invalid (e.g. 357)",
      },
      residential_address_unit_flat_number: {
        name: "applicant_details.residential_address_unit_flat_number",
        label: "Unit/Flat",
        originName: "residential_address_unit_flat_number",
        invalidErrorMsg: "Unit Flat Number is invalid (e.g. 357)",
      },
      residential_address_postcode: {
        name: "applicant_details.residential_address_postcode",
        label: "Postcode",
        originName: "residential_address_postcode",
        invalidErrorMsg: "Postcode is invalid (e.g. 357)",
      },
      residential_address_address_line_1: {
        name: "applicant_details.residential_address_address_line_1",
        label: "Address Line 1",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      residential_address_address_line_2: {
        name: "applicant_details.residential_address_address_line_2",
        label: "Address Line 1",
        invalidErrorMsg: "Address Line 1 is invalid (e.g. 357)",
      },
      residential_address_state: {
        name: "applicant_details.residential_address_state",
        label: "State",
        originName: "residential_address_state",
        invalidErrorMsg: "State is invalid (e.g. 357)",
      },
      residential_address_street_name: {
        name: "applicant_details.residential_address_street_name",
        label: "Street Name",
        originName: "residential_address_street_name",
        invalidErrorMsg: "Street Name is invalid (e.g. 357)",
      },
      residential_address_city_suburb: {
        name: "applicant_details.residential_address_city_suburb",
        label: "Suburb",
        originName: "residential_address_city_suburb",
        invalidErrorMsg: "Suburb is invalid (e.g. 357)",
      },
      residential_address_street_type: {
        name: "applicant_details.residential_address_street_type",
        label: "Street Type",
        originName: "residential_address_street_type",
        invalidErrorMsg: "Street Type is invalid (e.g. 357)",
      },
      residential_address_country: {
        name: "applicant_details.residential_address_country",
        originName: "residential_address_country",
        label: "Country",
        invalidErrorMsg: "Country is invalid (e.g. 357)",
      },
      residential_address_full_address: {
        name: "applicant_details.residential_address_full_address",
        originName: "residential_address_full_address",
        type: "address",
        label: "Residential Address",
        invalidErrorMsg: "Full Address is invalid (e.g. 357)",
      },
      applicant_mobile_phone: {
        name: "applicant_details.applicant_mobile_phone",
        originName: "applicant_mobile_phone",
        type: "phone",
        label: "Mobile Phone",
        invalidErrorMsg: "Mobile Phone is invalid (e.g. 357)",
      },
      applicant_email: {
        name: "applicant_details.applicant_email",
        originName: "applicant_email",
        label: "Email",
        invalidErrorMsg: "Email is invalid",
      },
      client_address: {
        name: "applicant_details.client_address",
        label: "I Confirm This Is My Email Address",
        originName: "client_address",
      },
    },
  },
};

export default fromModels;
