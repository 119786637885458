export default {
  formId: "onboardingForm",
  formField: {
    email: {
      name: "email",
      label: "Email",
      requiredErrorMsg: "Email is required",
    },
    otp: {
      name: "otp",
      label: "OTP",
      requiredErrorMsg: "OTP is required",
    },
  },
};
